@import 'styles/variables';

.br-error-hub__container__btn {
  margin-top: 20px;
  justify-content: flex-end;
}

.br-error-hub__container__header {
  text-align: end;
}

.br-error-hub__container__btn
  .br-error-hub__container__btn__reschedule-cancel-button {
  margin-left: 10px;
  background-color: @red-600;
  color: @gray-100;
}

.br-error-hub__container__btn .br-error-hub__container__btn__printAWB {
  margin: 0 10px;
  background-color: @green-600;
  color: @gray-100;
}

.br-order-action-modal-bin-assignment {
  display: block;
  margin: 10px 0;
}
