@import 'styles/variables';

.br-hub-performance__hub-date-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.br-hub-performance__hub-filter-title {
  font-size: 18px;
  font-family: @poppins-font-600;
  margin-right: 8px;
}

.br-hub-performance__hub-filter-container {
  & .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    width: 164px;
  }
}

.br-hub-performance__card-container {
  margin-top: 18px;
  display: flex;
}

.br-hub-performance__card-rank-container {
  padding: 19px 40px 24px 40px;
  box-shadow: @shadow-sm;
  border-radius: 4px;
  margin-right: 26px;
  display: flex;
  align-items: center;
}

.br-hub-performance__card-previous-after-rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  border-top: 1px solid @yellow-600;
  & .br-hub-performance__card-previous-after-rank-title,
  .br-hub-performance__card-previous-after-subtitle {
    font-family: @poppins-font-400;
    color: @gray-400;
    font-size: 12px;
    white-space: pre;
  }

  & .br-hub-performance__card-previous-after-rank-percent {
    font-family: @lato-font-700;
    font-size: 15px;
  }
}

.br-hub-performance__card__progress {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  & .ant-progress-circle .ant-progress-text {
    font-size: 40px;
    font-family: @poppins-font-600;
    color: @gray-900;
  }
  & .br-hub-performance__card__progress__subtitle {
    font-size: 12px;
    color: @gray-500;
    margin-top: -40px;
  }
  &
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: @teal-500;
  }
}

.br-after-hub {
  border-top: 1px solid @green-600;
}

.br-hub-performance__higlight-card-container {
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: @shadow-sm;
  border-radius: 4px;
}

.br-hub-performance__hitlight-title {
  font-family: @poppins-font-600;
  color: @text-color-47;
}

.br-hub-performance__highlight-cards-content {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.br-hub-performance__highlight-card-info-content {
  display: flex;
  background: @gray-50;
  box-shadow: @shadow-sm;
  border-radius: 4px;
  width: 278px;
  padding: 16px;
  justify-content: space-between;
}

.br-hub-performance__highlight-card-cost-content {
  display: flex;
  background: @gray-50;
  box-shadow: @shadow-sm;
  border-radius: 4px;
  width: 278px;
  padding: 18px 16px;
  justify-content: space-between;
  margin-left: 16px;
}

.br-hub-performance__highlight-card-info-missed-deliveries {
  display: flex;
  flex-direction: column;
}

.br-hub-performance__highlight-card-info-missed-deliveries-value {
  font-family: @poppins-font-600;
  font-size: 20px;
  color: @gray-900;
}

.br-hub-performance__highlight-card-info-missed-deliveries-title {
  font-size: 12px;
  color: @gray-500;
}

.br-hub-performance__highlight-card-info-missed-deliveries-extra-info-zero-attempts {
  padding-left: 7px;
  border-left: 4px solid @red-500;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.br-hub-performance__highlight-card-info-missed-deliveries-extra-info-sla {
  padding-left: 7px;
  border-left: 4px solid @yellow-400;
  display: flex;
  align-items: center;
}

.br-hub-performance__highlight-card-info-missed-deliveries-extra-info-value {
  font-size: 12px;
  margin-right: 7px;
}

.br-hub-performance__highlight-card-info-missed-deliveries-extra-info-title {
  font-family: @poppins-font-400;
  font-size: 12px;
  color: @gray-400;
}

.br-hub-performance__score-card-container {
  display: flex;
  margin-top: 20px;
  box-shadow: @shadow-sm;
  border-radius: 4px;
  padding: 19px 37px;
  justify-content: space-between;
}

.br-hub-performance__score-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.br-hub-performance__score-card-title {
  font-family: @poppins-font-400;
  font-size: 12px;
  color: @gray-400;
}

.br-hub-performance__score-card-value {
  font-family: @lato-font-700;
  font-size: 15px;
}

.br-hub-performance__star-column-content {
  display: flex;
  flex-direction: column;
}

.br-hub-performance__star-column-content-name {
  font-size: 13px;
}

.br-hub-performance__star-column-content-phone {
  font-size: 11px;
  color: @gray-400;
}

.br-hub-performance__table-container {
  margin-top: 32px;
  & .br-table-component {
    margin-top: 22px;
    & .br-table__header .br-table__title {
      font-family: @poppins-font-600;
      color: @gray-600;
    }
  }
}

.br-hub-performance__table-header-text {
  font-family: @poppins-font-600;
  color: @gray-600;
}

.br-hub-performance__attendance-column-content {
  display: flex;
  flex-direction: column;
}

.br-hub-performance-container .ant-spin-container {

  &>.br-hub-performance__hub-date-filter-container,
  &>.br-hub-performance__card-container,
  &>.br-hub-performance__score-card-container {
    flex-direction: row;
  }
}