@import 'styles/variables';

.br-shipment-details__tracking {
  position: relative;
  padding: 16px 24px;
  max-height: 336px;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  mask-image: linear-gradient(to top, transparent 25%, black 75%);
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  &-expanded {
    mask-image: none;
    max-height: unset;
    cursor: auto;
  }
}

.br-shipment-details__tracking-title {
  color: @text-gray;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.br-shipment-details__tracking.br-shipment-tracking__many-logs
  .br-shipment-details__tracking-timeline {
  & li.ant-timeline-item {
    &.ant-timeline-item-last {
      margin-bottom: 0;
    }
  }
}

.br-shipment-details__tracking .br-shipment-details__tracking-timeline {
  padding-left: 8px;
  & li.ant-timeline-item:last-child {
    padding-bottom: 0;
  }

  & li.ant-timeline-item {
    padding-bottom: 16px;
    & .ant-timeline-item-head {
      width: 16px;
      height: 16px;
      background-color: @tracking-icon-color;
      border: none;
    }
    &.ant-timeline-item-last {
      margin-bottom: 48px;
    }

    & .ant-timeline-item-tail {
      display: block;
      left: 7px;
      margin-top: 12px;
      height: calc(100% - 28px);
    }
    & .ant-timeline-item-content {
      margin-left: 28px;
      & .br-shipment-details_tracking-status {
        padding-top: 6px;
        margin-bottom: 0px;
        color: @text-gray-dark;
      }

      & .br-details-status {
        border-radius: 8px;
        border: 1px solid @gray-200;
        width: fit-content;
        padding: 8px 16px;
        margin-bottom: 16px;

        & .br-shipment-details-logs {
          margin-bottom: 8px;
          display: flex;
          flex-direction: column;
          .br-shipment-details_tracking-logs {
            color: @text-gray;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        & .br-shipment-details_tracking-details-date {
          margin: 0;
          margin-top: 8px;
          color: @gray-500;
        }
      }
    }
  }
}

.br-order-logs__log-title {
  margin-bottom: 8px;

  & > svg {
    margin-left: 12px;
  }
}

.br-order-logs__call-log-details {
  & > .ant-tag {
    margin-left: 14px;
    padding: 4px 8px;
  }
}

.br-order-logs__call-logs-phone-number {
  color: @gray-500;
}

.br-shipment-details_tracking-details-location {
  &::before {
    width: 5px;
    height: 5px;
    background-color: @gray-500;
    border-radius: 50%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px;
  }
}

.br-shipment-details__tracking-pod {
  display: flex;
  align-items: center;
  gap: 4px;

  & > span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.br-shipment-details_tracking-taken-by {
  margin: 0;
  padding: 0;
  color: @gray-500;
  margin-top: 4px;
  margin-bottom: 16px;
}

.app-container .ant-spin-container .br-material-logs__actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
