@import 'styles/variables';

.br-po-header {
  padding: 24px;
  box-shadow: @border-bottom;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background: @white;
  z-index: 1000;
  
  .br-button-component.ant-btn {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.br-po-header__actions,
.br-po-header__title {
  display: flex;
  gap: 12px;
  align-items: center;
}

.br-po-header__title__icon {
  border: 1px solid @gray-200;
  border-radius: 4px;
  height: 28px;
  width: 28px;
  text-align: center;
  cursor: pointer;
}

.br-po-header__title__text,
.br-po-header__secondary-title__text {
  .font({.display-sm()});
}

.br-po-header__secondary-title__text {
  color: @text-gray;
}