@import 'styles/variables';

.br-lost-or-damaged-modal__footer {
  display: flex;
  background-color: @gray-50;
}

.ant-form-item-control-input-content .br-lost-modal__radio-group__wrapper {
  width: 102%;
  display: flex;
  justify-content: space-between;
}

.br-lost-modal__radio-group__wrapper .ant-radio-wrapper-in-form-item {
  width: 48%;
  border: 1px solid @gray-200;
  box-shadow: @shadow-xs;
  border-radius: 4px;
  height: 36px;
  padding: 8px;
}

.ant-radio-wrapper-checked {
  background-color: @teal-100;
  border-color: @teal-500;
}

.br-lost-modal__select-wrapper .ant-select-single {
  width: 100%;
}

.br-lost-modal__form-wrapper .br-lost-modal__input-row {
  margin-top: 24px;
}

.br-lost-modal__form-wrapper .br-lost-modal__input-row:first-child {
  margin-top: 0px;
}
