@import 'styles/variables.less';

.br-permissions__form-items {
  display: flex;
}

.br-permissions-close-admin-group__section {
  box-shadow: @border-top;
  margin-top: 20px;
  padding: 24px 0;

  .ant-btn {
    max-width: fit-content;
  }
}

.br-permissions-close-admin-group__title {
  margin-bottom: 10px;
}

.br-permissions-super-admin__toggle {
  margin: 12px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
