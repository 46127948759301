@import 'styles/variables';

.br-security-type-card {
  border: 1px solid @border-color;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.br-security-type-card .ant-btn {
  margin-top: 15px;
}

.br-security-type-card__content {
  margin-bottom: 0;
}

.br-security-type-card__content .br-sidebar-content__title {
  font-size: 14px;
  margin-bottom: 4px;
}

.br-security-type-card__content .br-sidebar-content__sub-title {
  font-size: 12px;
}
