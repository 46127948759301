@import 'styles/variables';

.br-info-card.ant-card.ant-card-bordered {
  width: 648px;
  height: auto;
  border-radius: 8px;
    margin: 0 auto;
    
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .ant-card-head {
    .font({.display-xs()});
    background: @gradient-gray-50;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.br-info-card__content-item {
  display: flex;
  gap: 8px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
