@import 'styles/variables.less';

.br-inventory-requests {
  padding: @spacing-xl;
}

.br-inventory-requests__status-tag .ant-tag {
  border-radius: 16px;
  padding: 2px 8px;
}

.ant-tooltip.br-inventory-requests__status-tooltip {
  width: fit-content;
  max-width: 380px;
  .ant-btn {
    margin-top: 12px;
  }
}

.br-inventory-requests__actions-tooltip {
  min-width: 183px;
}

.br-inventory-table__time {
  color: @text-gray;
}
