.br-seals-logs {
  .br-table-component {
    .br-table__quick-filter-tabs {
      margin: 0;
      padding: 18px 0;
    }
  }
}

.ant-tag.br-seals-logs__tag {
  width: fit-content;
  border-radius: 10px;
}
