@import 'styles/variables';

.br-star_allowances-content {
  border: 1px solid @border-color-23;
  border-radius: 4px;
  padding: 18px;
  max-width: 90%;
  & .br-star_allowances-content__action.ant-btn {
    border-radius: 8px;
    height: 38px;
    margin-top: 20px;
    flex: unset;
  }
}

.br-star_allowances-content__zones .anticon-check {
  display: none;
}

.br-star_allowances-content .ant-input-suffix {
  font-size: 12px;
  font-family: @lato-font-700;
}

.br-star_allowances__hub-label {
  font-family: @lato-font-400;
  font-size: 12px;
  color: @text-color-46;
  background: @background-color-62;
  border-radius: 10px;
  padding: 1px 10px 2px;
}

.br-star_allowances__hub-zone .ant-select-item-option-content {
  color: @text-color-31;
  font-size: 13px;

  & .ant-checkbox-wrapper {
    margin-right: 10px;
  }
}

.br-star_allowances-content__zones__search {
  padding: 12px;
}

.br-star_allowances-content__title {
  margin-bottom: 12px;
  font-family: @poppins-font-600;
  font-size: 13px;
  color: @text-color-49;
}

.br-star_allowances-content
  .ant-select-selection-overflow-item
  .ant-checkbox-wrapper {
  display: none;
}
