@import 'styles/variables';

.br-action-center-order-details-wrapper {
  width: 100%;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;

  & .ant-spin-container {
    flex-direction: row;
  }
}
