@import 'styles/variables.less';

.br-seals-table__time {
  & > span {
    display: block;
    color: @gray-400;
  }
}

.br-receive-seals__table {
  .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
    display: none;
  }
}
