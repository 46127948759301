@import 'styles/variables';

.br-fulfillment-table.br-table-component {
  .br-table__quick-filter-tabs {
    margin-bottom: 0;
    padding: 16px;

    .br-table__title {
      margin-left: 0;
    }

    .br-table__header__actions {
      margin-right: 0;
    }
  }

  .cutoff-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 9px;

    &.before-cutoff {
      background-color: @icon-green;
    }

    &.after-cutoff {
      background-color: @icon-default;
    }
  }

  .br-po-id-column {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .in-progress-icon {
    padding: 6px;
    background: @bg-gray-light;
    border-radius: 100%;
  }

  .clickable {
    cursor: pointer;
  }

  .dimmed {
    svg {
      path {
        fill: @icon-disabled;
      }
    }
  }
}

.br-fulfillment-problematic-jobs-table.br-table-component__empty-state {
  box-shadow: unset;
  .br-table__quick-filter-tabs {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .br-table-component__empty-state {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.br-fulfillment-problematic-jobs-table .br-table__quick-filter-tabs {
  background: @white;
  border-radius: 12px;
}

.br-action-menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.cancel-action {
    color: @red-700;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: @red-700;
      }
    }
  }
}
