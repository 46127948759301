@import 'styles/variables';

.br-return-manifest-info-card__title {
  font-family: @poppins-font-600;
  font-size: 16px;
  margin-bottom: 8px;
}

.br-return-manifest-info-card__content {
  flex-wrap: wrap;
  padding: 14px;
  border: 1px solid @gray-200;
  border-radius: 8px;
  box-shadow: @shadow-xs;
}

.br-return-manifest-info {
  flex: 0 0 240px;
  margin-bottom: 16px;
}

.br-return-manifest-info__title {
  font-family: @lato-font-700;
  color: @gray-400;
}

.br-return-manifest-info__value {
  height: 100%;
}

.br-return-manifest-info .ant-card {
  margin-right: 20px;
  height: 100%;
}
