@import 'styles/variables';

.br-international__sign-in-container {
  height: 100%;
}

.br-international__sign-in-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.br-international__sign-in-title {
  font-size: 16px;
  margin-bottom: 6px;
  font-family: @lato-font-700;
  color: black;
}

.br-international__sign-in-sub-title {
  font-size: 13px;
  margin-bottom: 6px;
  font-family: @lato-font-700;
  color: #7b7b7b;
}

.ant-btn.ant-btn-primary.br-international__signup-button {
  padding: 12px 22px;
  font-size: 13px;
  margin-top: 28px;
  & span {
    font-family: @lato-font-700;
  }
}

.br-international__address-container {
  margin-top: 23px;
}

.br-international__address-container
  .ant-collapse.br-international__address-panel {
  border: none;

  & .ant-collapse-content-box {
    padding-bottom: 0;
  }

  & .ant-collapse-item {
    border: 0;
    margin-bottom: 12px;

    & .ant-collapse-header {
      background-color: @background-color-5;
      padding: 15px;
      font-size: 16px;
      font-family: @lato-font-400;
      color: black;
      border-radius: 4px;
      & .anticon.anticon-right.ant-collapse-arrow {
        right: 22px;
        left: unset;
        padding-top: 20px;
      }
      & .br-international__address-panel-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        & .br-international__address-panel-header-flag {
          font-size: 33px;
          margin-right: 12px;
          border-radius: 4px;
        }
      }
    }
  }

  & .ant-collapse-item-active {
    & .ant-collapse-header {
      border: none;
      border-radius: 4px;
    }
    & .ant-collapse-content {
      border: none;
      background-color: white;
    }
  }
}

.br-international__address-panel-content {
  display: flex;
  flex-direction: row;
  & div {
    display: flex;
    flex-direction: column;
    margin-right: 42px;
    font-size: 14px;
    color: #7b7b7b;
    font-family: @lato-font-700;
    & span {
      margin-bottom: 6px;
    }
  }

  & div:first-child {
    font-size: 13px;
    color: black;
    font-family: @lato-font-400;
  }
}

.br-completed-order-items__container__summary-table {
  margin-top: 16px;
  & .br-searchable-component__header {
    display: none;
  }
}
