@import 'styles/variables';

.br-delivery-highlights__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  & span {
    margin-top: 12px;
    font-size: 13px;
    color: @text-color-49;
    font-family: @poppins-font-500;
  }
}

.br-delivery-highlights__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 180px;
  overflow-y: auto;
  padding: 16px 32px;
  padding-top: 0;
}

.br-delivery-highlights__container::-webkit-scrollbar {
  width: 2px;
}

.br-delivery-highlights__container::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: @background-color-18;
}

.br-delivery-highlight__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  & .br-delivery-highlight__date-name {
    color: @text-color-45;
    font-size: 12px;
    margin-bottom: 8px;
    font-family: @lato-font-700;
  }

  & span {
    font-size: 14px;
    color: @text-color-46;
    text-align: left;
    font-family: @lato-font-400;
    margin-bottom: 4px;
  }
}
