.br-bosta-capital-settings__container {
  display: flex;

  & .br-capital__form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    > .ant-form-item-control-input {
      border-radius: 4px;
    }
  }
  & .br-capital__form-submit-btn {
    width: 131px;
    border-radius: 4px;
  }
}
