@import 'styles/variables.less';

.br-impersonation-modal__container {
  padding: 16px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-impersonation-modal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.br-impersonation-modal__subtitle {
  color: @gray-500;
  margin-top: 8px;
}

.br-impersonation__modal {
  .ant-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
    }
  }

  .ant-modal-body {
    width: 400px;
    padding: 16px 20px;
  }

  .ant-modal-footer {
    .br-button-component.primary svg path {
      fill: unset;
      stroke: @white;
    }
  }
}

.br-impersonation-business__selector {
  &.ant-row.ant-form-item {
    margin-top: 24px;
    min-height: auto;
  }

  &.ant-row.ant-form-item:not(:last-child) {
    margin: 0;
  }
}

.br-impersonation-status__content {
  .br-banner-content__text {
    flex: 1;
    p {
      margin-top: 0;
    }
  }
}

.br-impersonation-status__content {
  text-align: center;

  .br-empty-state__title {
    margin: 8px 0 4px 0;
  }

  .br-empty-state {
    margin-bottom: 16px;
  }

  .br-banner-content__parent {
    margin-top: 24px;
  }

  .br-banner-content__text {
    text-align: left;
  }

  .pending-state {
    .br-empty-state__image {
      margin: 40.5px 103.51px 9px 73.93px;
    }
  }

  .approved-state {
    margin: 16px 0;
    .br-empty-state__image {
      margin: 0 43.89px 0 0;
    }

    .br-empty-state__title {
      margin-top: 0;
    }
  }
}

.br-impersonation__business-name {
  display: block;
}
