@import 'styles/variables.less';

.br-receive-seals__card {
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-receive-seals__card__header {
  border-bottom: 1px solid @gray-200;
  padding: 12px 24px;
}

.br-receive-seals__card__content {
  padding: 12px 24px;
}
