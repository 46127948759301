@import 'styles/variables';

.br-soho-pickups-scan-label {
  margin-bottom: 8px;
  font-family: @lato-font-700;
}

.br-soho-pickups-scan-input {
  max-width: 260px;
}

.br-soho-pickups__actions {
  display: flex;
}

.br-soho-pickups-button {
  max-height: 28px;

  &.ant-btn:not(.ant-btn-circle) {
    border: 1px solid @gray-300;
    border-radius: 8px;
  }
}

.br-soho-pickups__actions-button {
  max-height: 28px;

  &.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary) {
    border: none;
    background: transparent;
    font-size: 22px;
    height: 20px;
  }
}

.br-soho-pickups-received-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-soho-pickups-print-all-awbs.ant-btn:not(.ant-btn-circle) {
  background: @teal-200;
  color: @teal-500;
  border-radius: 8px;
  max-height: 127px;
}
