.br-edit-material-modal__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  & .ant-divider.ant-divider-horizontal {
    padding: 0;
    margin: 0;
  }
}

.br-edit-material-modal__inputs-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.br-edit-material-modal__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;
  }
}
