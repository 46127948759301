@import 'styles/variables';

.br-po-products__empty-state {
  padding: 20px 0;
  text-align: center;
}

.br-po-products__empty-state__icon {
  width: 26px;
  height: 26px;

  path {
    fill: @teal-500;
  }
}

.br-po-products__empty-state__title {
  margin-top: 16px;
  font-weight: 600;
}

.br-po-products__empty-state__subtitle {
  color: @text-gray;
}

.br-po-products__empty-state__actions {
  display: flex;
  gap: 16px;
  align-items: center;
  width: max-content;
  margin: 16px auto 0;

  .br-button-component.ant-btn {
    gap: 4px;
    padding: 4px 8px;
    height: 28px;
  }
}

.br-po-products__empty-state__or {
  color: @gray-400;
}
