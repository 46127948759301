@import 'styles/variables';

.br-inventory-table__box-shadow {
  box-shadow: @shadow-xs;
}

.br-inventory-table__container {
  font-family: @poppins-font-500;
  margin-bottom: 20px;
}

.br-inventory-table__container .br-inventory-table__row-cards {
  display: none;
}

// ----- SEARCH BAR SECTION -----
.br-inventory-table__container .br-inventory-table__search-container {
  margin-top: 16px;
  padding: 16px;
  display: flex;
  border: 1px solid @gray-300;
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .br-inventory-table__product-count {
  display: flex;
  align-items: center;
  width: 100%;
  color: @gray-800;
  font-family: @poppins-font-600;
  font-size: 16px;
}

.br-inventory-table__container .ant-table-content {
  border-top: 0px;
}

.br-inventory-table__container .ant-table-title {
  padding: 0px;
}

.br-inventory-table__container
  .ant-input-group-wrapper.br-inventory-table__search-bar
  .ant-input-wrapper.ant-input-group
  .ant-input-affix-wrapper {
  height: 34px;
  border-radius: 4px;
  .br-inventory-table__box-shadow;
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .br-inventory-table__search-bar-buttons {
  display: flex;
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .ant-input-group-addon {
  display: none;
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .br-inventory-table__filter
  button.ant-btn {
  margin: 0 8px;
  height: 34px;
  .br-inventory-table__box-shadow;
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .br-inventory-table__filter {
  > button span:last-child {
    display: none;
  }
  .br-filter__container {
    button {
      width: 50%;
      margin: 0;
    }
    button.br-filter__reset-button {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    button.br-filter__submit-button {
      border-radius: 0px;
      border-top-right-radius: 4px;
    }
  }
  .br-filter__counter {
    display: block;
    background-color: @green-600;
    color: @green-600;
    margin-right: 8px;
    padding: 3px 5px;
    border-radius: 50px;
  }
}

.br-inventory-table__container
  .br-inventory-table__search-container
  .br-inventory-table__export.ant-btn {
  .br-inventory-table__box-shadow;
  svg {
    width: 18px;
    height: 18px;
    path {
      fill: @gray-500;
    }
  }
}

.br-inventory-table__container {
  .br-inventory-table__filter {
    position: relative;
    svg {
      margin-right: 5px;
    }
    .ant-dropdown {
      position: absolute;
      top: 37.8906px !important;
    }
  }
}

// ----- TABLE SECTION -----
.br-inventory-table__container .ant-table-container .ant-table-content {
  border: 1px solid @gray-300;
  border-top: 0px;
  border-bottom: 0px;
  border-radius: 0px;
}

.br-inventory-table__container table {
  height: 100%;
  width: 100%;
  tbody tr td,
  thead tr td {
    height: 100%;
  }
}

.br-inventory-table__container table > thead > tr:first-child th {
  font-family: @poppins-font-500;
}

.br-inventory-table__container table > tbody > tr > td.ant-table-cell {
  padding: 8px 16px;
  font-size: 14px;
}

.br-inventory-table__container .br-inventory-table__empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  .br-inventory-table__empty-title {
    font-size: 20px;
    color: @gray-900;
    margin: 5px 0;
  }
  .br-inventory-table__empty-button {
    margin-top: 25px;
  }
}

.br-inventory-table__container .ant-pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 991px) {
  .br-inventory-table__container .br-filter__container {
    width: 100%;
  }
  .br-inventory-container .br-inventory-table__search-container {
    display: block;
  }
  .br-inventory-table__search-container .br-inventory-table__product-count {
    margin-bottom: 10px;
  }
  .br-inventory-table__search-container
    .br-inventory-table__search-bar-buttons {
    display: flex;
    margin-top: 10px;
    .br-inventory-table__filter,
    .br-inventory-table__export {
      width: 50%;
    }
  }
  .br-inventory-table__export {
    margin-left: 5px;
  }
  .br-inventory-table__search-container
    .br-inventory-table__filter
    button.ant-btn {
    width: 100%;
  }
  .br-inventory-table__container
    .br-inventory-table__search-container
    .br-inventory-table__filter
    button.ant-btn {
    margin: 0;
  }
}
