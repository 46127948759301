@import 'styles/variables';

.br-action-center__star-info-popover {
  max-width: 320px;

  & .ant-popover-inner-content {
    padding: 0;
  }
}

.br-action-center__star-info-container {
  box-shadow: @border-bottom;
}

.br-action-center__star-info {
  padding: 16px;
}

.br-action-center__star-icon {
  background-color: @gray-50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.br-action-center__star-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.br-action-center__star-hub {
  display: block;
  color: @text-gray;
}

.br-action-center__star-active {
  background-color: @icon-green;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-flex;
  margin-left: 4px;

  &.star-busy {
    background-color: @icon-red-dark;
  }
}

.br-action-center__star_phone {
  display: flex;
  align-items: center;
  gap: 8px;
  color: @text-gray-dark;
  padding: 0px 0 14px 72px;
}

.br-action-center__whatsapp-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  //   padding-bottom: 12px;
  padding: 12px 0;

  & > svg:last-child > path {
    stroke: @teal-500;
  }
}

.br-action-center__star-info-loading {
  padding: 14px;
  max-width: 320px;
}
