@import 'styles/variables';

.br-serial-location-tooltip {
  .ant-tooltip-inner {
    color: @text-gray-dark;

    .br-serial-location-tooltip__zone {
      display: inline-block;
      color: @text-gray;
      .font({.caption-lg()});
    }
  }
}

.br-serial-location__btn {
  cursor: pointer;
}
