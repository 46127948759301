@import 'styles/variables.less';

.br-new-order__title.ant-typography {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 15px;
}

.br-new-order-card {
  margin-bottom: 20px;
}

.br-new-order-card__content {
  background-color: @background-color;
  padding: 20px 20px 10px 20px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid @border-color-18;
}

.br-new-order .br-form-item-label {
  display: flex;
  flex-direction: column;
  text-align: start;
  white-space: normal;
  word-break: break-word;
}

.br-new-order__card-content-title {
  color: @text-color;
  font-size: 15px;
  font-family: @poppins-font-600;
  font-weight: 600;
  margin-bottom: 20px;
}

.br-new-order .br-form-row .ant-row.ant-form-item:not(:last-child),
.br-form-with-sub-title .ant-row.ant-form-item:not(:last-child) {
  margin-right: 36px;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
}

input[type='number']:hover::-webkit-inner-spin-button {
  background: transparent url('../../../assets/imgRevamp/number.svg') no-repeat
    50% 50%;
  height: 16px;
  width: 6px;
  padding-right: 2px;
  position: relative;
  right: 4px;
}

.br-new-order__actions__create-order {
  margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
  .br-new-order .br-form-row__half-width {
    max-width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .br-new-order .ant-row.ant-form-item {
    padding-bottom: 10px;
  }

  .ant-divider-vertical {
    display: none;
  }
}
