@import 'styles/variables';

.br-po-business-details__form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-row.ant-form-item {
    min-height: unset;
  }
}

.br-po-business-details__view-info {
  display: flex;
  gap: 4px;
  width: max-content;
  margin: 24px auto 0;
  .font({.button-lg()});

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: @icon-teal;
    }
  }
}
