@import 'styles/variables';

.br-cycle-details__header {
  display: flex;
  flex-direction: column;
  font-family: @lato-font-700;
}

.br-cycle-details__header__label {
  font-size: 18px;
  margin-bottom: 11px;
}

.br-cycle-details__header__value {
  font-size: 24px;
  margin-bottom: 18px;
}

.br-cycle-details__filter {
  margin: 30px 0;
  // max-width: 80%;
  align-items: center;
}

.br-cycle-details__filter__actions {
  display: flex;
  margin-top: 10px;
  & .ant-btn:first-child {
    background-color: @background-color-18;
    border-color: @background-color-18;
    margin-right: 12px;
  }
}

.br-cycle-details__header .ant-tag {
  border-radius: 8px;
  font-family: @lato-font-700;
  font-size: 18px;
  margin: 0px 8px;
  padding: 4px 8px;
}

@media only screen and (max-width: 768px) {
  .br-cycle-details__filter {
    flex-direction: column;
    max-width: 100%;
    align-items: flex-start;
  }
}

.br-star-cycles__summary {
  display: flex;

  & .br-star-cycles__summary__card--value {
    font-size: 20px;
  }

  & .br-star-cycles__summary__card--value__suffix {
    margin-left: 4px;
  }

  & .br-star-cycles__summary__card--label {
    font-family: @lato-font-700;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 8px;
    line-height: 17px;
  }

  & .br-star-cycles__summary__card {
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 18px 14px;
    margin-right: 10px;
    width: 25%;
  }

  & svg {
    font-size: 38px;
    fill: none;
    margin-right: 16px;
  }
}

.anticon.anticon-check-circle.br-star-salary__config-column {
  & svg {
    fill: @teal-500;
    font-size: 25px;
  }
}

.ant-form.br-star-cycles__export-monthly {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin: @spacing-md 0;

  > div {
    max-width: 200px;
    min-height: 36px;
  }

  .ant-row.ant-form-item {
    margin-right: @spacing-md;
  }
}
