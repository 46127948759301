@import 'styles/variables';

.br-rto-modal {
  border-radius: 12px;

  .br-rto-modal_text-btn {
    padding: 0 4px;
    height: min-content;
    display: inline-block;

    span {
      text-decoration: underline;
    }
  }

  .br-rto-modal_rounded {
    border-radius: 8px;
  }
}

.br-rto-modal_title {
  font-family: @lato-font-700;
  font-size: 18px;
  margin-bottom: 8px;
}

.br-rto-modal_subtitle {
  color: @text-color-54;
}

.br-rto-modal_subtitle {
  margin: 12px 0;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: @background-color-46;
  color: @text-color-44;
}

.br-rto-modal_assign-to {
  font-size: 16px;
}

.br-rto-modal_footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 14px;

  .br-rto-modal_footer_btn {
    height: 44px;
  }

  .br-rto-modal_footer_skip-confirm-btn {
    flex: 80%;
  }
}

.br-rto-modal_assignee-dropdown {
  width: 100%;
  border-radius: 8px;
}
