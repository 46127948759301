@import 'styles/variables.less';

.br-international-insights__container
  .br-searchable-table
  .br-searchable-table__listing
  .ant-table-content {
  border: 0;

  & thead {
    & tr {
      & th {
        background-color: #f7fafc;
        border-radius: 0;
        border-bottom: 0;
        font-size: 13px;
        font-family: @poppins-font-500;
      }
    }
  }

  & tbody {
    & tr {
      & td {
        font-size: 13px;
        font-family: @poppins-font-500;
        color: #7b7b7b;
      }
    }
  }

  & tbody {
    & .ant-table-expanded-row {
      & td {
        padding: 0;
        border: 0;
      }
    }
  }
}

.br-international-insights-card__container {
  display: flex;
  flex-direction: row;
  & .br-international-insights-card-content {
    border: 1px solid @border-color-17;
    display: flex;
    flex-direction: column;
    padding: 17px 19px;
    width: 100%;
    max-width: 249px;
    border-radius: 4px;
    margin-right: 14px;

    & .br-insights-card__title-number {
      font-size: 16px;
      font-family: @lato-font-700;
    }

    & .br-insights-card__title-text {
      font-size: 13px;
      font-family: @lato-font-700;
      color: @text-color-45;
    }
  }
}

.br-insights-expand__container {
  border-radius: 8px;
  background-color: #f3f4f6;
  padding: 14px 10px;

  & .br-insights-expand__header {
    margin-left: 19px;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    & span {
      width: 49%;
      color: black;
      font-size: 10px;
      font-family: @poppins-font-500;
    }
  }

  & .br-insights-expand__content-row {
    margin-bottom: 4px;
    background-color: white;
    border-radius: 8px;
    padding: 10px 19px;
    display: flex;
    & span {
      color: black;
      font-size: 12px;
      font-family: @poppins-font-500;
      flex: 1 1;
    }
    & .br-insights__tag {
      background-color: #d1fae5;
      color: #065f46;
      padding: 1px 10px;
      border-radius: 10px;
    }
  }
}

.br-international-insights__table-header {
  margin-top: 21px;
  margin-bottom: 11px;
  & b {
    font-size: 14px;
    font-family: @poppins-font-500;
  }
}
