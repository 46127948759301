@import 'styles/variables.less';

.br-balance-adjustment__download-template-container {
  display: flex;
  background-color: @teal-50;
  padding: 14px;
  padding-bottom: 32px;
  border: 1px solid @gray-300;
  border-radius: 4px;
  box-shadow: 0px 1px 2px @box-shadow-5;
  align-items: flex-start;
  & div {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    font-size: 14px;
    & span:first-child {
      color: @black;
    }
    & button {
      width: fit-content;
      margin-top: 8px;
    }
  }
}

.br-wallet-balance-adjustment {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.br-balance-adjustment__upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 1px 1px @gray-200, 0px 4px 4px @box-shadow-6;
  border-radius: 8px;
  padding: 16px;
  & span:first-child {
    width: 100%;
    height: 100%;
  }
  & .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    & .ant-upload-text {
      color: @gray-900;
      margin-bottom: 4px;
      & span {
        color: @teal-500;
      }
    }
    & .ant-upload-hint {
      color: @gray-500;
      font-size: 12px;
      margin-bottom: 12px;
    }
    & button {
      background-color: @teal-500;
      & svg {
        margin-right: 8px;
      }
    }
  }
}

.br-balance-adjustment-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & .sucess-icon {
    margin-bottom: 20px;
  }
  & .sucess-title {
    font-size: 16px;
    color: @black;
    margin-bottom: 4px;
  }
  & .sucess-subtitle {
    font-size: 14px;
    color: @gray-500;
    margin-bottom: 20px;
  }
  & button {
    border: 1px solid @teal-500;
    color: @teal-500;
  }
}

.br-balance-adjustment-sucess-info {
  display: flex;
  box-shadow: 0px 0px 1px 1px @gray-200, 0px 4px 4px @box-shadow-6;
  border-radius: 8px;
  padding: 12px 22px 12px 12px;
  align-items: center;
  margin-bottom: 24px;
  & div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    & .file-name-text {
      font-size: 14px;
      & span {
        color: @gray-900;
      }
    }
    & .total-business-text {
      font-size: 14px;
      color: @gray-500;
    }
  }
}
