@import 'styles/variables.less';

.br-filter {
  &__trigger {
    display: flex !important;
    align-items: center;
  }

  &__menu {
    padding: 0 !important;
  }

  &__container {
    width: 237px;

    &__header {
      border: 1px solid @border-color-19;
      border-radius: 4px 4px 0 0;
      background-color: @background-color-5;
    }
  }
}

.br-filter__checkbox__text {
  padding-right: 8px;
  padding-left: 8px;
  color: #4A5568;
}

button.br-filter__trigger.ant-btn {
  height: 30px;
}

.br-filter__trigger .anticon svg {
  color: @text-color-33;
  font-size: 14px;
  height: 14px;
}

button.br-filter__trigger.ant-btn {
  justify-content: center;
}
