@import 'styles/variables';

.br-international-pickup-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.br-international-pickup-details__header {
  margin-bottom: 20px;
}

.br-international-pickup-details__section {
  padding: 20px 0;
  box-shadow: @border-top;
}

.br-international-pickup-details__section-title {
  margin-bottom: 16px;
}

.br-international-pickup-details__section-fields {
  display: flex;
  flex-wrap: wrap;
}

.br-international-pickup-details__field {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 50%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  margin-bottom: 16px;

  &.full-width {
    flex: 100%;
  }
}

.br-international-pickup-details__field-value {
  word-break: break-all;
}

.br-international-delivery-details__header {
  margin-bottom: 20px;
}

.br-international-pickups__deliveries-container {
  padding-bottom: 20px;
}

.br-international-pickup-details__field-value > img {
  max-width: 150px;
  cursor: pointer;
  border: 1px solid @gray-200;
  border-radius: 8px;
  box-shadow: @shadow-sm;
}
