@import 'styles/variables';

.br-service-rating__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  .ant-rate {
    margin: 0;
  }

  .ant-rate-star {
    padding: 0;
    margin: 0;
  }

  .ant-rate-star-full {
    svg > * {
      fill: @yellow-300;
    }
  }
}

.br-service-rating__label {
  margin-bottom: 8px;
}

.br-service-rating__item {
  width: 100%;
}
