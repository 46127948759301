@import 'styles/variables';

.br-fulfillment-locations-breadcrumb.ant-breadcrumb {
  li {
    .font({.display-sm()});
    .ant-breadcrumb-link {
      color: @text-gray-dark;
    }

    &:last-child {
      .ant-breadcrumb-link {
        color: @text-teal;
      }
    }
  }

  .br-fulfillment-locations-breadcrumb__first-title {
    color: @text-gray-dark !important;
  }
}
