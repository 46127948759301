@import 'styles/variables.less';

.ant-drawer.br-seal-logs__drawer {
  .ant-drawer-content-wrapper {
    width: 35% !important;
    .ant-drawer-body {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .br-seal-number,
  .br-seal-logs__timeline__title {
    font-size: 16px;
    font-weight: bold;
    color: @teal-600;
  }

  .br-seal-logs__timeline__title {
    margin-bottom: 20px;
  }

  .br-seal-logs__seal-data,
  .ant-timeline {
    padding: 0 24px;
  }

  .text-gray {
    color: @text-gray;
  }

  .text-bold {
    font-weight: bold;
  }
}

.br-seal-logs__log,
.br-seal-logs__seal-data {
  display: flex;
  justify-content: space-between;
}

.br-seal-logs__seal-data {
  gap: 5px;
}

.br-seal-logs__log {
  align-items: center;
  gap: 5px;
}

.br-seal-logs__divider {
  margin: 15px 0;
}
