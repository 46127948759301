@import 'styles/variables';

.br-fulfillment-products-table {
  .text-gray {
    color: @text-gray;
  }

  .br-variants-tooltip-title {
    display: block;
    width: max-content;
    color: @text-gray;
    border-bottom: 1px dashed @gray-300;
    .font({.caption-lg()});
  }

  .ant-table-thead > tr > th.ant-table-cell:nth-child(2) {
    padding-left: 0;
  }

  .ant-table-tbody > tr {
    > td:first-child {
      display: flex;
      align-items: center;
      height: 80px;
      justify-content: flex-start;

      &:has(.collapse-expand-icon) {
        padding-left: 0;
      }

      &:not(:has(.collapse-expand-icon)) {
        padding-left: 20px;
      }
    }

    &.ant-table-row-level-1 > td {
      &:first-child {
        justify-content: flex-end;
      }

      &:nth-child(2) {
        padding-left: 20px;
      }
    }

    &.ant-table-row-level-0 > td {
      &:nth-child(2) {
        padding-left: 0;
      }
    }
  }

  .collapse-expand-icon {
    cursor: pointer;
    transform: rotate(-90deg);

    &.expanded {
      transform: unset;
    }
  }
}
