@import 'styles/variables';

.br-new-confirmation__modal {
  .ant-modal {
    max-width: 488px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }
}

.br-new-confirmation-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.br-new-confirmation-modal__footer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.br-new-confirmation-modal__subtitle {
  color: @text-gray;
}
