@import 'styles/variables';

.br-container {
  z-index: 2;
}

.br-container,
.app-container > div,
.app-container .ant-spin-container > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 0;
}

.br-container-content {
  flex: 1;
  overflow: auto;
  overflow-x: hidden;
  padding: 41px 24px;
  margin-top: 60px;
}

@media only screen and (max-width: @sm) {
  .br-container-content {
    margin-top: 0;
    padding: 24px 8px;
  }
}
