@import 'styles/variables';

.br-live-ops-priorities__form .ant-row.ant-form-item {
  display: flex;
  flex-direction: row;
  min-height: fit-content;
  margin-bottom: 24px;
}

.br-live-ops-priorities__form .ant-row.ant-form-item .ant-form-item-control {
  max-width: 180px;
}

.br-live-ops-priorities__form .ant-row .ant-form-item-label {
  padding-right: 16px;
  margin-top: 6px;
}

.br-live-ops-priorities__form .ant-row .ant-input-number {
  min-width: 100%;
}

.br-live-ops-priorities__form__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.br-live-ops-priorities__form__buttons button.ant-btn {
  min-width: 90px;
  min-height: 38px;
}

.br-live-ops-priorities__form__buttons__clear {
  margin-right: 8px;
}

.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__clear,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__clear:active,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__clear:hover,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__clear:focus {
  background-color: @background-color-18;
  border: none;
  color: @text-color-58;
}

.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__add,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__add:active,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__add:hover,
.br-live-ops-priorities__form__buttons
  button.ant-btn.br-live-ops-priorities__form__buttons__add:focus {
  background-color: @background-color-15;
  border-color: @background-color-15;
  color: @text-color-15;
}

.ant-btn.br-live-ops-priorities__table__buttons__edit {
  margin-right: 17px;
}

.br-live-ops-priorities__table__actions {
  display: flex;
  flex-direction: row;
}

.ant-btn.br-live-ops-priorities__table__buttons__edit,
.ant-btn.br-live-ops-priorities__table__buttons__edit:active,
.ant-btn.br-live-ops-priorities__table__buttons__edit:hover,
.ant-btn.br-live-ops-priorities__table__buttons__edit:focus {
  background-color: @background-color-18;
  border: none;
  color: @text-color-58;
}

.ant-btn.br-live-ops-priorities__table__buttons__delete,
.ant-btn.br-live-ops-priorities__table__buttons__delete:active,
.ant-btn.br-live-ops-priorities__table__buttons__delete:hover,
.ant-btn.br-live-ops-priorities__table__buttons__delete:focus {
  background-color: transparent;
  border: none;
}

.br-live-ops-priorities
  .br-searchable-table__listing
  .ant-pagination-item-active
  a {
  border-radius: 4px;
  color: @text-color-10;
}

.br-live-ops-priorities
  .br-searchable-table__listing
  .ant-pagination-item-active
  a {
  background-color: @background-color-18;
}

.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-item {
  background-color: @background-color;
}

.br-live-ops-priorities .br-searchable-table__listing ul.ant-pagination {
  margin-left: auto;
  width: fit-content;
  border-radius: 2px;
}

.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-item,
.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-jump-next,
.br-live-ops-priorities .br-searchable-table__listing li.ant-pagination-next,
.br-live-ops-priorities .br-searchable-table__listing ul.ant-pagination li {
  border: 1px solid @border-color-24;
}

.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-item,
.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-jump-next,
.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-next {
  margin-right: unset;
}

.br-live-ops-priorities .br-searchable-table__listing .ant-pagination-prev {
  margin-right: unset;
}

.br-live-ops-priorities .ant-pagination-item-link span {
  margin: 0 auto;
}

.br-live-ops-priorities .ant-pagination-item-link span svg {
  height: 12px;
  color: @background-color-55;
}

.br-live-ops-priorities .ant-table-cell {
  max-width: 50px;
}
