@import 'styles/variables.less';

.br-hub-receive__tabs {
  .body-medium();
  margin-top: @spacing-xl;
}

.br-hub-receive__tabs > .br-tabs > .ant-tabs.br-tabs__tab {
  width: fit-content;
  height: fit-content;

  margin-top: @spacing-sm;
  margin-bottom: @spacing-xl;

  border-radius: 8px;
  overflow: hidden;

  background-color: @white;
  box-shadow: @shadow-sm;

  > .ant-tabs-nav {
    width: fit-content;
    margin: 0;

    &::before,
    > .ant-tabs-nav-wrap::before,
    > .ant-tabs-nav-wrap::after {
      content: unset;
    }

    > .ant-tabs-nav-wrap {
      margin: 0;

      > .ant-tabs-nav-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        height: fit-content;

        > .ant-tabs-tab {
          justify-content: center;

          padding-block: @spacing-sm;
          margin: 0;

          > * {
            color: inherit;
          }

          &.ant-tabs-tab-active {
            color: @white;
            background-color: @teal-500;
          }

          &:not(:first-of-type) {
            box-shadow: @border-left;

            [dir='rtl'] & {
              box-shadow: @border-right;
            }
          }
        }

        > .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
          display: none;
        }
      }
    }
  }
}