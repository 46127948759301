@import 'styles/variables';

.br-issue-decision-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.br-issue-decision-modal__product-details {
  background: @bg-hovered;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: @border-bottom;
}

.br-issue-decision-modal__serial {
  padding: 12px 16px;
  box-shadow: @border-bottom;
}

.br-issue-decision-modal__footer__actions {
  display: flex;
  gap: 4px;
}
