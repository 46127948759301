.buttonsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleHeader {
  font-size: calc(18px + (18 - 16) * ((100vw - 80vw) / (1920 - 960)));
  font-family: 'Lato/Bold';
  color: #232323;
  margin-bottom: 0;
}

.titleDiv {
  display: flex;
  flex-direction: column;
  line-height: 2;
}

.titleDiv > button {
  margin: 0;
}

.formWidth {
  width: 50%;
}

.submitButton {
  display: flex;
  justify-content: flex-end;
}

.formLabel {
  white-space: nowrap;
  max-width: none;
}

.filterGroup {
  display: flex;
  align-items: center !important;
}

.formGroup {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.formGroup > div {
  padding-left: 0px;
}

.formGroup span:nth-child(1) {
  height: 38px;
  padding: 6px 11px;
  font-size: 16px;
  width: 100%;
}

.selectControl {
  height: 38px !important;
}

.tableDetails {
  margin-top: 30px;
  line-height: 5rem;
}

.downloadReport {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.ModalAR {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.ModalARDate {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.ModalAR > div {
  width: 85%;
}

.ModalARDate > span {
  width: 85%;
}

.ModalARDate span:nth-child(1) {
  height: 38px !important;
  padding: 6px 11px !important;
  font-size: 16px !important;
}

.ArReport, .SouqReport {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.formRow {
  align-items: center !important;
}

.fileUpload {
  width: 50%;
  margin: 0 auto;
  display: flex;
}

.reportContainer {
  width: 50%;
}

.reportDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.generateButton {
  display: flex;
  justify-content: flex-end;
}
