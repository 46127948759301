@import 'styles/variables';

.br-cashier-bulk-action-modal-container.ant-modal {
  width: 100%;
  max-width: 692px;
  & .br-upload-dialog__upload {
    &:not(.br-upload-dialog__upload-empty) {
      border: none;
      display: flex;
      flex-direction: column;
      & .ant-upload-select {
        padding: 20px;
        border-radius: 8px;
      }
    }
    &.br-upload-dialog__upload-empty
      .ant-upload.ant-upload-select.ant-upload-select-picture {
      width: 100%;
      padding: 30px;
    }
  }
  & .ant-modal-header {
    & .ant-modal-title {
      width: 100%;
    }
  }
  & .br-cashier-bulk-action__modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 28px;
    & .br-cashier-bulk-action__money-title {
      color: @teal-500;
      font-size: 24px;
      font-family: @poppins-font-600;

      & svg {
        margin-right: 10px;
      }
    }
  }
}

.br-cashier-bulk-modal__click-to-upload-button {
  color: @teal-500;
  cursor: pointer;
}

.br-cashier-bulk-modal__upload-empty-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span:last-of-type {
    color: @gray-500;
    margin-top: 4px;
  }
  & button.ant-btn.primary {
    margin-top: 12px;
  }
}

.br-bulk-action-modal__accountant-container {
  display: flex;
  align-items: center;
  justify-content: center;
  & .br-cashier-bulk-action__money-title {
    color: @teal-500;
    font-size: 24px;
    font-family: @poppins-font-600;

    & svg {
      margin-right: 10px;
    }
  }
}

.br-cashier__signature-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
