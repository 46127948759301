@import 'styles/variables';

.br-cashier-receipt-info-drawer {
  & .ant-drawer-body {
    overflow: auto;
  }
}

.br-cashier-recipt-drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  & > span:last-child {
    color: @gray-500;
    cursor: pointer;
  }
}

.br-cashier-recepit-drawer__receipt-number-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 20px;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > span:last-child {
      color: @gray-900;
    }
    & > span:first-child {
      color: @gray-500;
    }
  }
}

.br-cashier-recepit-drawer__star-details-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 20px;
  padding-bottom: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > span:last-child {
      color: @gray-900;
    }

    & > span:first-child {
      color: @gray-500;
    }
  }
}

.br-cashier-recepit-drawer__amount-details-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 20px;
  padding-bottom: 16px;
  & > span {
    color: @gray-900;
  }
  & > div {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & > span:last-child {
        color: @gray-900;
      }

      & > span:first-child {
        color: @gray-500;
      }
    }
  }
  & .br-cashier__show-pdf-receipt {
    color: @teal-500;
    cursor: pointer;
    text-decoration: underline;
  }
}

.br-cashier-recepit-drawer__rejection-details-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 20px;
  padding-bottom: 16px;
  & > span:last-child {
    color: @gray-500;
  }
}

.br-cashier-recepit-drawer__pos-details-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 20px;
  padding-bottom: 16px;
  & > span {
    color: @gray-500;
  }
}

.br-cashier-recepit-drawer__dropdown-label {
  display: flex;
  gap: 8px;
  align-items: center;
  & span {
    color: @gray-500;
  }
}

.br-cashier-recepit-drawer__bt-details-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 16px;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > span:last-child {
      color: @gray-500;
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 10px;
    & > div {
      border: 1px solid @gray-200;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px;
        & > span:last-child {
          color: @gray-500;
        }
      }
    }
  }
}
