@import 'styles/variables';

.br-international-delivery-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &:first-child {
      max-width: 320px;
    }
  }
}

.br-international-delivery-details__header {
  margin-bottom: 20px;
}

.br-international-delivery-details__section {
  padding: 20px 0;
  box-shadow: @border-top;
}

.br-international-delivery-details__section-title {
  margin-bottom: 16px;

  svg {
    margin-right: 4px;
  }
}

.br-international-delivery-details__section-fields {
  display: flex;
  flex-wrap: wrap;
}

.br-international-delivery-details__field {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 50%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  margin-bottom: 16px;

  &.full-width {
    flex: 100%;
  }
}

.br-international-delivery-details__field-value {
  word-break: break-all;
}

.br-international-delivery-details__header {
  .br-international-delivery-details__business_name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .br-international-delivery-details__header__actions {
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}

.br-international-delivery-details__business_name {
  gap: 8px;
  cursor: pointer;

  .display-sm {
    flex: 2;
  }

  svg {
    transform: rotate(270deg);
    path {
      stroke: @teal-500;
    }
  }
}

.br-international-delivery-details__header__actions {
  gap: 12px;
}

.br-international-delivery-details__flight-section {
  background: @gray-50;
  padding: 12px;
  box-shadow: unset;
  margin-bottom: 20px;

  .br-international-delivery-details__section-title {
    .font({.subheading()});
    color: @gray-900;
  }
}
