@import 'styles/variables';

.br-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.br-empty-state__image {
  // height: 31px;
  // width: 59px;
  margin-bottom: 14px;
}

.br-empty-state p {
  margin: 0;
}

.br-empty-state__title {
  color: @text-gray-dark;
  margin-bottom: 4px;
}

.br-empty-state__sub-title {
  color: @text-gray;
}

.br-empty-state__actions {
  margin-top: 24px;
  margin-bottom: 113px;
}