@import 'styles/variables';

.br-international-shipping-modal__upload-container {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  padding: @spacing-lg;
  margin-top: @spacing-lg;
  margin-bottom: @spacing-md;
}

.br-international-shipping-modal__footer {
  display: flex;
  align-items: center;
  gap: @spacing-md;
}
