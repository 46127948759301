@import 'styles/variables';

.br-manage-materilas-page__container {
  padding: 41px 24px;
}

.br-manage-materials__first-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  & .br-table-component {
    & .br-table__quick-filter-tabs {
      margin-bottom: 0;
      & .br-table__header {
        padding: 16px 0;
      }
    }
    & .br-table__header__actions {
      display: none;
    }
  }
}

.br-manage-material__material-column {
  display: flex;
  flex-direction: column;
  & span.caption {
    color: @gray-500;
  }
}

.br-manage-materials__free-item-text {
  color: @green-600;
}

.br-manage-material__status-tag {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: @gray-200;
  width: fit-content;
  color: @gray-500;
  &.br-manage-material__enabled-tag {
    background: @green-100;
    color: @green-900;
  }
}

.br-manage-material__edit-icon {
  cursor: pointer;
}

.br-manage-materials__table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.br-manage-material__edit-column {
  display: flex;
  justify-content: flex-end;
}

.br-manage-materials__table-header__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
