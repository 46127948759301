@import 'styles/variables';

.br-orders-mass-upload-container {
  padding: 0;
}

.br-orders-mass-upload {
  width: 100%;
  height: 100%;
  line-height: 1.5715;
  > div:last-child {
    padding: 30px;
  }
}
.br-orders-mass-upload .br-orders-mass-upload__upload-options {
  padding: 30px 30px 0px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid @border-color-14;
  div.br-orders-mass-upload__option {
    padding: 0px;
    padding-bottom: 12px;
  }
  :first-child {
    margin-right: 24px;
  }
}

.br-orders-mass-upload__upload-option-active {
  color: @green-500;
  position: relative;
}

.br-orders-mass-upload__upload-option-active:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0;
  border-bottom: 2px solid @green-500;
}

.br-orders-mass-upload__left-side {
  margin-right: 72px;
}

.br-orders-mass-upload .ant-upload {
  margin-left: auto;
}

.br-orders-mass-upload__old-template {
  display: flex;
  margin-bottom: 13px;
  padding: 8px 12px;
  border-radius: 8px;
  background: @background-color-26;
  border: 1px solid @border-color-22;
}

.br-orders-mass-upload__old-template p {
  margin: 0;
  color: @text-color;
}

.br-orders-mass-upload__old-template .anticon {
  margin-top: 4px;
  margin-right: 10px;
}

.br-orders-mass-upload__old-template__title {
  font-family: @poppins-font-600;
  // font-size: 12px;
  font-weight: 600;
  color: @text-color;
}

/* rtl:ignore */
html[dir='rtl'] {
  .br-orders-mass-upload__old-template__title,
  .br-orders-mass-upload__old-template__sub-title,
  .br-orders-mass-upload__old-template__hint {
    direction: ltr;
  }
}

@media only screen and (max-width: 767px) {
  .br-orders-mass-upload > div {
    flex-direction: column;
  }

  .br-orders-mass-upload > div > div:nth-child(1) {
    margin-bottom: 10px;
  }

  .br-orders-mass-upload {
    padding-top: 10px;
  }
}

.br-orders-mass-upload .ant-upload {
  max-height: 312px;
  max-width: 570px;
  background-color: @background-color;
}

.br-orders-mass-upload .ant-upload-hint {
  display: flex;
  flex-direction: column;
}

.br-orders-mass-upload .ant-upload .ant-btn {
  margin: 0 auto;
}

.br-orders-mass-upload__steps {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.br-orders-mass-upload__steps__title {
  color: @text-color;
  font-family: @poppins-font-600;
  font-weight: 600;
  margin-bottom: 10px;
}

.br-orders-mass-upload__steps .ant-btn.ant-btn-link {
  display: inline-flex;
  font-size: 16px;
  padding: 0;
  color: @text-color-27;
  text-decoration: underline;
}

.br-orders-mass-upload__steps .ant-btn.ant-btn-link span {
  font-family: @poppins-font-500;
}

.br-orders-mass-upload__business-label {
  margin-top: 30px;
  color: @text-color;
  font-family: @poppins-font-500;
  font-weight: 600;
  margin-bottom: 10px;
}

.br-orders-mass-upload__business-selector {
  max-width: 430px;
}

.br-orders-mass-upload .ant-upload-drag-icon svg {
  height: 77px;
  width: 60px;
}

.br-orders-mass-upload .ant-upload-text {
  color: @text-color;
  font-size: 14px;
}

.br-orders-mass-upload .ant-upload-hint {
  color: @text-color-4;
  font-size: 13px;
  margin-bottom: 15px;
}

.br-orders-mass-upload .ant-upload .ant-btn {
  border-radius: 4px;
  border: 1px solid @border-color-17;
  box-shadow: 0px 2px 2px 0px @box-shadow;
}

.br-orders-mass-upload .ant-upload.ant-upload-drag .ant-btn span {
  color: @text-color-33;
  font-family: @poppins-font-500;
  font-size: 14px;
}
