@import 'styles/variables';

.br-print-awb-modal__warning-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.br-print-awb-modal__header-text {
  text-align: center;
}

.br-print-awb-modal__images-slider {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 450px;
  margin-bottom: 25px;
}

.br-print-awb-modal__footer-buttons {
  display: flex;
  justify-content: space-between;
}

.br-print-awb-modal__cancel-button {
  width: 35%;
}

.br-print-awb-modal__confirm-button {
  width: 60%;
}
