@import 'styles/variables';

.br-hubs-receive-from-business__pickup-scanning__business-info.ant-card {
  > .ant-card-head {
    border-bottom: unset;

    > .ant-card-head-wrapper > .ant-card-head-title {
      .display-xs();
      text-align: center;
    }
  }

  > .ant-card-body {
    padding-top: @spacing-2xl;

    div > label {
      .body-medium();

      > span {
        .caption-lg();
        color: @gray-500;
      }
    }

    .pickup-scanning__business-info__business-name {
      margin-bottom: @spacing-xl;

      > span.ant-input-affix-wrapper {
        background-color: @gray-50;
        border-radius: 4px;
        box-shadow: @shadow-xs;

        > input {
          color: @gray-900;
          padding-inline-start: @spacing-md;
          background-color: inherit;
        }
      }
    }
  }
}