@import 'styles/variables.less';

.br-zoning__header-title {
  margin-bottom: 3px;
}

.br-zoning__header {
  margin-bottom: 19px;
}

.br-zoning__hub-selector .ant-select {
  width: 200px;
  margin-left: 8px;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid @gray-300;
  }
}

.br-zoning__add-button {
  .br-button-component__label {
    margin-left: 4px;
  }
}

.br-zoning-add-new__dropdown {
  .ant-dropdown-menu-item {
    min-width: 243px;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 16px;
    }
  }

  .ant-dropdown-menu {
    border-radius: 8px;
    padding: 0;
  }
}

.br-zoning__zone-expander {
  max-width: 200px;

  .ant-row.ant-form-item {
    min-height: auto;
  }
  .ant-row.ant-form-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
    margin-top: 20px;
  }

  .ant-form-item-control-input-content {
    min-height: auto;
  }

  .ant-form-item-control {
    width: auto;
  }

  .ant-form-item > .ant-form-item-label {
    margin-bottom: 0;
  }
}

.br-zoning-availability__banner {
  margin: 20px 0;
  .br-banner-content__parent .br-banner-content {
    padding: 4px;
  }
}
