@import 'styles/variables';

.br-pickup-activity {
  > .br-pickup-details-content > *:has(.ant-tabs) {
    padding-top: 0;
    padding-inline: 0;

    .ant-tabs-content-holder {
      padding-inline: @spacing-lg;
    }
  }

  &-call-log.ant-tabs {
    > .ant-tabs-nav {
      position: sticky;
      top: 0;
      margin-bottom: 0;
      background-color: @white;
      box-shadow: @border-all-sides;

      > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        width: 100%;

        > .ant-tabs-tab {
          flex: 1 0;
          margin-inline-start: 0;

          > .ant-tabs-tab-btn {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  &-entry {
    display: flex;
    gap: @spacing-sm;
    padding: @spacing-lg;

    &:last-of-type {
      padding-bottom: 0;
    }

    &:not(:last-of-type) {
      box-shadow: @border-bottom;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: @spacing-xs;

      > .ant-typography {
        .body();
        color: @gray-500;

        margin-bottom: 0;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title.ant-typography {
        .button-lg();
        color: @gray-900;
        text-transform: capitalize;
      }

      .successful-entry {
        &.ant-typography {
          color: @green-600;
        }

        svg > *, &:is(svg) > * {
          fill: @green-600;
        }
      }

      .failed-entry {
        &.ant-typography {
          color: @red-600;
        }

        svg > *, &:is(svg) > * {
          fill: @red-700;
        }
      }
    }
  }
}