@import "styles/variables";

article.br-business-details {
  .br-business-details__default-location__field-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .br-business-details__default-location-cell {
    > div {
      &, > span {
        .body();
        margin-inline-end: @spacing-xs;
      }
    }

    > p {
      .caption-lg();
      color: @gray-500;
    }
  }

  html:has(&) .ant-tooltip > .ant-tooltip-content {
    width: 180px;

    &:has(.business-contacts) {
      width: 237px;
    }

    .ant-tooltip-inner {
      padding: @spacing-sm;
      background-color: @gray-800;

      div {
        .caption-medium();

        > span {
          .caption();
          float: inline-end;
        }

        &:not(:last-of-type) {
          margin-bottom: @spacing-sm;
        }
      }
    }
  }
}

