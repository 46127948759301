@import 'styles/variables';

.br-compensation-info-content-container {
  overflow: auto;
  width: 100%;
  padding: 20px;
  padding-top: 0;
  & .ant-divider-horizontal.ant-divider-with-text {
    padding: 8px 0;
    font-size: 12px;
    color: @gray-400;
    &::before,
    &::after {
      transform: translateY(50%);
    }
  }
}

.br-compensation-info-content-container .ant-form .ant-form-item-extra {
  font-size: 14px;
  color: @gray-500;
  margin-top: 4px;
}

.br-compensation-info-content-container .ant-form .ant-row.ant-form-item {
  margin-bottom: 20px;
}

.br-compensation-info-content-container
  .ant-form
  .ant-row.ant-form-item.br-compensation__order-type-radio {
  margin-bottom: 0;
}

.br-compensation__order-type-radio.ant-row.ant-form-item {
  min-height: 52px;
}

.br-compensation-form__no-of-items-container {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 23px;
}

.br-compensation-form__no-of-items-content {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & .br-compensation-form__order-id-text {
    font-size: 14px;
    color: @gray-400;
    margin-right: 16px;
    width: 100%;
    max-width: 75px;
  }

  & .ant-form .ant-row.ant-form-item.br-compensation__order-type-radio {
    margin-bottom: 0;
  }

  & .br-compensation__order-type-radio.ant-row.ant-form-item {
    min-height: 32px;
    flex: none;
    width: fit-content;
    margin-right: 0;
    & .ant-radio-button-wrapper {
      margin-right: 12px;
      border-radius: 4px;
      border: 1px solid @gray-200;
      & span {
        font-size: 12px;
        color: @gray-500;
      }
    }
    & .ant-radio-button-wrapper:last-child {
      margin-right: 0;
    }
    & .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
    & .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border: 1px solid @teal-500;
      background: @teal-100;
      & span {
        font-size: 12px;
        color: @gray-900;
      }
    }
  }
}

.br-compensation-form__no-of-items-content:last-child {
  margin-bottom: 0;
}

.br-compensation-info__x-text {
  font-size: 12px;
  color: @gray-500;
  margin: 0 4px;
  width: 100%;
  max-width: 64px;
  text-align: center;
}

.br-compensation__order-assigned-department-check-container.ant-row.ant-form-item {
  & .ant-checkbox-group {
    width: 100%;
    & .ant-row {
      margin-bottom: 16px;
    }
  }

  .br-compensation__order-full-row.br-compensation__order-assigned-department-check-info.ant-row.ant-form-item {
    width: 100%;
    max-width: 95%;
  }

  .br-compensation__order-assigned-department-check-info.ant-row.ant-form-item {
    min-height: 30px;
    margin-bottom: 0;
    margin-top: 8px;
    margin-left: 25px;
    width: 100%;
    max-width: 200px;
    & .ant-input-wrapper.ant-input-group {
      border-radius: 4px;
    }
    & .ant-input-group-addon {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: @gray-900;
    }
    & input.ant-input {
      border-left: 0;
      padding-left: 0;
    }
  }
}

.br-compensation-info__price-text {
  cursor: default;
  color: @gray-900;
  border-bottom: 1px dashed @gray-300;
  margin: 0 8px;
}

.br-compensation-info-tooltip-container.ant-tooltip {
  width: 100%;
  max-width: 244px;
  border-radius: 8px;
  & .ant-tooltip-inner {
    padding: 12px;
  }
}

.br-compensation-info__confrim-order-id-button {
  cursor: pointer;
  color: @teal-500;
}

.br-compensation-info-content-container
  .ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.br-compensation__approved_cfo_md.ant-row.ant-form-item {
  & .ant-checkbox-wrapper p {
    color: @red-500;
  }
  & .ant-form-item-explain.ant-form-item-explain-error {
    display: none;
  }
}

.br-compensation-info-content-container
  .ant-row.ant-form-item.ant-form-item-with-help {
  & .ant-form-item-explain {
    & div {
      color: @red-500;
    }
  }
}

.ant-row.ant-form-item.ant-form-item-has-error input.ant-input {
  border-color: @red-500;
}

.ant-input-number.ant-input-number-in-form-item.br-compensation__input-number-field {
  & .ant-input-number-handler-wrap {
    opacity: 1;
  }
}

.br-compensation__form-order-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;

  > div {
    border-radius: 8px;
    border: 1px solid @gray-200;
  }

  > div:first-of-type {
    width: 100%;
  }

  .ant-row.ant-form-item {
    padding: 0 16px;
  }

  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    border-radius: 4px;
  }
}
