@import 'styles/variables';

.ant-form.ant-form-horizontal.br-edit-delivery-modal__form-container {
  & label,
  .br-form-count__optional-label-container span {
    font-family: @lato-font-400;
    line-height: 20px;
  }

  & .ant-form-item-control-input-content {
    & input,
    textarea,
    .ant-select-selection-item {
      font-family: @lato-font-700;
    }
  }

  & .br-customer-details-modal__work-address {
    & span {
      font-family: @lato-font-700;
    }
    & .ant-checkbox-inner {
      width: 14px;
      height: 14px;
    }
  }
}

.br-reschedule-modal__header__customer-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 16px;
  & span {
    font-size: 18px;
    font-family: @poppins-font-600;
  }
}

.br-edit-customer-details-modal__container {
  width: 602px;
}
