@import 'styles/variables';

.br-pickup-activity-timeline-item {
  @bullet-point-size: 12px;
  @timeline-strip-width: 2px;

  position: relative;
  padding-bottom: @spacing-xl;

  > * {
    padding-inline-start: @bullet-point-size + @spacing-sm;
  }

  &__title::before, &::after {
    content: '';
    position: absolute;
    inset-inline-start: 0;
  }

  &::after {
    top: calc(@bullet-point-size * 1.5);
    inset-inline-start: calc((@bullet-point-size / 2) - (@timeline-strip-width / 2));

    height: 100%;
    width: @timeline-strip-width;
    background-color: @gray-300;
  }

  &:last-of-type {
    &::after {
      content: unset;
    }

    padding-bottom: 0;
  }

  &__title {
    position: relative;

    .button-lg();
    color: @gray-900;

    text-transform: capitalize;

    &::before {
      position: absolute;
      z-index: 1;

      top: 50%;
      transform: translateY(-50%);

      width: @bullet-point-size;
      height: @bullet-point-size;

      border-radius: 9999px;
      background-color: @gray-400;
    }
  }

  &__time.ant-typography, &__user.ant-typography {
    .body();
    color: @gray-500;
  }


  &__time.ant-typography {
    margin-bottom: @spacing-xs;
  }

  &__user.ant-typography {
    margin-bottom: 0;
  }

}