@import 'styles/variables.less';

.br-businesses-search__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .ant-btn {
    width: 170px;
  }

  & > div {
    display: flex;
    gap: 4px;
  }
}

.br-businesses-search-actions__right-side {
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > div {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}
@media only screen and (max-width: @lg) {
  .br-businesses-search__actions{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }
  .br-businesses-search-actions__right-side{
    align-items: flex-start;
  }
}
@media only screen and (max-width: @md) {
  .br-businesses-search-actions__right-side{
    & > div{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}
@media only screen and (max-width: @sm) {
  .br-businesses-search__actions,
  .br-businesses-search-actions__right-side {
    justify-content: unset;
    flex-direction: column;

    & > div {
      width: 100%;
      flex-direction: column;
    }

    & > div:first-child {
      margin-bottom: 20px;
    }

    .ant-btn {
      width: 100%;
    }
  }
}
