@import 'styles/variables.less';

.br-create-vendor__form-row {
  display: flex;
}

.br-create-vendor__divider {
  border-color: @border-color-28;
}

.br-create-vendor__clerk-divider {
  margin: 4px 0;
}

.br-create-vendor__add-email-container {
  display: flex;
  padding: 8px;
  & input {
    border: 1px solid @border-color-16;
  }
  & input::placeholder {
    color: black;
  }
  & a {
    flex: none;
    display: flex;
    padding: 8px;
    margin-left: 8px;
    cursor: pointer;
    background: @background-color-16;
    align-items: center;
    color: white;
  }
  & a:hover {
    color: white;
  }
}

.br-create-vendor__email-error {
  padding: 0 8px;
  font-size: 12px;
  color: @text-color-27;
}

.ant-btn.br-create-vendor__action-button {
  margin-top: 32px;
  font-size: 16px;
  padding: 16px 40px;
  width: fit-content;
  justify-content: flex-end;
  justify-self: flex-end;
}
