@import 'styles/variables';

.br-pickup-summary {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto 1fr repeat(3, auto);

  gap: @spacing-md;
  align-items: center;

  box-shadow: @border-all-sides;
  background-color: @white;
  border-radius: 16px;
  padding: @spacing-lg;

  @media only screen and (max-width: @md) {
    grid-template-columns: unset;
  }

  &__icon {
    width: 48px;
    height: 48px;
    padding: @spacing-sm;

    border-radius: 9999px;
    background-color: @teal-200;

    > * {
      fill: @teal-500;
    }
  }

  &-info {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, auto) 1fr;
    column-gap: @spacing-md;

    > * {
      width: fit-content;
    }

    &__title.ant-typography {
      .display-xs();
      margin-bottom: 0;
    }

    &__business-name.ant-typography {
      .body-medium();
      color: @gray-500;
      margin-bottom: 0;

      grid-row-start: 2;
    }
  }
}