@import 'styles/variables';

.app-container div.br-purchase-order {
  height: max-content;
}

.br-po-content {
  padding: 114px 24px 32px;
  background: @bg-gray-light;
  min-height: 100vh;
  display: flex;
  gap: 24px;
  width: 100%;

  .start-side,
  .end-side {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: max-content;
  }

  .start-side {
    flex: 27%;
  }

  .end-side {
    flex: 73%;
  }
}

.br-po-section {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  height: max-content;
  background-color: @white;
}

.br-po-section__title {
  padding: 16px 24px;
  background-color: @bg-gray-light;
  box-shadow: @border-bottom;
  border-radius: 8px 8px 0 0;
  .font({.display-xs()});
}

.br-po-section__content {
  padding: 24px;
  background-color: @white;
  border-radius: 0 0 8px 8px;
}
