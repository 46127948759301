@import 'styles/variables';

.br-new-delivery-detials__containter {
  padding: 24px;
}

.br-new-delivery-detials__containter .br-banner-content__parent {
  margin-bottom: 32px;
}

.br-order-info__col-data span.br-delivery-details__payment-type-text {
  color: @text-color-47;
  & svg {
    margin-right: 8px;
  }
}

.br-order-info__col-data {
  .br-deliveries__item-value-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > img {
      max-height: 150px;
      max-width: 60%;
    }

    > button > span {
      margin-bottom: 0;
      color: @text-color-47;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-new-delivery-detials__containter {
    padding: 0;

    & .ant-col {
      max-width: 100%;
      flex: 1 1 auto;
    }
  }

  .br-order-info__col-8 {
    margin-left: 0;
  }
}
