.br-assign-hub-modal__select {
  width: 100%;
}

.br-assign-hub-modal__footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  & .ant-btn:last-child {
    margin-left: 10px;
  }
}
