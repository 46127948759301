@import 'styles/variables.less';

.br-tabs .br-tabs__tab {
  height: 44px;
  background-color: @bg-default;
  .ant-tabs-nav-wrap {
    margin-left: 20px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }

  & .ant-tabs-tab {
    color: @text-gray;
    padding: 12px;
    .font({.body-medium()});
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @teal-500;
  }
  .ant-tabs-tab-btn:active {
    color: @teal-500;
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    background-color: @teal-500;
  }
}
