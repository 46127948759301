@import 'styles/variables';

a.br-linked-orders__original-so-link {
  display: inline-flex;
  gap: 4px;
  color: @text-teal;

  svg path {
    stroke: @text-teal;
  }
}
