@import 'styles/variables';

.br-action-center-container-title {
  font-family: @poppins-font-600;
  font-size: 18px;
}

.br-action-center__content {
  display: flex;
  gap: 24px;
  min-height: 80vh;
}

.br-action-center-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.br-action-center-hubs-filter {
  min-width: 200px;

  & .ant-select {
    width: 100%;
  }
}
