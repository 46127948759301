@import 'styles/variables';

.br-audit-actions-hub-leader__phone {
  display: block;
  color: @gray-400;
  font-size: 12px;
}

.br-audit-actions-table-tooltip {
  min-width: 156px;
  font-family: @lato-font-400;
  color: @gray-300;
}

.br-audit-actions__table {
  & .ant-tooltip-inner {
    background: @gray-800;
    border-radius: 4px;
  }
}

.br-audit-actions__content {
  border: 1px solid @gray-300;
  border-radius: 8px;
  margin-top: 28px;
}

.br-audit-actions-table-tooltip__item {
  display: flex;
  justify-content: space-between;
}

.br-audit-actions__table {
  & .ant-table {
    border-radius: 8px;
  }

  & .br-searchable-component__header {
    padding: 10px;
    min-height: 70px;

    & > div {
      width: 70%;
    }

    & .br-searchable-component__header__search-input.ant-input-search {
      width: 100%;
      padding: 0 16px;
      border: unset;

      & .ant-input-affix-wrapper {
        border-radius: 8px;
        min-height: 40px;
      }
    }
  }

  & .ant-table-container table > thead > tr:first-child th.ant-table-cell {
    background-color: @gray-50;
    color: @gray-500;
    border-radius: 0;
  }

  & .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0;
  }

  & .br-searchable-table__listing .ant-table-content {
    border-radius: 0 0 8px 8px;
    border: unset;
  }
}

.br-audit-actions__table {
  & .ant-table-thead {
    & .ant-table-cell {
      border-top: 1px solid @gray-300;
      border-bottom: 1px solid @gray-300;
    }
  }

  & .br-searchable-table__listing .ant-table .ant-table-cell {
    color: @gray-900;
    padding: 6px;
  }
}

.br-audit-actions__table {
  & .br-searchable-component__header__export.ant-btn {
    margin-right: 10px;
    min-height: 40px;
    border-radius: 8px;
  }
}
