@import 'styles/variables';

.br-selectable-card__container {
  height: 96px;
  width: 179px;
  padding: @spacing-lg @spacing-md @spacing-lg @spacing-lg;
  border-radius: 12px;
  background-color: @white;
  box-shadow: @shadow-xs;
  position: relative;
}

.br-selectable-card__container .ant-radio {
  position: absolute;
  right: 10px;
  top: 10px;
}

.br-selected-card__active-card {
  border: 1px solid @teal-400;
}

.br-selectable-card__header {
  margin-top: 16px;
}

.br-selectable-card__title {
  margin-left: 16px;
}

.br-selectable-card__values-wrapper {
  margin-top: 10px;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.br-selectable-card__title {
  color: @gray-500;
}

.br-selected-card__active-card .br-selectable-card__title {
  color: @teal-500;
}

.br-selected-card__fake .br-selectable-card__main-value {
  color: @red-600;
}

.br-selectable-card__values-wrapper .br-selectable-card__main-value {
  margin-inline-end: 8px;
}

.br-selectable-card__sub-value {
  color: @gray-500;
  background-color: @gray-100;
  padding: 2px 8px;
  border-radius: 8px;
  min-width: 44px;
  height: 24px;
}
