@import 'styles/variables';

.single-info__container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid @border-color-23;
  margin-bottom: 2%;
  justify-content: space-between;
}

.single-info__container .single-info__header svg path {
  fill: @icon-disabled;
}

.single-info__header {
  color: @gray-500;
  font-weight: 600;
}

.single-info__data-container h6 {
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.single-info__data-container h6 svg {
  margin-left: 8px;
}

.single-info__data-container h6 svg:hover {
  cursor: pointer;
}

.single-info__pinding-orders {
  color: @yellow-600;
}

.single-info__damaged-orders {
  color: @red-600;
}
