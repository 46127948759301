.br-bosta-shop__add-quota-modal-container.ant-modal {
  & .br-bosta-shop-modal__form {
    & .ant-input-number {
      width: 100%;
    }
  }
  & .ant-modal-footer {
    justify-content: flex-end;
  }
}
