@import 'styles/variables';

.br-packages-debrief__rto-tab {
  .ant-table-content .ant-table-thead {
    & > tr > th {
      background: @background-color-61;
      color: unset;
      font-family: @lato-font-700;
    }
  }
}