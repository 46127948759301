@import 'styles/variables';

.br-international-pickup-logs__timeline {
  .ant-timeline-item-head {
    width: 14px;
    height: 14px;
    background-color: @bg-gray;
    border: none;
  }

  .ant-timeline-item-tail {
    left: 6px;
  }
}

.br-international-pickup__user {
  color: @text-gray;
}
