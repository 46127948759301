@import 'styles/variables';

.br-dispatching-card__container {
  padding: 16px 24px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  flex: 1;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  &.border-yellow {
    border-top: 4px solid @yellow-600;
    & .br-dispatching-card__count {
      color: @yellow-600;
    }
  }

  &.border-red {
    border-top: 4px solid @red-700;
    & .br-dispatching-card__count {
      color: @red-700;
    }
  }
}

.br-dispatching-card__count {
  font-family: @poppins-font-600;
  font-size: 24px;
}

.br-dispatching-card__title {
  color: @gray-500;
}
