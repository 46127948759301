@import 'styles/variables';

.br-wrong-scan__subtitle {
  color: @text-gray;
}

.br-wrong-scan__container {
  .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }

  .br-table__quick-filter-tabs {
    margin-bottom: 16px;
  }
}
