@import 'styles/variables';

.br-change-password__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/imgRevamp/Registration-bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
  width: 100%;
}

.br-change-password__card {
  box-shadow: @shadow-lg;
  border-radius: 8px;
  background-color: @white;
  padding: 40px;
  max-width: 450px;

  .ant-form-item-has-success.ant-form-item-has-feedback .anticon-check-circle {
    display: none;
  }

  .ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
    line-height: 10px;
  }

  .ant-form-item-explain {
    margin-top: 8px;
  }
}

.br-change-password__title {
  margin: 24px 0;

  .font({.display-sm()});
}

.br-change-password__submit-btn {
  width: 100%;
  margin-top: 12px;
}

@media only screen and (max-width: @sm) {
  .br-change-password__card {
    max-width: 90%;
    margin: 0 16px;
  }
}
