@import 'styles/variables.less';

.br-barcodes-to-awb__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-width: 216px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-barcodes-to-awb__card-title {
  color: @text-gray;
}

.br-barcodes-to-awb__card-count {
  color: @text-gray-dark;
}

.br-barcodes-to-awb__card-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;

  & > .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
  }

  &.yellow {
    background-color: @yellow-50;

    svg > * {
      fill: @yellow-600;
    }
  }

  &.green {
    background-color: @green-50;
    svg > * {
      fill: @green-600;
    }
  }
}
