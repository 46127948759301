@import 'styles/variables';

.br-order-logs-card {
  .ant-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }

    .ant-timeline-item-content {
      min-height: unset;
    }
  }
}

.br-order-logs__date {
  color: @text-gray;
  text-transform: uppercase;
  .font({.subheading()});
}
