.br-filter--multi-select--container {
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
      display: flex;

      .ant-checkbox {
        margin-top: 4px;
      }
    }
  }
}
