@import 'styles/variables';

.br-pickup-summary-info-card {
  text-align: center;

  background-color: @teal-100;
  border-radius: 8px;
  padding: @spacing-sm;

  &__title.ant-typography {
    .caption();
    color: @gray-900;

    display: block;
  }

  &__value.ant-typography {
    .heading();
    color: @teal-500;
  }

  &.muted {
    background-color: @bg-gray-light;
  }

  &.muted > &__value.ant-typography {
    color: @gray-400;
  }
}