@import 'styles/variables';

.br-packaging-balance__container {
  padding: 41px 24px;
}

.br-packaging-balance__daily-section,
.br-packaging-balance__orders-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin-bottom: 16px;
}

.br-packaging-balance__orders-section {
  justify-content: space-between;
}

.br-packaging-balance__items-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.br-packaging-balance__items-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > span {
    text-transform: uppercase;
    color: @gray-400;
  }
  & > div {
    & svg {
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
      }
    }
    & svg.disabled path {
      fill: @gray-300;
    }
  }
}

.br-packaging-balance__items-row {
  display: flex;
  gap: 16px;
  width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > div {
    display: flex;
    padding: 8px 16px 8px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    width: 300px;
    background-color: white;
    & > div:first-child {
      display: flex;
      align-items: center;
      width: 300px;
      gap: 4px;
      & > div {
        display: flex;
        flex-direction: column;
        & > span:last-child {
          color: @gray-500;
          white-space: pre-line;
        }
      }
    }
    & > div:last-child {
      display: flex;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: @teal-200;
      & span {
        color: @teal-500;
      }
    }
  }
}

.br-packaging-balance__tooltips {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: @gray-800 !important;
    }

    .ant-tooltip-arrow-content {
      background-color: @gray-800 !important;
      --antd-arrow-background-color: @gray-800;
    }
  }
}

.br-packaging-balance__content-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
}

.br-packaging-balance__orders-content-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: @shadow-sm;
  & .br-packaging-balance__items-row > div {
    border: 1px solid @gray-200;
  }
}

.br-packaging-balance__order-id-text {
  color: @teal-500;
}

.br-packaging-balance__customer-info-container {
  display: flex;
  flex-direction: column;
  & > span:last-child {
    color: @gray-500;
  }
}

.br-packaging-balance__status-text {
  display: flex;
  flex-direction: row;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  width: fit-content;
  &.grey {
    background-color: @gray-100;
  }
  &.green {
    background-color: @green-100;
    color: @green-900;
  }
}

.br-packaging-balance__amount-container {
  display: flex;
  flex-direction: column;
  & span:last-child {
    color: @gray-500;
  }
}
