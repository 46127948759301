@import 'styles/variables.less';

.br-contact-person-modal .ant-modal-content {
  border: 2px solid @border-color-10;
  border-radius: 8px;
}

.br-contact-person-modal .ant-modal-header {
  border-bottom: unset;
  padding: unset;
}

.ant-modal-root .ant-modal-wrap.br-contact-person-modal .ant-modal {
  // max-width: 620px;
  width: 620px;
}

.br-contact-person-modal .ant-form-item-label > label {
  margin-bottom: 0px;
}

.br-contact-person-modal .ant-modal-footer {
  border-top: unset;
}

.br-contact-person-modal .ant-modal-close {
  display: none;
}

.br-contact-person-modal__sub-heading {
  color: @text-color;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 22px;
}

.br-contact-person-modal__heading {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.br-contact-person-modal__form__row .br-contact-person-modal__form__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.br-contact-person-modal__form__item input {
  width: 279px;
  height: 32px;
  max-width: 100%;
  border: 1px solid @border-color-18;
  border-radius: 4px;
  background-color: @background-color;
}

.br-create-contact-modal__form__footer {
  display: flex;
  justify-content: flex-end;
}

.br-create-contact-modal__form__footer .ant-btn-primary {
  margin-left: 10px;
}

.br-contact-person-modal .ant-modal-footer {
  border-top: unset;
  display: flex;
  justify-content: flex-end;
}

.br-create-contact-modal__form__set-contact-default .ant-checkbox + span {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 13px;
}

.br-create-contact-modal__form__footer
  div.br-create-contact-modal__form__set-contact-default {
  min-height: 0;
}

@media only screen and (max-width: 672px) {
  .br-contact-person-modal__form__row {
    display: flex;
    flex-direction: column;
    flex-basis: content;
  }

  .br-contact-person-modal__form__row .br-contact-person-modal__form__item {
    width: 100%;
  }

  .br-contact-person-modal__form__item input {
    width: 550px;
    height: 32px;
    max-width: 100%;
  }
}
