@import 'styles/variables.less';

.br-order-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  padding-top: 0;
}

.br-order-summary_vertical-alignment {
  padding: 150px;
}

.br-order-summary__title {
  color: @text-color;
  font-size: 17px;
  font-family: @poppins-font-600;
  font-weight: 600;
}

.br-order-summary__sub-title {
  color: @text-color-31;
  font-size: 13px;
  font-family: 'Lato/Bold';
  font-weight: 500;
  margin-bottom: 20px;
}

.br-order-summary__card {
  border: 1px solid @border-color-17;
  border-radius: 8px;
  width: 536px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 60px;
}

.br-order-summary img {
  width: 416px;
}

.br-order-summary .anticon-check-circle {
  font-size: 60px;
  color: @text-color-29;
  margin-bottom: 10px;
}
.br-order-summary__card__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-order-summary__card__print-button {
  height: 34px;
  width: 151px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.br-order-summary__card__print-note {
  color: @text-color-31;
  font-size: 13px;
  font-family: 'Lato/Bold';
  font-weight: 500;
}

.br-order-summary__footer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.br-order-summary__footer_create-more-orders-button {
  margin-right: 8px;
}

.br-order-summary__footer_create-more-orders-button span {
  margin-right: 8px;
  color: @text-color-33;
  font-size: 14px;
  font-family: 'Lato/Bold';
  font-weight: 500;
}
