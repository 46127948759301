.br-sales-order__total-orders-price {
  padding: 16px 24px;
}

.br-sales-order__total-orders-price__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
