@import 'styles/variables.less';

.br-create-edit-star__tooltip .ant-tooltip-inner {
  font-size: 13px;
  width: fit-content;
  white-space: nowrap;
}

.br-create-edit-star__title,
.br-create-edit-star__salary-section__title,
.br-create-edit-star__salary-customization {
  font-family: @lato-font-700;
}

.br-create-edit-star__title {
  color: @text-color;
  font-size: 18px;
  margin-bottom: 19px;
}

.br-create-edit-star .ant-divider-horizontal,
.br-create-edit-star__footer {
  margin: 32px 0;
}

.br-create-edit-star__fleet-type-form-item .anticon.anticon-check {
  display: none;
}

.br-create-edit-star .ant-checkbox-wrapper {
  margin-right: 11px;
}

.br-create-edit-star__fleet-type-form-item .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}

.br-create-edit-star__salary-sublabel {
  font-size: 13px;
  font-family: @lato-font-400;
  color: @text-color-31;
}

.br-create-edit-star__salary-disabled-sublabel {
  color: @text-color-8;
}

.br-create-edit-star__salary-add-action.ant-btn {
  height: 32px;
}

// .br-create-edit-star__salary-add-action.ant-btn:not(.ant-btn[disabled]),
// .br-create-edit-star__salary-add-action.ant-btn:hover:not(.ant-btn[disabled]) {
//   background-color: @background-color-62;
//   color: @text-color-15;
//   border-color: @background-color-62;
// }

.br-create-edit-star__salary-add-action {
  margin-top: 13px;
}

.br-created-edit-star__salary-confirgarion {
  border: 1px solid @border-color-23;
  border-radius: 8px;
  background-color: @background-color-63;
}

.br-create-edit-star__salary-section {
  padding: 16px;
  background-color: @text-color-15;
  border-radius: 8px;
}

.br-create-edit-star__salary-section__right-content,
.br-create-edit-star__salary-customization,
.br-create-edit-star__empty-salary-section {
  display: flex;
  flex-direction: column;
}

.br-create-edit-star__salary-form {
  padding: 16px;
  border-bottom: 1px solid @border-color-23;
}

.br-create-edit-star__empty-salary-section {
  align-items: center;
  margin-bottom: 13px;
  margin-top: 6px;
}

.br-create-edit-star__salary-section__hint {
  margin-top: 15px;
  font-size: 14px;
}

.br-create-edit-star__salary-section__title {
  font-size: 14px;
  margin-bottom: 10px;
}

.br-create-edit-star__salary-section__subtitle {
  font-size: 13px;
  margin-bottom: 29px;
}

.br-create-edit-star__salary-section .ant-btn-danger {
  margin-bottom: 29px;
}

.br-create-edit-star__salary-customization {
  margin-top: 10px;

  & span {
    margin-bottom: 6px;
  }

  & .ant-input-number {
    width: 100%;
  }
}

.br-star__zones-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.br-form__national-id {
  margin-bottom: 16px;
}

.br-form__display-national-id {
  display: flex;
  max-width: 50%;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 10px;

  > a > img {
    width: 150px;
    height: 130px;
    object-fit: cover;
    object-position: center;
  }
}
