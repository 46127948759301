@import 'styles/variables';

.br-otp-validation__title {
  font-size: 14px;
  margin-top: 5px;
}

.br-otp-validation__subtitle {
  display: block;
  font-size: 13px;
  color: @text-color-31;

  & span {
    color: @text-color-29;
  }
}

.br-otp-modal__container .br-content-header-with-sub-title {
  margin-bottom: 17px;
}

.br-otp-validation__receive-confirmation {
  font-size: 11px;
  color: @text-color-31;
}

.br-opt-modal__form-text .br-otp-code__resend-code-link,
.br-opt-modal__form-text .br-otp-code__resend-code-link-disabled {
  padding: 0px;
}

.br-otp-code__resend-code-link span,
.br-otp-code__resend-code-link-disabled span {
  font-size: 13px;
  text-decoration: underline;
}

.br-otp-validation__receive-confirmation__timer {
  color: @text-color-29;
  font-weight: bold;
}

.br-otp-code__resend-code-link span {
  color: @text-color-29;
}

.br-otp-code__resend-code-link-disabled span {
  color: @text-color-32;
}

.ant-btn.br-otp-code__resend-code-link {
  padding: 0;
}

.br-otp-modal__container .ant-modal-close svg {
  font-size: 17px;
  color: @text-color-34;
}

.br-otp-modal__container
  .ant-form-item:not(:last-child)
  .ant-form-item-control-input-content::after {
  padding: 4px;
  content: '-';
}
