.br-pickup-map-modal__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;

  & > div {
    height: 500px !important;
  }
}
