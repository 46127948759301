.br-permissions-select__container {
  & > div:last-child {
    overflow-y: auto;
    max-height: 300px;
  }
}

.br-permissions-button {
  width: 100%;
  min-height: 36px;

  & > span:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.br-permissions-select__checkbox {
  min-width: 100%;
}
