@import 'styles/variables';

.br-hubs-receive-from-business {
  .ant-card {
    border-radius: 16px;
    box-shadow: @border-all-sides;
    background-color: @gray-50;
  }

  &__pickup-scanning.ant-card {
    background-color: @white;

    > .ant-card-head {
      .display-sm();
      border-bottom: unset;

      > .ant-card-head-wrapper > .ant-card-head-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    > .ant-card-body {
      display: grid;
      grid-auto-flow: row;
      gap: @spacing-xl;

      @media screen and (min-width: @lg) {
        grid-template-columns: 1fr 2fr;
      }

      &::before, &::after {
        content: unset;
      }
    }
  }
}
