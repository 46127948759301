//@import '~react-widgets/dist/css/react-widgets.css';

// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

.DayPickerInput {
  input {
    @extend .form-control;
    width: 100%;
    height: 45px;
  }
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.ant-picker-range {
  width: 100%;
}

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon,
.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: none;
}
