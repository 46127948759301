.br-dates-filter {
  .br-date-filter-form-item.ant-row.ant-form-item {
    flex: 1;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .ant-drawer-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}
