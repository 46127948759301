@import 'styles/variables.less';

.br-create-star-salary-zone .br-content-header__title {
  font-size: 24px;
  font-family: @poppins-font-600;
}

.br-create-star-salary-zone .ant-modal {
  width: 920px;
}

.br-create-star-salary-zone__content {
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
  }
}

.br-create-star-salary-zone .ant-row.ant-form-item:not(:last-child) {
  margin-bottom: 8px;
}

.br-create-star-salary-zone__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
  & .ant-btn:first-child {
    background-color: @background-color-18;
    border-color: @background-color-18;
    margin-right: 12px;
  }
}
