@import 'styles/variables';

.br-international-order__tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  background-color: @purple-500;
  border-radius: 10px;
  max-width: 32px;
  max-height: 19px;

  svg * {
    fill: @white;
    stroke: @white;
  }
}
