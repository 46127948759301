@import 'styles/variables';

.br-rate-star__header {
  color: @text-color-50;
  font-family: @poppins-font-600;
  font-size: 18px;
}

.br-rate-star__number-cards {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.br-rate-star__number-cards__title {
  color: @text-color-50;
  font-size: 16px;
}

.br-rate-star__number-cards__rates {
  margin: 27px 0;
  display: flex;
  flex-direction: column;

  & .ant-form-item-explain-error {
    margin-top: 12px;
  }
}

.br-rate-star__number-card {
  border: 1px solid @border-color-24;
  border-radius: 4px;
  padding: 12px 17px;
  font-size: 16px;
  color: @text-color-47;
}

.br-rate-star__number-card--focused {
  color: @background-color-51;
  background-color: @background-color-50;
  border: 1px solid @background-color-51;
}

.br-rate-star__footer {
  display: flex;
  margin-top: 23px;
  justify-content: flex-end;

  & .ant-btn:first-child {
    margin-right: 12px;
  }
}
