@import 'styles/variables';

.br-return-manifest__header-title {
  font-family: @poppins-font-600;
  font-size: 16px;
}

.br-return-manifest__header-state {
  margin-left: 10px;
}
