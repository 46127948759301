@import 'styles/variables';

.crumbsbar__container {
  margin: 0 auto;
  width: 90%;
  position: relative;
}

.crumbsbar__slider {
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, @teal-400, @yellow-400, @green-500);
  margin-top: 25px;
}

.crumbsbar__point {
  position: absolute;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.crumbsbar__point.crumbsbar__start {
  left: 0%;
  background-color: @teal-400;
}

.crumbsbar__point.crumbsbar__middle {
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: @yellow-400;
}

.crumbsbar__point.crumbsbar__end {
  right: 0%;
  background-color: @green-500;
}
