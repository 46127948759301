@import 'styles/variables';

.br-fulfillment-location-card {
  box-shadow: @shadow-sm;
  background: @white;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 12px);

  &.has-parent {
    width: 221px;
  }

  &.not-have-child {
    cursor: auto;
    pointer-events: none;
  }
}

.br-fulfillment-location-card__title {
  color: @text-gray-dark;
  .font-28;
}

.br-fulfillment-location-card__subtitle {
  color: @text-gray;
  margin-top: 4px;
  .font(.display-xs());
}

.br-fulfillment-location-card__icon.anticon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 40px;
  background: @teal-50;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: @teal-600;
    }
  }
}
