@import 'styles/variables.less';

.br-draft-orders__products-popover .ant-popover-content .ant-popover-inner {
  padding: 0 12px;
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    & .ant-spin-container > div {
      display: flex;
      gap: 12px;
      padding: 12px 0;
      flex-direction: row;
      align-items: center;
      &:not(:last-child) {
        box-shadow: @border-bottom;
      }

      .br-draft-orders__products-popover__image {
        position: relative;
        border: 1px solid @gray-200;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        .br-draft-orders__products-popover__image-count {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -8px;
          top: -7px;
          background-color: @white;
          border: 1px solid @gray-200;
          border-radius: 32px;
          z-index: 2;
          width: 20px;
          height: 20px;
          box-shadow: @shadow-xs;
          color: @text-teal;
        }
      }

      .br-draft-orders__products-popover__info {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex: 1;

        .br-draft-orders__products-popover__title {
          display: flex;
          flex-direction: column;
          width: 183px;
        }

        .br-draft-orders__products-popover__price {
          color: @text-gray;
        }

        > span {
          width: 75px;
          text-align: center;
        }
      }
    }
  }
}
