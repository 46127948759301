@import 'styles/variables';

.br-fulfillment-locations-list {
  background: @bg-gray-light;
  padding: 32px 24px;

  .ant-spin-nested-loading {
    &:has(.br-fulfillment-locations-listing) {
      height: unset;
    }

    .ant-spin-container {
      > div {
        flex-direction: row;

        &.br-fulfillment-locations-list__actions {
          height: auto;
        }
      }
    }
  }
}

.br-fulfillment-locations-list__actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
