@import 'styles/variables';

.br-packages-debrief__tabs__pickups .br-swapping-tables > div {
  &:first-child {
    width: 35%;
  }
  &:last-child {
    width: 65%;
  }
}

.br-pickups-tab-actions__radio-button {
  margin-right: 8px;
  border-radius: 4px;

  & span:last-child {
    font-family: @lato-font-400;
    font-size: 13px;
  }
}

.br-pickups-tab-actions__divider {
  margin-left: 8px;
  height: 24px;
  border: 1px solid #d1d5db;
}

.br-pickups-tab-actions .ant-radio-button-wrapper {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px;
}

.br-pickups-tab-actions .ant-radio-button-wrapper:not(:first-child)::before {
  top: -1px;
  left: -1px;
  width: 0px;
}

.br-pickups-tab-actions
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-left: 1px solid #00b887;
}

.br-pickups-tab-actions {
  margin-bottom: 24px;
}

.br-pickups-tab-actions__label {
  font-size: 12px;
  color: #9ca3af;
  margin: 0 8px;
}

.br-pickups-tab-actions__scannig-type-title {
  font-size: 14px;
  font-family: @lato-font-700;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & .anticon-exclamation-circle {
    margin: 0 4px;
    font-size: 12px;
    color: #9ca3af;
  }
}

.br-pickups-tab-actions__scannig-type-title__only-weights {
  margin-top: 0;
}

.br-pickups-tab-actions__package-size-input.ant-input {
  width: 10.6%;
}

.br-pickups-tab-actions__tracking-number-input.ant-input {
  width: 17%;
  border-left: none;
}

.br-pickups-tab-actions__customized-tracking-number-input.ant-input {
  width: 26%;
}

.br-pickups-tab-actions__package-size-active-input:focus,
.br-pickups-tab-actions__active-tracking-number-input:focus {
  box-shadow: 0 0 0 0 rgb(0 184 135 / 20%);
}

.br-pickups-tab-actions__package-size-active-input,
.br-pickups-tab-actions__active-tracking-number-input {
  border-color: #1fc493;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(0 184 135 / 20%);
}

.br-pickups-tab-actions__active-tracking-number-input,
.br-pickups-tab-actions__active-tracking-number-input:hover {
  border-left: 0;
}

.br-pickups-tab-actions__package-size-active-input,
.br-pickups-tab-actions__package-size-active-input:hover {
  border-right: 0;
}

.br-pickups-tab-actions .ant-input-group.ant-input-group-compact {
  display: flex;
  width: 70%;
}

.br-pickups-tab-actions .ant-input-group.ant-input-group-compact {
  display: flex;
  width: 70%;
}

.br-pickups-tab-actions
  .ant-input-group.ant-input-group-compact.br-pickups-tab-actions__only-weight {
  width: 30%;
}

.br-pickups-tab__scanning-inputs {
  display: flex;
  max-width: 600px;
  div:first-child {
    margin-right: 8px;
  }
  > div {
    flex: 1;
  }
}

.br-pickups-tab-actions__scannig-type-title--soho-note {
  font-size: 12px;
  font-family: @lato-font-700;
  color: @text-color-45;
  margin-right: 8px;
}

.br-pickups-tab-actions .ant-input-number {
  width: 30%;
}

.br-pickups-tab__confirm-receiving-cta{
  margin-top: 8px;
}