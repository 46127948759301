@import 'styles/variables';

.ant-modal.br-add-factors-modal {
  width: 100%;
  max-width: 612px;

  & .br-factor-divider-fake-exception.ant-divider-horizontal {
    margin-top: 42px;
  }

  & .ant-modal-footer {
    justify-content: flex-end;
  }
}

.br-add-factors-container {
  //   padding-bottom: 12px;
}

.br-add-factors-modal__header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .br-add-factors-modal__header-title {
    font-size: 18px;
    font-family: @poppins-font-600;
  }
}

.br-add-factors-modal__performance-factor-container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.br-add-factors-modal__performance-factor-title {
  font-size: 14;
  font-family: @lato-font-700;
  margin-bottom: 12px;
}

.br-add-factors-modal__performance-factor-warning {
  font-size: 14px;
  margin-top: 12px;
}

.br-points-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-points-action-conatiner {
  display: flex;
}

.br-points-action__points-conatiner,
.br-points-action__remove-conatiner {
  display: flex;
  align-items: center;
}

.br-points-action__points-conatiner {
  margin-right: 30px;
  & .ant-input-number {
    max-width: 145px;
    width: 100%;
    margin-right: 8px;
    & .ant-input-number-handler-wrap {
      display: none;
    }
  }
  & input {
    width: 100%;

    // height: 100%;
    height: 40px;
  }

  & span {
    font-size: 13px;
  }
}

.br-points-action__maximum-number-conatiner {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-right: 14px;
  & .ant-input-number {
    max-width: 100px;
    width: 100%;
    & .ant-input-number-handler-wrap {
      display: none;
    }
  }
  & input {
    width: 100%;
    max-width: 100px;
    // height: 100%;
    height: 40px;
  }
  & span {
    position: absolute;
    top: 44px;
    font-size: 9px;
    color: #667085;
  }
}

.br-points-action__remove-conatiner {
  color: #ff0000;
  cursor: pointer;
  & .br-points-action__remove-text {
    font-size: 12px;
    font-family: @lato-font-700;
    margin-left: 12px;
  }
}

.ant-select.br-factors__select-container {
  width: 100%;
  max-width: 266px;
}

.br-add-factors-modal__points-container {
  max-height: 300px;
  overflow: auto;
}
