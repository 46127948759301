@import 'styles/variables';

.br-otp-modal__container .ant-modal-content {
  width: 100%;
  max-width: 480px;
}

.br-otp-modal__container .ant-modal-body {
  padding: 22px 19px;
}

.ant-modal-root .ant-modal-wrap.br-otp-modal__container .ant-modal .ant-modal-close {
  display: block;
  color: @text-color-31;
}

.ant-modal-root .ant-modal-wrap.br-otp-modal__container .ant-modal .ant-modal-close .anticon {
  font-size: 17px;
}

.br-otp-modal__container .ant-modal-body span svg {
  width: 17px;
  height: 17px;
}

.br-otp-modal__footer {
  padding-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.br-otp-modal__close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.br-otp-modal__content .br-otp-modal__receive-confirmation {
  font-size: 11px;
}

.br-otp-modal__content a {
  font-size: 11px;
  text-decoration: underline;
}

.br-otp-modal__form-items span {
  margin: 0 3px;
}
.br-otp-modal__otp-code {
  display: flex;
  flex-direction: column;

  & .br-otp-modal__form-items {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  & .error-visible {
    margin-bottom: unset;
  }

  & .error-visible .ant-row.ant-form-item input {
    border: 1px solid @border-color-15;
  }

  & .ant-row.ant-form-item {
    min-height: unset;
    flex: unset;
    width: unset;
    & input {
      width: 100%;
      max-width: 42px;
      height: 100%;
      min-height: 42px;
      padding: 0;
      text-align: center;
      font-size: 24px;
      font-family: @poppins-font-600;
    }

    & input::placeholder {
      font-size: 24px;
      font-family: @poppins-font-600;
      color: @text-color-34;
    }
  }
}

.br-opt-modal__form-text .br-opt-modal__error-text {
  font-size: 11px;
  color: @text-color-27;
  margin: 5px 0;
}

.br-otp-modal__container .ant-modal-content .br-otp-modal__verify-button {
  padding: unset;
  font-size: 11px;
  text-decoration: underline;
  margin: unset;
  height: auto;
  & span {
    margin: 0;
  }
}
