@import 'styles/variables.less';

.br-validate-modal .ant-modal {
  width: 455px;
}

.br-validate-modal .ant-modal-close {
  display: flex;
}

.br-validate-modal .ant-modal-close svg {
  width: 19px;
  height: 16px;
}

.br-validate-modal__heading {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.br-validate-modal__form__footer {
  display: flex;
  justify-content: flex-end;
}

.br-validate-modal__form__footer .ant-btn-primary {
  margin-left: 10px;
}

.br-validate-modal__form__footer {
  border-top: unset;
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
}

.br-validate-modal__form__footer .ant-btn:not(.ant-btn-circle) {
  padding: 16px 12px;
  height: 38px;
}

.br-validate-modal__form {
  margin-top: 32px;
}

.br-validate-modal .br-validate-model__select {
  margin: 10px 0 20px;
  width: 100%;
}

.br-validate-modal__form div.br-validate-modal__form__textarea {
  margin-bottom: 39px;
}

.br-validate-modal__form__footer .ant-btn-error {
  background: red;
  color: white;
}

.br-validate-modal__textarea {
  margin-top: 37px;
}
