.br-amazon-reports__actions,
.br-amazon-reports__invoice-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-amazon-reports__invoice-actions {
  gap: 16px;

  .br-upload-dialog__upload:not(.br-upload-dialog__upload-empty) {
    .ant-upload-span,
    .ant-upload-text-icon {
      display: flex;
      align-items: center;
    }

    display: flex;
    gap: 16px;
    border: unset;
    padding: 0;
  }
}

.br-amazon-reports__actions {
  padding: 20px 0 50px 0;

  .primaryButton.btn-primary {
    margin-top: 0;
  }
}
