@import 'styles/variables';

.br-session-card__container {
  display: flex;
  justify-self: space-between;
  align-items: center;
  box-shadow: @shadow-sm;
  padding: 24px;
  margin: 16px 0;
  border-radius: 8px;

  & > div:first-child {
    display: flex;
    width: 100%;
  }
}

.br-session-card__session-info {
  margin-left: 8px;

  & > span {
    display: block;
    color: @gray-500;
  }
}
