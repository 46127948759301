@import 'styles/variables';

.br-cashier-approve-modal-container.ant-modal {
  width: 100%;
  max-width: 672px;
}

.br-cashier-approve-modal-title-container {
  display: flex;
  flex-direction: column;
  & > span:last-child {
    color: @gray-500;
  }
}

.br-cashier-approve-modal-content-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.br-cashier-approve-modal-content__card-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: @gray-50;
  border-radius: 8px;
  gap: 24px;
  padding: 20px;
  & > div {
    width: 100%;
    gap: 24px;
    display: flex;
    flex-direction: column;
    & > div.br-cashier-approve-modal-content__input-container {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        & .ant-input-number-affix-wrapper {
          width: 100%;
          padding-inline-start: 12px;
          display: flex;
          align-items: baseline;
          min-height: 36px;
          & .ant-input-number-prefix {
            margin-inline-end: 8px;
          }
        }
      }
    }
    & > div.br-cashier-approve-modal-content__pos-id-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      & .ant-input-number-affix-wrapper {
        width: 100%;
        padding-inline-start: 12px;
        display: flex;
        align-items: baseline;

        & .ant-input-number-prefix {
          margin-inline-end: 8px;
        }
      }
    }
  }
  &.total-amount-content {
    background: @gray-100;
  }
}
