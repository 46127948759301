@import 'styles/variables.less';

.br-department-tooltip-container {
  display: flex;
  flex-direction: column;
}

.br-department-tooltip-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.br-department-tooltip__title {
  word-break: keep-all;
}

.br-department-tooltip__sub_title {
  color: @gray-400;
}
