.br-businesses__2fa-banner {
  margin-bottom: 16px;
}

.br-businesses-table__title {
  margin: 16px 0;
}

.br-business-listing__name {
  text-decoration: underline;
}

.br-businesses__table {
  margin-top: 16px;

  .br-table__quick-filter-tabs {
    margin-bottom: 16px;
  }
}
