@import 'styles/variables';

.br-internatioanl-deliveries__fromTo {
  display: flex;
  align-items: center;
  gap: 4px;
}

.br-international-deliveries__weight {
  text-transform: lowercase;
}

.br-international-deliveries__custom-vat {
  display: flex;
  flex-direction: column;

  > span:last-child {
    color: @text-gray;
  }
}

.br-international-deliveries-e2e__container {
  padding: 24px;
  height: 100%;

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: auto;
  }
}
