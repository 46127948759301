@import 'styles/variables.less';

.br-pricing-plan__header {
  & .br-content-header__titles .br-sidebar-content__sub-title {
    font-family: @lato-font-400;
    font-size: 13px;
  }
}

.br-pricing-plan__cities-dropdown.ant-select-dropdown {
  max-height: 207px;
  padding: 11px 6px;
}

.br-pricing__pickup-location-title {
  font-family: @lato-font-700;
}

.br-pricing-plan__cities-dropdown .rc-virtual-list-holder::-webkit-scrollbar {
  width: 4px;
}

.br-pricing-plan__cities-dropdown
  .rc-virtual-list-holder::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: @scrollbar-thumb-background-color;
}

.br-pricing-plan__cities-dropdown
  .rc-virtual-list-holder::-webkit-scrollbar-track {
  background-color: @scrollbar-track-background-color;
}

.br-pricing-plane__select-options {
  display: flex;
  flex-direction: column;
  & .br-area__highlighted-text {
    background: @background-color-58;
    padding: 2px 0;
  }

  & .br-area__first-highlighter span {
    font-size: 14px;
    font-family: @lato-font-400;
    margin-bottom: 0;
  }

  & .br-area__second-highlighter span {
    font-size: 11px;
    font-family: @lato-font-400;
    margin-bottom: 5px;
  }
}

.br-pricing-plan__pickup-location-desc {
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
  font-family: @lato-font-400;
  color: @text-color-32;
}

.br-pricing-plan__main-content {
  margin-top: 13px;
  box-sizing: unset;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  & .br-pricing-plan__table {
    margin-right: 24px;
    width: 100%;
    max-width: calc(100% - 230px);
    padding-bottom: 20px;

    & .ant-table-content {
      border-radius: 8px;
    }

    & .br-pricing-plan__table-header-title {
      font-family: @lato-font-400;
      display: flex;
      align-items: center;
      & svg {
        width: 9px;
        margin-left: 3px;
        & path {
          fill: @side-bar-icon-color;
        }
      }
    }

    & .ant-table-thead {
      & tr {
        padding: 0px;
      }
      & .ant-table-cell {
        padding: 10px 8px;
        font-size: 12px;
        font-family: @lato-font-400;
        height: 36px;
      }

      & .br-pricing-plan__city-table-header {
        padding: 10px 40px 10px 0;
        & .ant-table-filter-column {
          width: fit-content;
          padding: 6px 0 6px 30px;

          & .ant-table-filter-column-title {
            font-size: 12px;
            font-family: @lato-font-400;
            padding: 0;
          }

          & .ant-table-filter-trigger-container {
            position: relative;
          }
        }
      }
    }

    & tr[data-row-key='0'],
    tr[data-row-key='1'],
    tr[data-row-key='2'],
    tr[data-row-key='3'],
    tr[data-row-key='4'],
    tr[data-row-key='5'],
    tr[data-row-key='6'],
    tr[data-row-key='7'] {
      background-color: @background-color-5;
      padding: 0;
      & .ant-table-cell {
        padding: 6px 20px;
        height: 40px;
        text-align: left;
      }
      & .br-pricing-plan__city-table-header {
        & .br-pricing-plan-highlighter {
          display: flex;
          flex-direction: column;

          & span:first-child {
            & span {
              font-size: 15px;
              font-family: @lato-font-400;
              margin-bottom: 3px;
              color: @text-color;
            }
          }

          & span:not(:first-child) {
            font-size: 12px;
            font-family: @lato-font-400;
            color: @text-color-31;
            width: 260%;
          }
        }
      }
    }

    & tr {
      & p {
        margin-bottom: 0;
      }
      & .ant-table-cell {
        padding: 10px 8px;
        font-size: 14px;
        text-align: center;
      }
      & td.br-pricing-plan__city-table-header.ant-table-cell {
        padding: 10px 0 10px 20px;
      }
      & .br-pricing-plan-highlighter {
        text-align: left;
      }
    }
  }
}

.br-pricing-plan-sider .br-pricing-plan__14-package-size span:first-child {
  font-size: 10px;
}

.br-pricing-plan-sider {
  position: fixed;
  right: 30px;
  top: 227px;
  border: 1px solid @border-color-27;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  max-width: 206px;
  height: 100%;
  max-height: 317px;

  & .br-pricing-page__currency-container {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    & .br-pricing-page__currency {
      margin-bottom: 12px;
      font-family: @lato-font-400;
      font-size: 12px;
      font-style: italic;
      text-align: center;
      & span {
        color: @text-color-29;
        font-family: @lato-font-400;
      }
    }
  }

  & .ant-divider {
    margin: 12px 0;
  }

  & .br-pricing-plan__switch-vat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & span {
      font-size: 12px;
      font-family: @lato-font-400;
    }

    & .ant-switch-checked {
      background-color: @checked-switch-fill-color;
      & .ant-switch-handle::before {
        background-color: @background-color-16;
      }
    }
  }

  & .br-pricing-plan__14-percent {
    & p {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: @lato-font-400;
    }
    & span {
      font-size: 10px;
      color: @text-color-31;
    }
  }

  & .br-pricing-plan__14-package-sizes {
    & p {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: @lato-font-400;
    }

    & .br-pricing-plan__14-package-size {
      display: flex;
      justify-content: space-between;
      & span {
        font-size: 10px;
        margin-bottom: 7px;
      }
      & span:nth-child(2) {
        color: @text-color-32;
        text-align: end;

        & svg {
          margin-left: 6px;
        }
      }

      & .br-pricing-plan__white-bag-title {
        margin-bottom: 0;
      }

      & .br-pricing-plan__white-bag-section {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
}

html[dir='rtl'] .br-pricing-plan-sider {
  max-height: 450px;
  & .br-pricing-page__currency-container {
    & .br-pricing-page__currency {
      margin-top: 6px;
    }
  }
}

.ant-tooltip.br-pricing-plan__tool-tip {
  width: 100%;
  max-width: 320px;
  & .ant-tooltip-inner {
    padding: 5px;
  }
}

.br-pricing-plan__main-content .ant-table-filter-dropdown {
  width: 200px;
  position: relative;
  right: 100px;
}

.br-pricing-plan__main-content.br-pricing-plan__main-content-table
  .ant-table-filter-dropdown {
  width: 200px;
  position: relative;
  left: 10px;
}

html[dir='rtl'] .br-pricing-plan__main-content .ant-table-filter-dropdown {
  width: 200px;
  position: relative;
  right: 75px;
}

html[dir='rtl']
  .br-pricing-plan__main-content.br-pricing-plan__main-content-table
  .ant-table-filter-dropdown {
  width: 200px;
  position: relative;
  left: -80px;
}

.br-pricing-plan__main-content .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}

.br-pricing__drop-off-search {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & input {
    font-family: @lato-font-400;
    font-size: 12px;
    margin-bottom: 5px;
  }
  & button.ant-btn {
    padding: 6px 5px;
    font-size: 12px;
    height: fit-content;
    width: fit-content;
  }
}

.br-pricing-plan__main-content .ant-dropdown-trigger {
  display: flex;
  width: fit-content;
  align-items: center;
}

.br-pricing-plan__main-content
  .ant-table-thead
  th
  .ant-table-filter-trigger-container:hover {
  background: unset;
}

.br-pricing-plan__main-content
  .ant-table-thead
  th
  .ant-table-filter-trigger-container {
  background: unset;
}

svg.br-pricing__search-icon {
  margin-bottom: 2px;
  margin-left: 6px;
  display: flex;
}

.br-pricing-plan__table .ant-table-filter-trigger-container {
  display: flex;
  align-items: center;
}

.br-pricing-plan__main-content .ant-tag {
  font-family: @lato-font-700;
  font-size: 12px;
  background-color: @background-color-38;
  white-space: break-spaces;
  margin-left: 5px;
  & .tag-close-button {
    margin-left: 5px;
    color: @text-color-32;
    cursor: pointer;
  }
}

@media only screen and(min-width:751px) and (max-width: 1125px) {
  .br-pricing-plan-sider {
    position: unset;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: fit-content;

    & .ant-divider {
      height: 133px;
      padding: 0 7px;
      margin: 0;
    }

    & .br-pricing-plan-mobile__content {
      max-width: 33%;
      width: 100%;
      padding-right: 14px;
    }

    & .br-pricing-plan__switch-vat {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
      & span {
        font-size: 11px;
        font-family: @lato-font-700;
      }

      & .ant-switch-checked {
        background-color: @checked-switch-fill-color;
        & .ant-switch-handle::before {
          background-color: @background-color-16;
        }
      }
    }
    & .br-pricing-plan__1-collection {
      padding-right: 14px;
      max-width: 33%;
      width: 100%;
      & p {
        margin-bottom: 4px;
        font-size: 14px;
        font-family: @lato-font-700;
      }
      & span {
        font-size: 12px;
        color: @text-color-31;
      }
    }

    & .br-pricing-plan__14-percent {
      padding-right: 14px;
      max-width: 196px;
      width: 100%;
      & p {
        margin-bottom: 4px;
        font-size: 14px;
        font-family: @lato-font-700;
      }
      & span {
        font-size: 12px;
        color: @text-color-31;
      }
    }

    & .br-pricing-plan__14-package-sizes {
      max-width: 33%;
      width: 100%;
      padding-right: 14px;
      & p {
        margin-bottom: 4px;
        font-size: 14px;
        font-family: @lato-font-700;
      }

      & .br-pricing-plan__14-package-size {
        display: flex;
        justify-content: space-between;
        & span {
          font-size: 12px;
          margin-bottom: 7px;
        }
        & .br-pricing-plan__white-bag-section {
          margin-bottom: 0;
        }
      }
    }
  }

  .br-pricing-plan__main-content {
    width: 100%;
    max-width: 100%;

    & .br-pricing-plan__table {
      width: 100%;
      max-width: 100%;
    }
  }

  .br-pricing-page__currency-container {
    display: flex;
    justify-content: center;
    margin: 6px 0;

    & .br-pricing-page__currency {
      font-family: @lato-font-400;
      font-size: 12px;
      font-style: italic;
      text-align: center;
      & span {
        color: @text-color-29;
        font-family: @lato-font-700;
      }
    }
  }
}

.br-pricing-plan__sign_and_return_tooltip {
  margin-left: 5px;
  vertical-align: middle;
}

@media only screen and (max-width: 750px) {
  .br-pricing-plan-sider {
    position: unset;
    max-width: 100%;
  }
  .br-pricing-page__currency-container {
    display: flex;
    justify-content: center;
    margin: 6px 0;

    & .br-pricing-page__currency {
      font-family: @lato-font-400;
      font-size: 12px;
      font-style: italic;
      text-align: center;
      & span {
        color: @text-color-29;
        font-family: @lato-font-700;
      }
    }
  }
}
