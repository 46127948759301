@import 'styles/variables.less';

.br-tracking-number-scan {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.br-tracking-number-scan__seal-number {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-tracking-number-scan__header {
  display: flex;
  align-items: center;
  gap: 18px;

  .br-tracking-number-scan__arrow-icon svg {
    path {
      stroke: @deep-teal;
    }
  }

  .br-tracking-number-scan__close-icon {
    cursor: pointer;
    svg {
      width: 8px;
      height: 8px;
      path {
        fill: @gray-500;
      }
    }
  }
}

.br-tracking-number-scan__delete-icon {
  cursor: pointer;
  svg path {
    fill: @icon-red;
  }
}

.br-tracking-number-scan {
  .ant-row.ant-form-item {
    min-height: auto;
  }
}

.br-hub-receiving-bulky__table {
  margin-top: 24px;
}
