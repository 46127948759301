@import 'styles/variables';

.br-fulfillment-create-location-modal {
  .ant-modal {
    width: 536px;
  }
}

.br-zone-name {
  color: @text-gray-light;
}

.br-location-name {
  color: @text-teal;
}
