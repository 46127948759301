@import 'styles/variables.less';

.br-three-pl-integrations__actions {
  margin-top: 20px;

  & > .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}

.br-three-pl-integrations__errors-table-container {
  margin-top: 20px;
}

.br-three-pl-integrations__errors-table-title {
  color: @red-600;
  font-family: @poppins-font-600;
  margin: 10px 0;
}
