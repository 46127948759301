@import 'styles/variables';

.br-hub-manifests__no-star {
  color: @text-gray;
}

.br-hub-manifests__filters {
  display: flex;
  margin-bottom: 20px;

  .ant-picker {
    height: 36px;
  }
}

.br-hub-manifests__filters-action {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .ant-btn {
    min-width: 200px;
  }
}
