.br-filter--single-select--container {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;

  .ant-radio-group {
    .ant-radio-wrapper {
      display: block;

      span.ant-radio + span {
        white-space: normal;
      }
    }
  }
}
