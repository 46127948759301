.br-districts-mapping__filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-row.ant-form-item {
    min-height: auto;
  }
}

.br-districts-mapping__filters {
  > * {
    min-width: 220px;
  }
}

.br-districts-mapping__actions {
    display: flex;
    align-items: center;
    gap: 12px;
}