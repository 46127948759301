@import 'styles/variables';

.br-print-serials-modal {
  .ant-modal-body {
    max-height: 324px;
    overflow-y: auto;
  }
  .ant-spin-nested-loading:has(.br-print-serials-modal__loading-wrapper) {
    min-height: 70px;
  }

  .ant-modal-footer {
    padding: 16px;
  }
}

.br-print-serials-modal__collapse {
  .ant-collapse-header:has(.ant-checkbox-wrapper.ant-checkbox-wrapper-checked) {
    background: @bg-teal-light;
  }

  .ant-collapse-item-active .ant-collapse-header {
    background: @bg-hovered;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    background-color: @white;
  }

  .ant-checkbox-wrapper {
    gap: 12px;
  }
}

.br-print-serials-modal__collapse__product {
  span:last-child {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.br-print-serials-modal__collapse__serial {
  padding: 12px 16px;
  box-shadow: @border-bottom;

  &:has(.ant-checkbox-wrapper.ant-checkbox-wrapper-checked) {
    background: @bg-teal-light;
  }
}

.br-print-serials-modal__select-all.ant-checkbox-wrapper {
  padding: 8px 16px;
}
