@import 'styles/variables';

.br-add-edit-bonuses-and-decductions .br-content-header__title {
  font-size: 20px;
}

.br-add-edit-bonuses-and-decductions .ant-modal {
  width: 920px;
}

.br-add-edit-bonuses-and-decductions__content {
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
  }
}

.br-add-edit-bonuses-and-decductions .ant-row.ant-form-item:not(:last-child) {
  margin-bottom: 8px;
}

.br-add-edit-bonuses-and-decductions .br-upload-dialog__upload {
  display: inline-block;
  width: 100%;
}

.br-add-edit-bonuses-and-deductions__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
  & .ant-btn:first-child {
    background-color: @background-color-18;
    border-color: @background-color-18;
    margin-right: 12px;
  }
}

.br-add-edit-bonuses-and-decductions .ant-upload-list-picture .ant-upload-list-item-progress{
  bottom: 2px;
}