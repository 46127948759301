@import 'styles/variables';

.br-product-image-container {
  text-align: center;

  &,
  .br-product-empty-image {
    width: 40px;
  }
}

.br-product-image,
.br-product-empty-image {
  height: 40px;
}

.br-product-image {
  max-width: 40px;
}

.br-product-empty-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid @gray-200;
  border-radius: 4px;

  svg {
    path {
      fill: @icon-subdued;
    }
  }
}
