@import 'styles/variables';

.br-score-card-templates__empty-state-container {
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  margin-top: 21px;
  & svg {
    margin-bottom: 24px;
  }
}

.br-score-card-templates__container {
  margin-top: 21px;
}

.br-templates-empty-state__title {
  font-size: 14px;
  font-family: @poppins-font-600;
  margin-bottom: 4px;
}

.br-templates-empty-state__subtitle {
  font-size: 13px;
  color: #9ca3af;
}

.br-score-card-template__template-conent-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #e5e7eb;
  padding: 19px 26px;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 12px;
}

.br-score-card-template__info-section {
  display: flex;
  flex-direction: row;
}

.br-score-card-template__actions-section {
  display: flex;
}

.br-score-card-template__hubs-section {
  display: flex;
  flex-direction: column;
  margin-left: 42px;
  & div {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
  }
}

.br-score-card-template__info-title {
  font-size: 11px;
  font-family: @lato-font-700;
  color: #9ca3af;
}

.br-score-card-template__info-subtitle {
  font-size: 12px;
}

.br-score-card-template__add-factor-button {
  margin-right: 12px;
}

.br-templates__empty-factor-text {
  font-size: 12px;
  color: #cc0000;
}

.factor-weight {
  margin-right: 16px;
  font-size: 12px;
  color: #4b5563;
  margin-left: 8px;
}

.br-templates__hub-tag {
  font-size: 12px;
  border-radius: 4px;
  background-color: #e5e7eb;
  padding: 4px;
  padding-bottom: 6px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 12px;
  margin-bottom: 12px;
  & .br-templates__hub-remove-tag {
    color: #9ca3af;
    margin-left: 8px;
    cursor: pointer;
  }
}

.br-hubs-factors-modal__selected_hubs_container {
  display: flex;
  margin-top: 21px;
  flex-wrap: wrap;
}
