@import 'styles/variables';

.br-ops-control-wrong-zone {
  > .br-pending-transit-filter__container {
    margin-inline-start: auto;
    margin-bottom: @spacing-xl;
  }

  > .br-table-component {
    background-color: @white;
    box-shadow: @border-all-sides;
    border-radius: 12px;

    &.hide-pagination
      ul.ant-pagination
      > li:not(
        :is(.ant-pagination-prev, .ant-pagination-next, .ant-pagination-options)
      ) {
      display: none;
    }

    > .br-table__quick-filter-tabs {
      margin: 0;
      padding-bottom: @spacing-lg;

      > .br-table__header > .br-table__title.heading {
        display: flex;
        align-items: center;
        gap: @spacing-lg;

        > span {
          .heading();
          color: @gray-900;
        }
      }
    }

    .ant-table-content > table {
      > thead.ant-table-thead > tr > th.ant-table-cell {
        > div.br-ops-control-wrong-zone__aging > svg {
          width: 16px;
          height: 16px;
          margin-inline-start: @spacing-xs;
        }
      }

      > tbody.ant-table-tbody > tr.ant-table-row > td.ant-table-cell {
        .body();
        height: 68px;

        > svg.br-ops-control-wrong-zone__changed_hub_flag {
          margin-inline-start: @spacing-xs;

          > * {
            fill: @yellow-600;
          }
        }

        > h3 {
          .caption-lg();
          color: @gray-900;
          margin-bottom: @spacing-sm;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          & + p {
            .caption();
            color: @gray-500;
            text-transform: capitalize;
            margin-bottom: 0;
          }
        }

        > .ant-tag.br-tag-red {
          color: @red-600;
        }
      }
    }
  }
}

.br-table__header__actions .br-wrong-zone__bulk-actions {
  min-width: 153px;
  height: 36px;
  border: 1px solid @teal-500;
  color: @teal-500;
  padding: @spacing-sm @spacing-lg;
  border-radius: @spacing-xs;
}
