@import 'styles/variables';

.br-uncovered-area {
  cursor: not-allowed;
}

.br-area__no-character-search,
.br-area__no-results-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 48px;
}

.br-area__no-character-search img,
.br-area__no-results-search img {
  width: 42px;
  height: 42px;
  margin-bottom: 15px;
}

.br-area__title {
  color: @text-color-31;
  font-size: 12px;
  font-weight: bold;
}

.br-area__sub-title {
  text-align: center;
  font-size: 10px;
  color: @text-color-32;
}

.br-area__highlighted-text {
  background-color: @background-color-20;
  padding: 0;
}

.br-area__uncovered-tag {
  height: fit-content;
}
