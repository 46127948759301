@import 'styles/variables';

.ant-modal-root .ant-modal-wrap.br-confirmation-modal .ant-modal {
  width: 536px;
}

.br-confirmation-modal .ant-modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  .font({.display-xs()});
}

.br-confirmation-modal .ant-modal-body {
  color: @text-gray;
  & .flyers-order-cancel-reason-form.ant-form {
    margin-top: 32px;
  }
}

.br-confirmation-modal .ant-modal-footer {
  .ant-btn,
  button.ant-btn.ant-btn-primary,
  button.ant-btn.ant-btn-danger {
    height: 36px;
  }

  .ant-btn:last-child {
    margin-left: 12px;
  }
}
