@import 'styles/variables.less';

.br-live-ops__container {
  margin-top: 9px;
  background-color: @background-color;
  border: 1px solid @border-color-23;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px @box-shadow;
}

.br-live-ops__container__row:first-child {
  margin-top: unset;
}

.br-live-ops__container__row {
  display: flex;
  margin-top: 12px;
}

.br-live-ops__container__row div {
  font-family: @lato-font-700;
  font-size: 13px;
  color: @text-color;
  width: 40%;
}

.br-live-ops__container__row__title {
  font-family: @lato-font-700;
  font-size: 13px;
  color: @text-color;
}

.br-live-ops__container__row .br-live-ops__container__row__details {
  min-width: 330px;
}

.br-live-ops__container__row__details .br-live-ops__container__row__checkboxs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.br-live-ops__container__row__checkboxs .br-live-ops__container__cross-icon {
  width: fit-content;
  margin-right: 10px;
}

.br-live-ops__container__row div div,
.br-live-ops__container__row__sub-title,
.br-live-ops__container__row__checkboxs .ant-checkbox-disabled + span {
  font-family: @lato-font-400;
  font-size: 13px;
  color: @text-color-31;
  font-weight: normal;
}

.br-live-ops__container__row__checkboxs {
  margin-top: 6px;
  display: flex;
}

.br-live-ops__container__row__checkboxs
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: @background-color;
}

.br-live-ops__container__cross-icon > span:first-child {
  background: @background-color-15;
  border: 1px solid @border-color-3;
  border-radius: 7px;
  margin-right: 8px;
}

.br-live-ops__container__cross-icon .anticon {
  width: 17px;
}

.br-live-ops__container__cross-icon svg {
  width: 7px;
  padding-bottom: 6px;
  color: @text-color-15;
}
