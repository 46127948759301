@import 'styles/variables';

.br-sessions-list__title {
  color: @gray-500;
  margin: 12px 0;
}

.br-other-sessions__container {
  .br-sessions-list__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
