.br-new-settings-parent {
  padding: 0;
}

.br-settings .br-banner-content__parent {
  margin-bottom: 20px;
}

.br-settings .br-business-info-side__content .br-banner-content__parent {
  margin-right: 33px;
  margin-left: 33px;
  width: unset;
}

.br-settings .br-api-side__content .br-banner-content__parent {
  margin-top: 20px;
  margin-right: 33px;
  margin-left: 33px;
  margin-bottom: unset;
  width: unset;
}
