@import 'styles/variables.less';

.br-business__bosta-credit-limit-form {
  > form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  button {
    margin-bottom: @spacing-sm;
  }
}
