@import 'styles/variables';

.br-date-picker {
    font-family: @poppins-font-500;
    display: flex;
    justify-content: flex-end;
    position: relative;

    & .ant-btn.ant-dropdown-trigger,
    .ant-picker-range {
        box-shadow: 0px 2px 2px @box-shadow;
        font-family: @poppins-font-500;
    }

    & .ant-btn:not(:last-child) {
        margin-right: 8px;
        margin-left: 8px;
    }

    .ant-picker-input input {
        cursor: pointer;
    }

    cursor: pointer;
}

.br-date-picker__date-filter .ant-picker-ranges .ant-picker-preset .ant-tag-blue {
    background: @teal-100;
    border: 1px solid @teal-200;
    border-radius: 28px;
    color: @text-color;
    font-size: 14px;
    font-family: @poppins-font-400;
}

.br-date-picker__date-header {
    display: flex;
    justify-content: center;
    height: 44px;
    padding: 8px;
    border-radius: 4px;
    color: @text-gray;
    background-color: @background-color-17;
    border: 1px solid @background-color-17;
    border-bottom: 0;
}

.br-date-picker__date-filter .ant-picker-footer .br-date-picker__active-tag.ant-tag.ant-tag-blue {
    background: @background-color-16;
    color: @text-color-15;
}

.br-date-picker__separator {
    margin: 0px 8px;
    width: 1px;
    height: 20px;
    color: @text-color-30;
}

.br-date-picker__suffix {
    color: @background-color-34;
}

.br-date-picker__range-picker {
    height: 36px;
}

.ant-picker.ant-picker-range.br-date-picker__range-picker :nth-child(5) {
    order: -1;
}

.br-date-picker__range-picker .ant-picker-input input {
    color: @text-color-33;
    width: 6.5em;
}

html[dir='rtl'] .br-date-picker__range-picker .ant-picker-input input {
    width: 8em;
}

.br-date-picker__range-picker .ant-picker-active-bar {
    display: none;
}

.br-date-picker__range-picker .ant-picker-range-separator {
    padding: 4px;
}

.br-date-picker__range-picker-preset .ant-picker-input {
    display: none;
}

.br-date-picker__range-picker-preset .ant-picker-range-separator {
    display: none;
}

.br-date-picker__range-picker-preset__suffix-container {
    display: flex;
    align-items: center;
}

.br-date-picker__date-filter__text {
    font-size: 14px;
    color: @text-color-33;
    margin-left: 7px;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix .br-date-picker__range-picker-preset__suffix-container {
    svg {
        opacity: 0.25;
    }

    span {
        // color: @ant-icon-down-color;
    }
}

.br-date-picker .ant-btn.ant-dropdown-trigger:disabled:hover {
    border-color: @border-color-17;
    box-shadow: 0px 2px 2px @box-shadow;
}

.br-date-picker .ant-btn.ant-dropdown-trigger:disabled,
.br-date-picker .ant-picker.ant-picker-disabled {
    background-color: @background-color;
}

.br-date-picker__date-filter.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    display: none;
}

.br-date-picker__date-filter .ant-picker-body {
    .ant-picker-cell .ant-picker-cell-inner {
        overflow-x: hidden;
    }

    .ant-picker-cell-in-view {
        &.ant-picker-cell {

            &-in-range::before,
            &-range-start:not(.ant-picker-cell-range-start-single)::before,
            &-range-end:not(.ant-picker-cell-range-end-single)::before {
                background: @teal-100;
            }
        }

        &.ant-picker-cell-in-range.ant-picker-cell-range {

            &-hover::before,
            &-hover-start .ant-picker-cell-inner::after,
            &-hover-end .ant-picker-cell-inner::after {
                background: @teal-300;
            }
        }

        &.ant-picker-cell-range {

            &-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
            &-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
                background: @teal-200;
            }
        }
    }
}

.br-date-picker {
    & .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
        border-radius: 12px 0 0 12px;
    }

    & .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
        border-radius: 0 12px 12px 0;
    }
}
