@import 'styles/variables';

.br-bosta-coding-info-box__container {
  background-color: @white;
  width: fit-content;
  padding: 6px;
}

.br-zone-geofencing__container {
  .gm-ui-hover-effect {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
