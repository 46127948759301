@import 'styles/variables';

.br-pickup-details__signature-image {
  width: 76px;
  height: 76px;
  object-fit: cover;

  border: 1px solid @gray-300;
  border-radius: 8px;

  cursor: pointer;
}