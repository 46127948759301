@import 'styles/variables';

.br-hubs-receive-from-business__pickup-scanning__scanned-packages.ant-card {
  > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    > .scanned-packages__header-icon {
      width: 24px;
      height: 24px;

      background-color: @white;
      border-radius: 9999px;
      border: 1.5px solid @green-600;

      > svg > * {
        fill: @green-600;
      }
    }
  }
}