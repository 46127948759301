@import 'styles/variables';

.br-cards-filter__container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.br-cards-filter__card {
  background-color: @white;
  border: 1px solid @gray-200;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  width: 230px;
}

.br-cards-filter-card__content {
  flex: 1;
}

.br-cards-filter-card__label {
  color: @text-gray;
  margin-bottom: 4px;
}

.br-cards-filter-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.br-cards-filter__card-yellow {
  &.br-cards-filter__card-active {
    border: 1px solid @yellow-600;
  }

  .br-cards-filter-card__icon {
    background-color: @yellow-50;

    &:not(.no-fill) svg path {
      fill: @yellow-600;
    }

    &.no-fill svg path {
      stroke: @yellow-600;
    }
  }
}

.br-cards-filter__card-purple {
  &.br-cards-filter__card-active {
    border: 1px solid @purple-600;
  }

  .br-cards-filter-card__icon {
    background-color: @purple-50;

    &:not(.no-fill) svg path {
      fill: @purple-600;
    }

    &.no-fill svg path {
      stroke: @purple-600;
    }
  }
}

.br-cards-filter__card-blue {
  &.br-cards-filter__card-active {
    border: 1px solid @blue-600;
  }

  .br-cards-filter-card__icon {
    background-color: @blue-50;

    &:not(.no-fill) svg path {
      fill: @blue-600;
    }

    &.no-fill svg path {
      stroke: @blue-600;
    }
  }
}

.br-cards-filter__card-green {
  &.br-cards-filter__card-active {
    border: 1px solid @green-600;
  }

  .br-cards-filter-card__icon {
    background-color: @green-50;

    &:not(.no-fill) svg path {
      fill: @green-600;
    }

    &.no-fill svg path {
      stroke: @green-600;
    }
  }
}

.br-cards-filter__card-gray {
  &.br-cards-filter__card-active {
    border: 1px solid @gray-600;
  }

  .br-cards-filter-card__icon {
    background-color: @gray-200;

    &:not(.no-fill) svg path {
      fill: @gray-500;
    }

    &.no-fill svg path {
      stroke: @gray-500;
    }
  }
}

.br-cards-filter__card-teal {
  &.br-cards-filter__card-active {
    border: 1px solid @teal-600;
  }

  .br-cards-filter-card__icon {
    background-color: @teal-50;

    &:not(.no-fill) svg path {
      fill: @teal-600;
    }

    &.no-fill svg path {
      stroke: @teal-600;
    }
  }
}
