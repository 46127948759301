@import 'styles/variables';

.br-vendors-search__actions {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 16px 0;

  & > .ant-btn {
    min-width: 200px;
  }
}
