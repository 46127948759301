@import 'styles/variables.less';

.br-content-header {
  display: flex;
  justify-content: space-between;
}

.br-content-header__titles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.br-sidebar-content__sub-title {
  font-size: 14px;
  line-height: 20px;
  font-family: @lato-font-400;
  margin-top: 6px;
}

.br-content-header__sub-title {
  font-size: 16px;
}

.br-sidebar-content__title {
  font-size: 16px;
  font-family: @lato-font-700;
}

.br-content-header__with-title-only {
  margin-bottom: 15px;
}

.br-sidebar-content-header-with-sub-title {
  margin-bottom: 24px;
}

.br-content-header-with-sub-title {
  margin-bottom: 30px;
}

.br-content-header-with-sub-title,
.br-sidebar-content-header-with-sub-title,
.br-sidebar-content__sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-content-header__title {
  color: @text-color;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
}

.br-sidebar-content__title,
.br-content-header__title {
  font-family: @lato-font-700;
}

.br-content-header__with-title-only {
  font-family: @lato-font-400;
  font-weight: 600;
}

html[dir='rtl'] {
  .br-content-header__title,
  .br-content-header__title,
  .br-content-header__with-title-only {
    font-family: 'Tajawal, Bold';
  }
}
