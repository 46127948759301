@import 'styles/variables';

.br-support-tickets__create-ticket {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
}

.br-support-tickets__create-ticket > * {
  flex: 1;
}

.br-support-tickets__create-ticket__Modal .ant-modal {
  width: 916px;
}

.br-support-tickets__create-ticket__Modal {
  .ant-modal-body {
    height: fit-content;
    padding: 0;
  }
  .ant-modal-body .ant-modal-content {
    width: 846px;
    height: 372px;
  }
}

.br-support-tickets__create-ticket__Modal {
  .br-sidebar-content-header-with-sub-title {
    margin-bottom: 0px;
    padding: 14px 20px;
    box-shadow: @border-bottom;
  }
  .br-sidebar-content__title {
    color: @gray-900;
  }
  .br-sidebar-content__sub-title {
    color: @gray-500;
  }
}

.br-support-tickets__create-ticket__title {
  color: @text-color;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 22px;
}

.br-support-tickets__create-ticket__subtitle {
  color: @text-color-4;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 40px;
}

.br-support-tickets__create-ticket-upload-dialog {
  margin-left: 20px;
  .ant-upload-list-picture-card,
  .ant-upload-picture-card-wrapper,
  .ant-upload-select-picture-card {
    border-radius: 8px;
  }
}

.br-support-tickets__create-ticket__Modal
  .br-support-tickets__create-ticket__form__submit-button {
  height: 55px;
  width: 187px;
  float: right;
}

.br-support-tickets__create-ticket__Modal
  .br-support-tickets__create-ticket__form__submit-button.ant-btn
  span {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

.br-support-tickets__create-ticket__form-word-count {
  font-size: 12px;
  clear: both;
  margin-top: 3px;
  display: block;
}

.br-support-tickets__create-ticket__form .br-form-optional-label svg {
  margin-left: 4px;
  width: 16px;
}

.br-support-tickets__create-ticket__form_description-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: 18px;
}

.br-support-tickets__create-ticket__form_label {
  .font({.body-medium()});
}

.br-support-tickets__create-ticket__form {
  .ant-row.ant-form-item:not(:last-child),
  .br-form-row {
    margin-bottom: 7px;
  }
}

.br-city-area__selected-indicator {
  width: 4px;
  height: 36px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
  display: none;
}

.br-support-tickets__create-ticket__form__category__selected-indicator {
  width: 4px;
  height: 36px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
  display: none;
}

.br-support-tickets__create-ticket__form .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.br-support-tickets__create-ticket__form
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: @white;
  padding: 0;
  .br-support-tickets__create-ticket__form__category__selected-indicator {
    background: @teal-500;
    display: block;
  }
  .ant-select-item-option-content span:nth-child(2) {
    padding: 8px 0px 8px 5px;
    background-color: @bg-selected;
    border-radius: 4px;
    width: 100%;
  }
}
@media (min-width: 600px) {
  .br-support-tickets__create-ticket__Modal.ant-modal-wrap .ant-modal {
    max-width: calc(100vw - 16px);
  }
}

@media only screen and (max-width: @sm) {
  .br-support-tickets__create-ticket {
    flex-direction: column;
  }
  .br-support-tickets__create-ticket-upload-dialog {
    margin: 20px 0px 0px;
    div.ant-upload.ant-upload-select {
      padding: 16px 24px;
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-support-tickets__create-ticket__Modal {
    display: flex;
    align-items: flex-end;
    .ant-modal {
      display: flex;
      align-items: flex-end;
    }
    .ant-modal-content {
      width: 100%;
    }
    .ant-form {
      height: 68vh;
      overflow: auto;
    }
    .ant-modal-footer {
      padding: 16px 16px 20px 16px;
    }
    .br-content-header {
      height: 80px;
    }
  }
}
