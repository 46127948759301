@import 'styles/variables';

.br-po-select-products-modal {
  .ant-spin-nested-loading:has(.br-po-select-products-modal__loading-wrapper) {
    min-height: 100px;
  }

  .ant-input-number {
    width: 104px;

    .ant-input-number-handler-wrap {
      opacity: 1;
    }
  }

  .ant-btn.br-button-component {
    width: max-content;
  }

  .br-po-products__empty-state {
    margin: 136px 0;
  }

  .ant-modal {
    width: 624px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      align-items: center;
      justify-content: space-between;

      .br-po-select-products-modal__footer__counts {
        color: @text-gray;
        display: flex;
        gap: 8px;

        span:not(:first-child) {
          border-left: 1px solid @gray-200;
          padding-left: 8px;
        }
      }

      .br-po-select-products-modal__footer__actions {
        display: flex;
        gap: 4px;
      }
    }
  }

  .br-table-component {
    &,
    .br-table {
      box-shadow: none;
    }

    table {
      max-width: 100%;
    }

    &.br-table-component__empty-state {
      height: auto;
    }

    .br-table-component__empty-state {
      height: auto;
      margin: 50px;
      box-shadow: none;
      padding: 0;

      .br-empty-state__actions {
        margin-bottom: 0;
      }
    }
  }
}

.br-po-select-products-modal__selected-products__product {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: @bg-selected;
  box-shadow: @border-bottom;

  .ant-checkbox-wrapper {
    margin-right: 24px;
  }

  &:first-of-type {
    box-shadow: @border-top-bottom;
  }
}

.br-po-select-products-modal__product__name {
  margin-left: 10px;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.br-po-select-products-modal__product__sku {
  margin-right: 40px;
  margin-left: auto;
  min-width: 104px;
}

.br-po-select-products-modal__product__sku,
.br-sku-column {
  color: @text-gray;
}

.br-po-select-products-modal__label {
  .font({.subheading()});
  display: inline-block;
  color: @gray-400;
  margin: 16px 0 0 16px;
  text-transform: uppercase;

  &:first-child {
    margin-bottom: 16px;
  }
}

.br-po-select-products-modal__tables {
  max-height: 402px;
  overflow-y: auto;

  tr.ant-table-row {
    td {
      &:first-child {
        padding-left: 16px;
        padding-right: 12px;
      }
      &:nth-child(2) {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    &.ant-table-row-selected > td {
      background: @bg-selected;
    }
  }

  .br-table__quick-filter-tabs {
    padding: 16px;
    box-shadow: @border-bottom;
    margin: 0;

    .br-table__title {
      display: none;
    }

    .br-table__header__actions {
      flex: 100%;
      margin: 0;
    }
  }

  .br-po-products-table__add-product-btn {
    margin-top: 10px;
  }
}
