@import 'src/styles/variables.less';

.br-bosta-coding-selectors__container {
  .br-bosta-coding-selectors-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.br-bosta-coding__actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  .ant-btn {
    min-width: 240px;
  }
}

.br-bosta-coding__errors {
  margin-bottom: 16px;

  > span {
    display: block;
    color: @red-400;
  }
}
