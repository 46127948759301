@import 'styles/variables';

.br-order-details__empty-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.br-order-details__empty-content-title {
  font-family: @poppins-font-600;
  font-size: 14px;
  margin-top: 22px;
}

.br-order-details__empty-content-subtitle {
  font-family: @lato-font-400;
  font-size: 14px;
}
