@import 'styles/variables';

.br-good-value__form-group {
  margin: 24px 0;
  padding: 20px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  background-color: @white;

  .br-upload-single-image__empty-view
    .br-upload-single-image__empty-view__image {
    margin: 15px;
  }

  .br-item-value-input label:nth-of-type(1) {
    width: 100%;
  }

  .br-item-value__value-label,
  .ant-form-item-label,
  label {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .br-form__proof-preview-container {
    position: relative;
    height: 150px;
    width: 100%;

    > div {
      display: none;
    }

    > img {
      border: 1px solid @gray-200;
      border-radius: 8px;
      position: absolute;
      height: 150px;
      width: 250px;
      object-fit: contain;
    }

    svg path {
      fill: white;
    }

    svg:hover {
      cursor: pointer;
    }
  }

  .br-form__proof-preview-container:hover {
    > div {
      position: absolute;
      display: flex;
      width: 250px;
      height: 150px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      svg {
        box-shadow: @sm;
      }
    }

    > img {
      opacity: 0.5;
    }
  }
}

.br-item-value__modal .ant-modal-title {
  width: 100%;
}

.br-item-value__modal__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > p {
    margin-bottom: 0;
  }

  > svg:hover {
    cursor: pointer;
  }
}

.br-item-values__tooltips-parent {
  .ant-tooltip-inner {
    border-radius: 8px;
  }
}

.br-item-value__modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  div {
    gap: 4px;
  }

  p {
    margin-bottom: 0;
  }
}

.br-deliveries__item-value-container {
  img {
    border: 1px solid @gray-200;
    border-radius: 8px;
    height: 150px;
    width: 250px;
    object-fit: contain;
  }
}
