@import 'styles/variables';

.br-order-details__container {
  border: 1px solid @border-color-23;
  border-radius: 8px;
  width: 100%;
  overflow-y: auto;
}

.br-order-details__content {
  height: 100%;
  padding: 19px 16px;
}

.br-order-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-order-details__title {
  font-size: 16px;
  font-family: @poppins-font-600;
}

.br-order-details__action-title {
  font-size: 11px;
  color: @text-color-54;
}

.br-order-details-shipment-info {
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid @border-color-3;
}

.br-order-details-shipment-info-tracking-number {
  font-size: 14px;
  margin-bottom: 10px;
  font-family: @lato-font-900;
}

.br-order-details-shipment-info-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  & .br-order-details-shipment-info-title {
    min-width: 30%;
  }
}

.br-order-details-shipment-info-title {
  font-size: 12px;
  font-family: @lato-font-700;
  color: @text-color-45;

  & .info-value {
    font-size: 14px;
    font-family: @lato-font-400;
    color: @text-color;
    margin-left: 8px;

    &.block {
      display: block;
      margin-left: 0;
    }
  }
}

.br-order-details-customer-details {
  margin-top: 20px;

  & .br-order-details-shipment-info-title {
    margin-bottom: 20px;

    &:last-child {
      padding-bottom: 20px;
      margin: 0;
    }
  }
}

.br-order-details-business-link {
  text-decoration: underline;
}

.br-order-details__container {
  & .br-order-info-delivery-details__state {
    font-family: @lato-font-700;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 10px;
  }
  & .br-order-info-delivery-details__state.br-state-blue-group {
    background-color: @background-color-21;
    border-color: @background-color-21;
    color: @text-color-37;
  }
  & .br-order-info-delivery-details__state.br-state-green-group {
    background-color: @background-color-22;
    border-color: @background-color-22;
    color: @text-color-29;
  }
  & .br-order-info-delivery-details__state.br-state-purple-group {
    background-color: @background-color-23;
    border-color: @background-color-23;
    color: @text-color-31;
  }
  & .br-order-info-delivery-details__state.br-state-red-group {
    background-color: @background-color-24;
    border-color: @background-color-24;
    color: @text-color-27;
  }
  & .br-order-info-delivery-details__state.br-state-yellow-group {
    background-color: @background-color-25;
    border-color: @background-color-25;
    color: @text-color-36;
  }
}

.br-order-requests__container {
  border-radius: 8px;
  border: 1px solid @blue-100;
}

.br-order-requests__type {
  padding: 14px 0 14px 10px;
  background-color: @blue-100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.br-order-requets__details {
  padding: 14px;
  background-color: @blue-50;
  color: @text-gray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > span {
    display: block;
    color: @text-gray-dark;
  }
}

.br-order-ticket__container {
  border-radius: 8px;
  border: 1px solid @gray-100;
  margin-top: 16px;
}

.br-order-ticket {
  padding: 14px 0 14px 10px;
  background-color: @gray-100;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-order-star {
  padding: 14px;
  background-color: @gray-50;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: @text-gray-dark;

  & > span {
    display: block;
    color: @text-gray;
  }
}

.br-action-center__ticket-link {
  color: @text-gray-dark;
  text-decoration: underline;

  &:hover {
    color: @text-gray-dark;
  }
}

.br-action-center-view-logs__btn {
  width: 100%;
}

.br-delivery-details__COD-paid-online {
  display: flex;
  gap: 8px;
  > p {
    color: black;
  }
}
