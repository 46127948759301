@import 'styles/variables';

.br-repeated-modal__title {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.ant-modal-root .ant-modal-wrap .br-repeated-modal.ant-modal {
  width: 620px;
  border-radius: 8px;
}

.br-repeated-modal__sub-title {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 22px;
}

.br-repeated-modal__form button.ant-btn.ant-dropdown-trigger {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.br-repeated-modal__form__footer {
  display: flex;
}

.br-repeated-modal__form__footer .ant-btn-primary {
  margin-left: 10px;
}

.br-repeated-modal__form__footer button:nth-child(2) {
  margin-left: auto;
}
