@import 'styles/variables';

.br-pickup-details-page {
  .br-pickup-summary-container {
    margin-bottom: @spacing-xl;
  }

  .br-pickup-details-container {
    display: flex;
    flex-direction: column;
    gap: @spacing-xl;

    @media only screen and (min-width: @lg) {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 316px;
      grid-template-rows: repeat(4, auto);

      > :not(.br-pickup-details-aside) {
        grid-column-start: 1;
      }

      > .br-pickup-details-aside {
        grid-column-start: 2;
        grid-row: 1/-1;

        position: sticky;
        top: 0;

        max-height: 80dvh;
        height: fit-content;
      }
    }
  }
}

.br-pickup-details__header__edit-button.ant-btn {
  padding-inline: @spacing-xl;
  margin-inline-start: auto;
  margin-bottom: @spacing-xl;
}

.br-pickup-details__outbound {
  justify-content: flex-end;

  button {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }
}
