@import 'styles/variables.less';

.br-table-mobile-card {
  display: flex;
  box-shadow: 0 0 1px 1px #e4e7ec, 0 4px 4px rgb(29 41 57 / 5%);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 8px;
  font-size: 13px;
  height: 300px;
  overflow-y: auto;
  & > div {
    width: 100%;
    margin-left: 8px;
  }
}

.br-table-mobile-card::-webkit-scrollbar-thumb {
  background-color: @gray-200;
  border-radius: 8px;
}

.br-table-mobile-card::-webkit-scrollbar {
  width: 16px;
  background: @gray-50;
  border-left: 1px solid @gray-200;
}

.br-table-mobile-card::-webkit-scrollbar-track,
.br-table-mobile-card::-webkit-scrollbar-thumb {
  border: 4px solid rgba(black, 0);
  background-clip: padding-box;
}
