.br-bosta-coding-selectors__container {
  .br-bosta-coding-selectors-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.br-bosta-coding__actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .ant-btn {
    min-width: 240px;
  }
}

.br-bosta-coding__switch {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-row.ant-form-item:not(:last-child) {
    margin-right: 0;
    max-width: fit-content;
  }

  .ant-form-item-control-input-content {
    min-height: auto;
  }
}

.br-bosta-coding__extra-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 12px 0;

  .ant-row.ant-form-item,
  .ant-form-item-control-input-content {
    min-height: auto;
  }
}
