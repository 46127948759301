@import 'styles/variables';

.br-mass-upload__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.br-mass-upload__success svg {
  color: @background-color-16;
  width: 60px;
  height: 60px;
}

.br-mass-upload__success-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    font-size: 17px;
    margin-bottom: 5px;
    font-family: @poppins-font-600;
  }

  & p {
    font-size: 13px;
    font-family: @poppins-font-600;
    color: @text-color-31;
  }
  margin-top: 16px;
  margin-bottom: 18px;
}

.br-mass-upload__success-actions {
  display: flex;
  flex-direction: column;
  & button {
    width: 266px;
  }

  & .br-mass-upload__success-secondary-actions {
    margin-top: 6px;
    display: flex;
    & button {
      width: unset;
      padding: 10px;
      margin-right: 10px;
    }
  }
}
