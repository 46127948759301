@import 'styles/variables';

.br-line-serials-type-dropdown {
  width: 100px;
}

.br-create-rtv__confirmation-modal {
  .ant-modal-title {
    .font(.display-xs());
  }

  .br-delete-confirmation-modal__message {
    padding-bottom: 0;

    &,
    pre {
      .font(.heading());
    }

    pre {
      margin-bottom: 0;
      color: @text-gray-dark;
    }
  }
}
