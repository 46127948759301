@import 'styles/variables';

.br-reschedule-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  & span {
    font-size: 18px;
    font-family: @poppins-font-600;
  }
  & svg {
    cursor: pointer;
  }
}

.br-reschedule-modal__schedule-date-content,
.br-reschedule-modal__time__slot {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  justify-content: center;
  & span {
    font-size: 14px;
    margin-bottom: 8px;
    font-family: @lato-font-400;
  }
}

.br-reschedule-modal__container {
  & .ant-modal-footer {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    & .ant-btn {
      padding: 8px 16px;
      font-size: 14px;
      & span {
        font-family: @lato-font-700;
      }
    }
    & .ant-btn.br-reschdule-modal__primary-button {
      background-color: @background-color-51;
      color: white;
      & span {
        font-family: @lato-font-700;
      }
    }
  }
}
