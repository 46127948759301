@import 'styles/variables.less';

.br-shipment-details__summary__package-type {
  display: flex;
  flex-direction: column;
}

.br-shipment-details__summary__package-type__subtitle {
  font-size: 13px;
  color: @text-color-32;
}

.br-shipment-details__summary {
  padding: 35px;
  padding-right: 20px;
  width: 100%;
  max-width: 1056px;
}

.br-shipment-details__summary__shipment-table-title,
.br-shipment-details__summary__customer-tabel-title,
.br-shipment-details__summary__performance-table-title,
.br-shipment-details__summary__pickup-table-title,
.br-shipment-details__summary__parcels-tabel-title {
  color: @text-color-5;
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 30px;
  font-family: @lato-font-400;
}

html[dir='rtl'] .br-shipment-details__summary__shipment-table-title,
html[dir='rtl'] .br-shipment-details__summary__customer-tabel-title,
html[dir='rtl'] .br-shipment-details__summary__performance-table-title,
html[dir='rtl'] .br-shipment-details__summary__pickup-table-title,
html[dir='rtl'] .br-shipment-details__summary__parcels-tabel-title {
  font-size: 20px;
}

.br-shipment-details__summary__table-label {
  color: @text-color-30;
  font-size: 17px;
  font-family: @lato-font-400;
  letter-spacing: 0px;
  margin-left: 10px;
}

.br-shipment-details__summary__customer-tabel-title,
.br-shipment-details__summary__performance-table-title,
.br-shipment-details__summary__pickup-table-title,
.br-shipment-details__summary__parcels-tabel-title {
  padding: 32px 0 24px 0;
}

.br-shipment-details__summary__promise-met {
  color: @text-color-29;
}

.br-shipment-details__summary__promise-not-met {
  color: @text-color-21;
}

.br-shipment-details__summary .anticon-info-circle {
  color: @text-color-4;
  font-size: 14px;
  margin-left: 6px;
  vertical-align: middle;
}

.br-shipment-details__summary__crp-image {
  height: 120px;
  margin-bottom: 14px;
}

.br-shipment-details
  .br-shipment-details__summary__crp-image-sub-title.ant-typography {
  font-size: 11px;
}

.br-shipment-details
  .br-shipment-details__summary__crp-image-enlarge.ant-typography {
  text-decoration: underline;
  color: @text-color-22;
  cursor: pointer;
}

.br-shipment-details__summary__wallet-title {
  font-family: @lato-font-700;
  font-size: 18px;
  line-height: 22px;
  margin: 39px 0;
}

.br-shipment-details__summary__wallet-empty-state {
  height: 288px;
  margin-top: 8px;
  border: 1px solid @border-color-13;
  border-radius: 9px;
}

.br-shipment-details__summary__wallet-footer {
  display: block;
  margin-top: 15px;
  font-family: @lato-font-400;
  font-size: 17px;
  line-height: 20px;
}

.br-shipment-details__summary__wallet-footer .ant-btn.ant-btn-link {
  display: inline-flex;
  padding: 0;
  font-size: inherit;
}

.br-shipment-details__summary__wallet__pricing-plan.ant-btn.ant-btn-link {
  display: inline-flex;
  padding: 0;
  color: inherit;
}

.br-shipment-details__summary__wallet__pricing-plan.ant-btn.ant-btn-link span,
.br-shipment-details__summary__wallet-footer .ant-btn.ant-btn-link span {
  font-family: inherit;
}

.br-shipment-details__summary__package-type {
  display: flex;
  flex-direction: column;
}

.br-shipment-details__summary__package-type__subtitle {
  font-size: 13px;
  color: @text-color-32;
}

.br-cycles-payment-status {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 2px 8px 2px 6px;
  border-radius: 16px;
  background-color: #e4e7ec;
  color: #667085;
  & svg {
    margin-right: 4px;
  }
}

.br-cycles-payment-status.success {
  background-color: @background-color-76;
  color: #14494e;
}

.br-information-table.br-information-table-wallet {
  margin-bottom: 24px;
}
