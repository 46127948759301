.br-stars__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.br-stars__table-header {
  margin: 16px 0;
}

.br-stars__star-name {
  text-decoration: underline;
}
