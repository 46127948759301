@import 'styles/variables';

.br-damage-serials-modal {
  .br-damage-serial-tag.ant-tag {
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    color: @yellow-900;
  }
}
