.br-ofd-search-filters__row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-ofd-search-filters {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 22px;
}
