@import 'styles/variables';

.br-last-level-content {
  min-height: 36px;
  border: 1px solid @gray-200;
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  .font({.body-medium()});
}

.br-last-level-content__tag {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 16px;
  background: @gray-100;
  color: @gray-700;
  .font({.caption()});
}

.br-last-level-content__empty-list {
  color: @text-gray;
}
