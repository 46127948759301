@import 'styles/variables';

.br-flight-details__modal {
  .ant-modal {
    max-width: 488px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .ant-picker {
    height: 36px;
  }
}

.br-flight-details-modal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.br-flight-details-modal-header__subtitle {
  color: @text-gray;
}

.br-flight-details-modal__content {
  padding: 24px 20px;
  box-shadow: @shadow-sm;
  border-radius: 8px;

  .br-flight-details-modal-content__title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;
  }

  .ant-form.ant-form-horizontal {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.br-flight-details-modal__footer {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
