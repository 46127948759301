@import 'styles/variables';

.br-dispatching-overview-summary__container {
  box-shadow: @shadow-sm;
  padding: 25px 50px;
  margin-top: 22px;
  border-radius: 4px;
}

.br-dispatching-overview-summary__to-be-dispatched {
  border: 4px solid @purple-500;
  border-radius: 50%;
  width: 136px;
  height: 136px;
  vertical-align: middle;
  text-align: center;
  color: @gray-500;

  & > div {
    color: @gray-900;
  }
}

.br-dispatching-overview-summary__cards {
  margin-left: 60px;
  flex-grow: 1;
}

.br-dispatching-overview-summary__card {
  flex: 1;
  & > div:first-child::before {
    display: block;
    content: '';
    background: @yellow-400;
    height: 6px;
    width: 100%;
    margin-bottom: 18px;
  }

  &.border-yellow > div:first-child::before {
    background: @yellow-400;
  }
  &.border-blue > div:first-child::before {
    background: @blue-400;
  }
  &.border-dark-yellow > div:first-child::before {
    background: @yellow-600;
  }
  &.border-red > div:first-child::before {
    background: @red-700;
  }
}

.br-dispatching-overview-summary__card:first-child {
  & > div:first-child::before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.br-dispatching-overview-summary__card:last-child {
  & > div:first-child::before {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.br-dispatching-overview-summary-title {
  color: @gray-600;
}

.br-dispatching-overview-summary__to-be-dispatched {
  text-transform: uppercase;
}

.br-dispatching-overview-summary-count {
  &.text-dark-yellow {
    color: @yellow-600;
  }
  &.text-red {
    color: @red-700;
  }
}
