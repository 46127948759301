@import 'styles/variables.less';

.br-barcodes-list__container {
  margin-top: 24px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-barcodes-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  box-shadow: @border-bottom;
  background: linear-gradient(91.07deg, @gray-50 47.33%, @white 106.58%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.br-barcodes-list__action {
  display: flex;
  gap: 16px;
}

.br-barcodes-list__body {
  .br-empty-state {
    padding: 24px 0;
  }
}

.br-barcodes-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;

  &:not(:last-child) {
    box-shadow: @border-bottom;
  }
}

.br-barcodes-list__body {
  max-height: 512px;
  overflow: auto;
}

.br-barcodes-list__container {
  .ant-spin-container > div:not(.br-empty-state) {
    flex-direction: row;
  }
}

@media only screen and (max-width: @sm) {
  .br-barcodes-list__header {
    flex-wrap: wrap;
  }

  .br-barcodes-list__action {
    margin-top: 16px;
    flex-direction: column;
    width: 100%;
  }
}
