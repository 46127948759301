@import 'styles/variables';

.br-upload-dialog .ant-modal-title {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.br-upload-dialog .ant-modal-content {
  width: 580px;
}

.br-upload-dialog div.ant-modal-body {
  line-height: 0;
  height: 412px;
}

.br-upload-dialog__upload {
  height: 100%;
  overflow-y: auto;
}

.br-upload-dialog__upload .ant-upload-list.ant-upload-list-picture-card {
  height: 100%;
}

.br-upload-dialog__upload:not(.br-upload-dialog__upload-empty) {
  padding: 29px 24px;
  border-radius: 4px;
  border: 1px dashed @border-color-3;
}

.br-upload-dialog__upload .ant-upload.ant-upload-select-picture-card:hover {
  border: 1px dashed @border-color-3;
}

.br-upload-dialog__upload-empty
  .ant-upload.ant-upload-select.ant-upload-select-picture {
  height: 100%;
  width: 100%;
  margin: 0;
  background: none;
  border: 1px dashed #cbd5e0;
  width: 416px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.br-upload-dialog__files-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span:first-child {
    font-family: @poppins-font-600;
    font-size: 16px;
  }
  & span:last-child {
    font-family: @poppins-font-600;
    font-size: 14px;
  }
}

.br-upload-dialog__empty-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.br-upload-dialog__empty-view__image {
  margin-bottom: 22px;
}

.br-upload-dialog__empty-view__title {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}

.br-upload-dialog__empty-view__sub-title {
  color: @border-color-25;
  font-family: @poppins-font-400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 18px;
  max-width: 287px;
  text-align: center;
}

button.ant-btn.br-upload-dialog__empty-view__upload-button {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  // height: 30px;
  // width: 102px;
  // border: 0.5px solid @border-color-9;
  // border-radius: 4px;
}

.br-upload-dialog .ant-btn-primary {
  height: 45px;
  width: 123px;
  border-radius: 8px;
  background-color: @background-color-16;
}

.br-add-edit-bonuses-and-decductions__upload-attachments {
  margin-left: 23px;
}

.br-add-edit-bonuses-and-decductions__upload-attachments
  .ant-upload.ant-upload-select {
  width: 100%;
  margin-bottom: 27px;
}

.br-upload-dialog__upload .ant-upload-list-item-thumbnail,
.br-upload-dialog__upload
  .ant-upload-list-item-thumbnail.ant-upload-list-item-file {
  background: transparent url('../../../../../../assets/imgRevamp/upload2.svg')
    no-repeat;
  height: 33px;
  width: 26px;
  padding-right: 2px;
  position: relative;
  right: 4px;
  // cursor: default;
}

.br-upload-dialog__upload
  .ant-upload-list-picture
  .ant-upload-list-item-thumbnail
  img,
.br-upload-dialog__upload .anticon-file {
  display: none !important;
}

.br-upload-dialog__upload .ant-upload-list-item-card-actions-btn .anticon svg {
  font-size: 24px;
}

.br-upload-dialog__upload .ant-upload-list-item-name {
  font-size: 13px;
  font-family: @poppins-font-400;
}

.br-upload-dialog__upload .ant-upload-list-picture .ant-upload-list-item {
  border: none;
}
