.br-api-key__create-edit-modal-container.ant-modal {
  & .br-api-key__create-edit-modal__form {
    & .ant-input-number {
      width: 100%;
    }
  }
  & .ant-modal-footer {
    justify-content: flex-end;
  }
}

.br-api-key__create-edit-modal__form {
  & > .ant-row.ant-form-item {
    min-height: auto;

    &:last-child {
      margin-top: 20px;
    }
  }
}
