@import 'styles/variables';

.br-scanning-popup.ant-modal {
  width: 70%;
  height: 70%;
  border-radius: 8px;
  .ant-modal-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: unset;
    box-shadow: none;
    color: @white;
    .br-scanning-popup__actionTitle {
      font-size: 30px;
      margin-bottom: 5px;
    }
    .br-scanning-popup__actionData {
      font-size: 60px;
    }
  }
}

.bg-green {
  background-color: rgba(@green-500, 0.9);
}

.bg-gray {
  background-color: rgba(@gray-500, 0.9);
}

.bg-yellow {
  background-color: rgba(@yellow-500, 0.9);
}

.bg-red {
  background-color: rgba(@red-500, 0.9);
}

.bg-pale-red {
  background-color: rgba(@red-300, 0.9);
}

.bg-blue {
  background-color: rgba(@blue-500, 0.9);
}
