@import 'styles/variables';

.br-international-pickup-details__drawer {
  .ant-drawer-content-wrapper {
    width: 536px;
  }

  .ant-drawer-header {
    padding: 16px 20px;
    box-shadow: @border-bottom;
    border: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-close {
    display: none;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tabpane {
    padding: 20px;
  }
}

.br-international-pickup-details__title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.br-international-pickup-details__close {
  cursor: pointer;
}

.br-international-pickup-details__subtitle {
  color: @text-gray;
}
