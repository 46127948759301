@import 'styles/variables';

.br-ofd-edits__content {
  display: flex;
  gap: 24px;
  min-height: 80vh;
}

.br-ofd-edits-container__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
}

.br-ofd-edits-hubs-filter {
  min-width: 200px;

  & .ant-select {
    width: 100%;
  }
}

.br-ofd-edits-container {
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 15px;
  }
}

.br-ofd-edits-container {
  & .br-searchable-table__listing {
    .ant-table-cell:first-child {
      padding-left: 14px;
    }
  }
}
