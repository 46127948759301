@import 'styles/variables';

.ant-form.ant-form-horizontal.br-edit-delivery-modal__form-container {
  max-height: 320px;
  overflow-y: auto;
  padding-right: 10px;
  & .br-edit-delivery-modal__cod-input {
    margin-left: 24px;
    & .ant-input-group-addon {
      background: @background-color-54;
      color: @text-color-50;
      font-size: 16px;
      font-family: @lato-font-700;
      line-height: 20px;
    }
  }

  & .br-edit-delivery-modal__cod-input-cash-collection {
    & .ant-input-group-addon {
      background: @background-color-54;
      color: @text-color-50;
      font-size: 16px;
      font-family: @lato-font-700;
      line-height: 20px;
    }
  }

  & label {
    font-family: @lato-font-400;
    line-height: 20px;
  }

  & .ant-form-item-control-input-content {
    & input,
    textarea,
    .ant-select-selection-item {
      font-family: @lato-font-700;
    }
  }

  & .ant-input-number {
    width: 100%;
    border-radius: 4px;
  }
}

.br-reschedule-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  font-size: 18px;
  font-weight: bold;
  & svg {
    cursor: pointer;
  }
}

.br-reschedule-modal__container {
  & .ant-modal-footer {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    & .ant-btn {
      padding: 8px 16px;
      font-size: 14px;
    }
    & .ant-btn.br-reschdule-modal__primary-button {
      background-color: @background-color-51;
      color: white;
    }
  }
}

.br-edit-delivery-modal__cod-change-banner {
  margin-bottom: 20px;

  .br-banner-content__text-container > img {
    max-width: 16px;
    margin-top: 4px;
  }

  .br-banner-content__text p {
    margin-top: 0;
    .font({.body()});
  }
}

.br-edit-delivery-modal__allow-to-open-package {
  display: flex;
  align-items: center;

  .anticon {
    margin-left: 4px;
  }
}
