@import 'styles/variables.less';

.br-hub-transfer-dispatch-table__container {
  margin-top: 16px;

  & > .br-table-component {
    box-shadow: @shadow-sm;
  }
}

.br-hub-transfer-dispatch-update__time {
  & > span {
    display: block;
    color: @gray-400;
  }
}

.br-hub-transfer-dispatch-table__container {
  .ant-tag {
    width: fit-content;
    border-radius: 10px;
  }

  .br-table-component .br-table__quick-filter-tabs {
    margin: 0;
    padding: 18px 0;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 4px;
  }

  .br-table-pagination-prev-icon,
  .br-table-pagination-next-icon {
    padding: 5px;
  }
}

.br-hub-transfer-dispatch-scanning__actions {
  margin-left: 8px;
  align-items: flex-end;
}

.br-hub-transfer-dispatch__table {
  .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
    display: none;
  }
}
