@import 'styles/variables';

.br-pickup-details {
  background-color: @white;
  box-shadow: @border-all-sides;
  border-radius: 16px;

  overflow: hidden;

  &-header {
    display: flex;
    align-items: center;
    gap: @spacing-sm;

    padding-block: 20px;
    padding-inline: @spacing-lg;
    box-shadow: @border-bottom;

    &__icon {
      width: 24px;
      height: 24px;

      &.stroked-icon {
        > svg > * {
          stroke: @teal-500;
        }
      }

      &:not(.stroked-icon) {
        > svg > * {
          fill: @teal-500;
        }
      }
    }

    &__title.ant-typography {
      .heading();
      color: @gray-900;
      margin-bottom: 0;

      flex: 1;
    }
  }

  &-content {
    display: grid;
    grid-auto-flow: row;

    @media only screen and (min-width: @md) {
      grid-template-columns: repeat(4, 1fr);

      > .double-width {
        grid-column: span 2;
      }

      > .full-width {
        grid-column: span 4;
      }
    }

    > * {
      box-shadow: @border-right, @border-bottom;

      [dir="rtl"] & {
        box-shadow: @border-left, @border-bottom;
      }

      padding: @spacing-lg;
    }

    &__title, &__title.ant-typography {
      .body();
      color: @gray-500;
    }

    &__value, &__value.ant-typography {
      .button-lg();
      color: @gray-900;
      margin-bottom: 0;

      span.ant-typography {
        .caption();
        color: @gray-500;

        display: block;
        margin-top: @spacing-xs;
      }
    }
  }
}