@import 'styles/variables';

.br-pickups__check {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: @spacing-sm;

  color: @red-600;

  > svg > * {
    fill: @red-700;
  }

  &.br-pickups__check-valid {
    color: @gray-500;

    > svg > * {
      fill: @gray-500;
    }

    &.colored {
      color: @green-500;

      > svg > * {
        fill: @green-500;
      }
    }
  }
}