@import 'styles/variables.less';

.br-hub-and-star-selectors__container {
  padding: 24px;
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.br-hub-and-star-selectors {
  align-items: flex-end;
  & > .ant-row.ant-form-item {
    min-height: 100%;
  }

  & > * {
    max-width: 260px;
  }

  .ant-select {
    min-width: 260px;
  }
}

.br-hub-and-star-selectors__container {
  .ant-select {
    max-width: 260px;
  }
}

.br-hub-and-star-selectors-deliveries__banner {
  margin-top: 16px;

  .br-banner-content {
    padding: 12px 24px;
  }
}

.br-hub-and-star-selectors-deliveries__count {
  color: @yellow-600;
}

.br-hub-and-star-selectors-deliveries__banner {
  .br-banner-content__button {
    button.ant-btn {
      background-color: unset;
      color: @yellow-600;
      text-decoration: underline;
      border: 0;
    }
  }
}

.br-hub-transfer-error__banner {
  .br-banner-content__text-container {
    align-items: center;
  }

  .br-banner-content__text p {
    margin-top: 0;
  }
}

.br-hub-and-star-selectors-seals__summary {
  display: flex;
}

.br-hub-transfer__summary {
  background-color: @teal-50;
  border-radius: 100px;
  padding: 6px 20px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  & > span {
    color: @teal-500;
  }
}
