@import 'styles/variables';

.br-date-time__container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div:last-child {
    color: @text-gray;
  }
}
