.br-bulk-upload-cash__container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  & .ant-upload.ant-upload-drag {
    border: none;
  }
  & .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}

.br-bulk-upload-error-reasons__container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 16px;
  & > span {
    color: black;
  }
  & > ul {
    margin: 0;
    padding: 16px 32px;
    border: 1px solid grey;
    height: 300px;
    overflow: scroll;
    border-radius: 8px;
    & > li {
      margin-bottom: 8px;
    }
  }
}
