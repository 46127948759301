@import 'styles/variables';

.br-rejection-reasons-modal .br-content-header__title {
  font-size: 20px;

  & .anticon {
    font-size: 12px;
    cursor: pointer;
  }
}

.br-rejection-reasons-modal .ant-radio-group-outline {
  display: flex;
  flex-direction: column;
}

.br-rejection-reasons-modal label.ant-radio-wrapper span {
  font-family: @lato-font-700;
}

.br-rejection-reasons-modal__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 23px;
  & .ant-btn:first-child {
    margin-right: 12px;
    background: @background-color-18;
    border-color: @background-color-18;
    color: @text-color;
  }
}
