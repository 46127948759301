@import 'styles/variables';

.br-upload-dialog .ant-modal-title {
  color: @text-color;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.br-upload-dialog .ant-modal-content {
  width: 580px;
}

.br-upload-dialog div.ant-modal-body {
  line-height: 0;
  height: 412px;
}

.br-upload-dialog__upload {
  height: 100%;
  overflow-y: auto;
}

.br-upload-dialog__upload .ant-upload-list.ant-upload-list-picture-card {
  height: 100%;
}

.br-upload-dialog__upload:not(.br-upload-dialog__upload-empty) {
  padding: 10px;
  border-radius: 4px;
  border: 1px dashed @border-color-3;
}

.br-upload-dialog__upload .ant-upload.ant-upload-select-picture-card:hover {
  border: 1px dashed @border-color-3;
}

.br-upload-dialog__upload:not(.br-upload-dialog__upload-empty)
  .ant-upload-select {
  background-color: @background-color-8;
  border: none;
}

.br-upload-dialog__upload-empty
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  height: 100%;
  width: 100%;
  margin: 0;
  background: none;
}

.br-upload-dialog__upload-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.br-upload-dialog__upload-card .anticon {
  font-size: 22px;
  color: @gray-300;
  margin-bottom: 10px;
}

.br-upload-dialog__upload-card span:not(.anticon) {
  color: @text-color-4;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 11px;
}

.br-upload-dialog__empty-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.br-upload-dialog__empty-view__image {
  margin-bottom: 22px;
}

.br-upload-dialog__empty-view__title {
  color: @text-gray-dark;
  margin-bottom: 4px;
}

.br-upload-dialog__empty-view__sub-title {
  color: @text-gray;
  margin-bottom: 17px;
  .font({.caption()});
}

button.ant-btn.br-upload-dialog__empty-view__upload-button {
  color: @text-color-12;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  height: 30px;
  width: 102px;
  border: 0.5px solid @gray-200;
  border-radius: 4px;
}

.br-upload-dialog .ant-btn-primary {
  height: 45px;
  width: 123px;
  border-radius: 8px;
  background-color: @background-color-16;
}

.br-upload-dialog__empty-view__upload-title-part {
  color: @text-teal;
  .font({.button()});
}

.br-hide-upload-button {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    display: none;
  }
}

.br-upload-dialog__upload.br-support-ticket__upload {
  .ant-upload-list-item-thumbnail {
    background: unset;
    height: 100%;
    width: 100%;
  }
}
