@import 'styles/variables';

.br-hubs-receive-from-business__pickup-scanning__business-info.ant-card {
  > .ant-card-body .pickup-scanning__business-info__contact-person div.ant-select {
    width: 100%;

    .ant-select-selection-item,
    .ant-select-item-option-content {
      display: flex;
      justify-content: space-between;

      &::after {
        content: unset;
      }
    }

    .business-info__contact-person__phone-number {
      .body();
      color: @gray-500;
      display: inline-block;
    }
  }
}