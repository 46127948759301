@import 'styles/variables';

.br-order-details__cancel-order-modal-footer {
  display: flex;
  justify-content: flex-end;
  box-shadow: @border-top;
  padding: 17px;

  & > .ant-btn:first-child {
    margin-right: 8px;
  }
}

.br-order-details__cancel-order-modal-title {
  padding: 20px;
}

.br-order-details__cancel-order-modal-container {
  .ant-modal-body {
    padding: 0;
  }

  .ant-form-item {
    padding: 0 20px;
  }
}
