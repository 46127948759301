.br-scan-modal {
  .br-scan-serials-modal__scan-field.ant-row.ant-form-item {
    padding: 0;
    margin-bottom: 10px;
  }
}

.br-scan-modal__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
