.br-swapping-tables .ant-table .ant-table-cell {
  font-size: 13px;
}

.br-swapping-tables {
  display: flex;
}

.br-swapping-tables > div {
  width: 50%;
}

.br-swapping-tables__form-input {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  display: flex;
  width: fit-content;
}

.br-swapping-tables__form .ant-col-3 {
  flex: 0 0 40.6%;
  max-width: 40.6%;
}

.br-swapping-tables__form .ant-col-5 {
  flex: 0 0 50%;
  max-width: 50%;
}

.br-swapping-tables__form .ant-input-affix-wrapper,
.br-swapping-tables__form
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper-focused {
  border-radius: 4px;
  border-color: white;
  box-shadow: none;
}

.test {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  width: 260px;
}

.br-swapping-tables__form-input .ant-input-suffix,
.br-swapping-tables__form .ant-input-suffix {
  display: none;
}

.br-swapping-tables__form__inputs {
  width: 260px;
}

.br-swapping-tables__form__inputs input:first-child {
  border-right: 0;
  box-shadow: none;
  width: 20%;
}

.br-swapping-tables__form__inputs input:last-child {
  border-left: 0;
  box-shadow: none;
  width: 80%;
}

.br-swapping-tables__left-table-container {
  margin-right: 1rem;
}
