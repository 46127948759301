:root {
  //lato fonts
  --lato--font-400: 'Lato/Regular';
  --lato--font-700: 'Lato/SemiBold';
  --lato--font-900: 'Lato/Bold';

  //poppins font
  --poppins--font-400: 'Poppins/Regular';
  --poppins--font-500: 'Poppins/Medium';
  --poppins--font-600: 'Poppins/SemiBold';
}
