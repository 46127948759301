@import 'styles/variables.less';

.br-undebriefed-routes__table {
  & tr :first-child {
    white-space: nowrap;
    width: 200px;
  }
}

.br-undebriefed-routes__table-alarming-label {
  background-color: @background-color-48;
  padding: 4px 8px;
  color: @text-color-15;
  border-radius: 4px;
}
