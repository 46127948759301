@import 'styles/variables';

.br-wrong-scan-filters__container {
  border-radius: 8px;
  background-color: @white;
  box-shadow: @shadow-sm;
  margin: 32px 0;

  .ant-picker {
    height: 36px;
    min-width: 220px;
  }
}

.br-wrong-scan-filters__header {
  padding: 16px;
  border-bottom: 1px solid @gray-300;
}

.br-wrong-scan-filters__content {
  padding: 16px;
}

.br-wrong-scan-filters__actions {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}
