.br-select-half-width {
  width: calc(50% - 11px);
}

.br-swapping-tables .br-searchable-component__header .ant-typography {
  width: 100%;
}

.br-packages-debrief__tabs__pickups__thumbnail-container {
  width: 45px;
  margin: auto;
  svg {
    width: 30px;
    height: 30px;
  }
}