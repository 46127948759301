@import 'styles/variables';

.br-escalation-severity__container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.br-escalation-severity__item {
  width: 18px;
  height: 4px;
  background-color: @icon-disabled;
  border-radius: 4px;

  &-1 {
    background-color: @icon-green;
  }

  &-2 {
    background-color: @yellow-400;
  }

  &-3 {
    background-color: @icon-red;
  }

  &-4 {
    background-color: @bosta-red;
  }

  &-5 {
    background-color: @icon-red-dark;
  }

  &-6 {
    background-color: @red-900;
  }
}
