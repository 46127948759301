@import 'styles/variables.less';

.br-zoning-table__container {
  margin-top: 15px;
  padding-bottom: 40px;
}

.br-zoning-table__container {
  .br-table-component.br-table-component__empty-state .br-empty-state {
    min-height: 75vh;
  }
}

.br-zoning__table {
  .br-table__quick-filter-tabs {
    padding: 8px 0;
    margin: 0;

    .br-table__header__actions {
      max-width: 309px;

      .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 4px;
      }
    }
  }
}

.br-zoning__table {
  tr.ant-table-row-level-0 {
    background-color: @gray-200;

    & > td {
      max-width: 10px;
      white-space: nowrap;
    }
  }

  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background-color: transparent;
  }

  .ant-table-row.ant-table-row-level-0:hover {
    background-color: @gray-200;
  }
}

.br-zoning-table__container {
  .ant-table-row.ant-table-row-level-1 {
    & > td.ant-table-cell {
      padding: 16px 12px;
    }
  }
}

.br-zoning-table__container {
  .br-table .br-zoning-table__zone-row {
    & > td.ant-table-cell {
      padding: 14px 12px;
      background-color: @teal-300;
    }
  }
}

.br-zoning-table__container {
  .br-table .ant-table-row {
    td.ant-table-cell {
      .font({.caption()});
    }
  }
}

.br-zoning-table__city-name {
  color: @gray-500;
}

.br-zoning-add-new__dropdown {
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ant-dropdown-menu-item.br-zoning__delete-action {
    color: @red-600;

    & > svg path {
      fill: @red-600;
    }
  }
}

.br-zoning-table__time {
  color: @gray-400;
}

.br-zoning-availability__toggle.ant-row.ant-form-item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: auto;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & .ant-col.ant-form-item-control {
    flex: unset;
    width: auto;
  }
}

.br-zoning-availability__toggle {
  .ant-switch-checked {
    background-color: @teal-500;
  }
}
