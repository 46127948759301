@import 'styles/variables.less';

.br-add-category-modal {
  .ant-modal-footer {
    justify-content: flex-end;
  }
}

.br-add-category-modal__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 43px;
  > .ant-btn {
    border: none;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  > span:first-child {
    font-size: 18px;
    line-height: 28px;
    font-family: @poppins-font-600;
    color: @text-color-50;
  }
}

.br-add-category-modal__form {
  .ant-form-item > .ant-form-item-label {
    margin-bottom: 10px;
    > label {
      font-family: @lato-font-400;
      color: @text-color-50;
    }
  }
  .ant-input-number {
    border-radius: 4px;
    width: 100%;
  }
  .ant-row.ant-form-item.br-add-category-modal__form-select-all {
    flex-direction: row-reverse;
    align-items: center;
    width: 155px;
    .ant-form-item-label {
      margin: 0 0 0 10px;
    }
    .ant-checkbox {
      top: 4px;
    }
  }
}
