@import 'styles/variables';

.br-fulfillment-inventory-serials {
  .br-fulfillment-return-list__date-filter-section {
    padding-bottom: 20px;
    gap: 8px;
  }

  .br-po-statistics__statistic__icon {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    background: @gray-50;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: @icon-default;
      }
    }
  }

  .br-fulfillment-inventory-serials__bulky-actions-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.br-fulfillment-inventory-serials__actions-menu {
  .ant-dropdown-menu-title-content div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
