@import 'styles/variables';

.hubsOverview-container > * {
  width: 15%;
}

.hubsOverview-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: @spacing-lg;
  margin-bottom: @spacing-xl;
}

.hubsOverview-container .single-info__container {
  width: auto;
}

.br-table .ant-table-column-sorters {
  display: flex;
  align-items: center;
}

.br-table .ant-table-column-sorters .ant-table-column-title {
  margin-right: 8px;
}

.br-table .ant-table-column-sorters .ant-table-column-sorter {
  margin-left: 0;
  width: 60%;
}

@media only screen and (max-width: @xs) {
  .hubsOverview-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
