@import 'styles/variables.less';

.br-generate-barcodes__modal {
  .ant-modal-header {
    .ant-modal-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg {
        cursor: pointer;
      }
    }
  }

  .ant-row.ant-form-item {
    min-height: auto;
  }
}

.br-generate-barcodes__help-text {
  color: @text-gray;
}

.br-generate-barcodes__banner {
  margin-top: 24px;

  .br-banner-content__text p {
    margin: 0;
    .font({.body-medium()});
  }
}

.br-generate-barcodes__modal {
  .ant-input-affix-wrapper {
    padding-right: 0;
  }
}

.br-generate-barcodes-modal__input-actions {
  display: flex;
  flex-direction: column;
  background-color: @gray-200;

  & > .ant-btn:not(.ant-btn-circle) {
    padding: 0;
    height: auto;
    width: fit-content;
    border-radius: 0;

    &:first-child svg {
      transform: scaleY(-1);
    }
  }
}
