@import "styles/variables";

.br-ops-control-wrong-zone__confirmation-modal.ant-modal > .ant-modal-content {
  padding: @spacing-xl;
  padding-top: calc(@spacing-xl + 13px);
  border-radius: 12px;

  > .ant-modal-header {
    border-bottom: none;
    box-shadow: none;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;

    > .ant-modal-title {
      flex: 1;

      > div {
        margin-bottom: @spacing-xl;
        width: fit-content;
        margin-inline: auto;
        position: relative;
        z-index: 0;

        > svg {
          width: 22px;
          height: 22px;

          > path {
            stroke: @yellow-600;
          }
        }

        &::before, &::after {
          content: '';
          position: absolute;
          top: 50%;
          inset-inline-start: 50%;
          background-color: @yellow-50;
          width: 48px;
          height: 48px;
          z-index: -1;
          border-radius: 9999px;
          transform: translateX(-50%) translateY(-50%);
        }

        &::after {
          width: 36px;
          height: 36px;
          background-color: @yellow-100;
        }
      }
    }
  }

  > .ant-modal-body {
    padding: 0;
  }

  > .ant-modal-footer {
    border: none;
    background-color: @white;
    padding: 0;
    gap: @spacing-md;
    margin-top: @spacing-2xl;

    > * {
      flex: 1;
      margin: 0;
    }
  }
}