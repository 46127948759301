@import 'styles/variables';

.br-deliveries-search__group:not(:last-child) {
  box-shadow: @border-bottom;
  padding: 8px 0;
}

.br-deliveries-search__group-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.br-deliveries-search__actions {
  display: flex;
  gap: 8px;
}

.ant-row.ant-form-item.br-deliveries-search__fulfillment-checkbox {
  margin-bottom: 8px;
}

.br-deliveries-search__container.ant-collapse {
  > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    vertical-align: middle;
  }

  .ant-collapse-header {
    align-items: center;
  }

  .ant-collapse-header-text {
    .font(.display-md());
  }
}
