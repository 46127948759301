@import 'styles/variables.less';

.br-new-order-card__title {
  color: @text-color;
  font-size: 17px;
  font-family: @poppins-font-600;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.br-new-order-card__title__number {
  background: @background-color-16;
  height: 28px;
  width: 28px;
  margin-right: 7px;
  border-radius: 50%;
  text-align: center;
}

.br-new-order-card__title__number span {
  width: 16px;
  height: 25px;
  color: @background-color;
  font-size: 14px;
  font-family: @lato-font-400;
  font-weight: 500;
  text-align: center;
}

.br-new-order-card__header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: @background-color-5;
  border: 1px solid @border-color-20;
  border-bottom: 0;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 425px) {
  .br-new-order-card__header {
    height: unset;
    display: flex;
    flex-direction: column;
  }
}
