@import 'styles/variables';

.br-pickup-activity {
  > .br-pickup-details-header > .ant-typography.br-pickup-details-header__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .ant-radio-group > .ant-radio-button-wrapper {
      height: 36px;

      &:first-of-type {
        border-start-start-radius: 8px;
        border-end-start-radius: 8px;
      }

      &:last-of-type {
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;
      }
    }
  }

  > .br-pickup-details-content {
    overflow-y: auto;
    max-height: 65dvh;
  }
}