@import 'styles/variables';

.br-soho-tag.ant-tag {
  font-family: @lato-font-700;
  border-radius: 10px;
  border-color: @gray-100;
  font-size: 12px;
  margin-right: 0;
  margin-left: 10px;
  color: @gray-800;
  background: @gray-100;
}

.br-pickups__table-header {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.br-container-content .br-pickups__cards {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.br-pickups__table {
  &.hide-pagination {
    .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
      display: none;
    }
  }
}

.br-pickups__table.br-table-component {
  border-radius: 16px;
  overflow: hidden;

  & > .br-table__quick-filter-tabs {
    background-color: @white;
    margin-bottom: 0;
    padding-block: @spacing-lg;

    & > .br-table__header {
      padding: 0;

      & > .br-table__header__actions {
        align-items: center;

        > span.br-table__header__actions-label {
          .font-12();
          font-family: @poppins-font-600;
        }
      }
    }
  }

  & > .br-table {
    .br-pickups {
      &-location__address-line {
        color: @gray-500;
      }
    }
  }
}

.br-pickups__form-row.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.br-pickups-checkbox__container.form-group {
  height: 36px;
  border: 1px solid @gray-300;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  margin: 24px 5px 18px 5px;

  .ant-checkbox-wrapper {
    width: 100%;
    max-width: none;
  }
}

.br-pickups__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  width: 20px;
  border-radius: 9999px;

  margin-inline-start: @spacing-sm;

  &:has(.br-pickups__icon-created-by-star) {
    background-color: @yellow-100;

    > svg > * {
      stroke: @yellow-600;
    }
  }

  &:has(.br-pickups__icon-first-business-pickup) {
    background-color: @green-100;

    > svg > * {
      fill: @green-600;
    }
  }

  &:has(.br-pickups__icon-drop-off) {
    background-color: @teal-200;
    > svg > * {
      stroke: @teal-500;
    }
  }
}

.br-pickups__table-count {
  display: flex;
  align-items: center;
  gap: 4px;
}

.br-pickups__table-subtitle {
  display: block;
  color: @gray-500;
  .font({
    .subheading()
  });
}

.br-pickups__view-map {
  cursor: pointer;
  color: @teal-500;

  &:hover {
    color: @teal-700;
  }
}
