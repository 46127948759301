@import 'styles/variables';

.br-fulfillment-business {
  padding: 0;
  margin: 0;
  background: @bg-gray-light;

  .br-tabs {
    .ant-tabs.ant-tabs-top.br-tabs__tab {
      height: auto;

      .ant-tabs-nav {
        height: 60px;
        margin-bottom: 44px;

        .ant-tabs-nav-wrap {
          background: @white;
          box-shadow: @border-bottom;
          margin-left: 0;
          padding-left: 24px;
        }

        .ant-tabs-tab-disabled {
          opacity: 0.5;

          .ant-tabs-tab-btn {
            color: @text-gray;
          }
        }
      }
    }
  }
}
