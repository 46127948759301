@import 'styles/variables';

.br-sales-order {
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: auto;
  }
}

.br-info-card__item {
  display: flex;
  gap: 8px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  span {
    color: @text-gray-dark;
  }

  svg {
    width: 20px;
  }
}

.checked-icon {
  width: 16px;

  path {
    fill: @green-600;
  }
}

.br-sales-order__left-items {
  color: @text-red;
  margin-right: 12px;
}
