@import 'styles/variables';

.br-hub-receiving__scanning-type-selector {
  padding: 8px 0;

  .ant-row.ant-form-item {
    flex-direction: row;
    align-items: center;

    & > .ant-col.ant-form-item-control {
      width: auto;
      margin-left: 8px;
    }
  }

  .ant-radio-group > label.ant-radio-button-wrapper {
    margin-left: 8px;
    border: 1px solid @gray-100;
    border-radius: 4px;

    &::before {
      display: none;
    }
  }

  .ant-radio-group
    > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid @teal-500;
    color: @teal-500;

    &:first-child {
      border-right-color: @teal-500;
    }
  }
}
