@import 'styles/variables.less';

.br-bosta-fees-content {
  width: 330px;
  padding: 17px;
  padding-top: 22px;
}

.br-bosta-fees-content__title {
  color: @text-color;
  font-family: @lato-font-700;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.br-bosta-fees-content__sub-title {
  font-family: @lato-font-700;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 17px;
}

.br-bosta-fees-content__sub-title-negative {
  color: @text-color-60;
}
