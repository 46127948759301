.br-bosta-shop {
  & .br-bosta-shop__form-container {
    & .ant-input-number {
      width: 100%;
    }
  }
}

.ant-row.ant-form-item.br-bosta-shop__submit-action {
  margin-top: 16px;
}
