.br-delivery-images__images-wrapper {
  margin-bottom: 15px;
  justify-content: flex-start;
  display: flex;
  width: 90% !important;
}

.br-delivery-images__single-image {
  width: 150px;
  margin-right: 15px;
  height: 150px;
}

.br-delivery-images__section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.br-delivery-images__section-header {
  margin-bottom: 10px;
}

.br-delivery-images__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
