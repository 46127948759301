.app-container div.br-vendors__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  .ant-btn {
    max-width: 200px;
  }
}

.br-vendor__name {
  text-decoration: underline;
}

.br-vendors-listing__container {
  .ant-spin-nested-loading {
    height: auto;
  }
}
