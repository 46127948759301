@import 'styles/variables';

.br-side-tabs-parent {
  margin: 0;
  padding: 0;
}

.br-side-tabs {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 60px;
}

.br-side-tabs__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 29px;
  padding-left: 33px;
  padding-right: 33px;
  // overflow-x: auto;
}

.br-side-tabs__container,
.br-side-tabs__menu {
  height: 100%;
}

.br-side-tabs__menu.ant-menu {
  background-color: @background-color;
  padding-top: 20px;
}

.br-side-tabs__menu__not-collasped.ant-menu {
  width: 278px;
}

.br-side-tabs__menu .ant-menu-item a,
.br-side-tabs__menu .ant-menu-item .anticon svg path,
.br-side-tabs__menu .ant-menu-item.ant-menu-item-active a,
.br-side-tabs__menu .ant-menu-item.ant-menu-item-active .anticon svg path {
  display: flex;
  align-items: center;
  color: #9ca3af;
  fill: #9ca3af;
  font-size: 14px;
  font-family: @poppins-font-500;
}

.br-side-tabs__menu
  .ant-menu-item
  .anticon.br-settings-score-card-icon
  svg
  path,
.br-side-tabs__menu
  .ant-menu-item.ant-menu-item-active
  .anticon.br-settings-score-card-icon
  svg
  path {
  fill: white;
}

.br-side-tabs__menu.ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: @background-color-2;
}

.br-side-tabs__menu .ant-menu-item.ant-menu-item-selected::after {
  border-right: 3px solid @border-color-15;
}

.br-side-tabs__menu .ant-menu-item.ant-menu-item-selected a,
.br-side-tabs__menu .ant-menu-item.ant-menu-item-selected .anticon {
  color: @text-color;
  font-family: @poppins-font-500;
}

.br-side-tabs__menu
  .ant-menu-item.ant-menu-item-selected
  .anticon.br-settings-score-card-icon
  svg
  path {
  stroke: @text-color-27;
  fill: white;
}

.br-side-tabs__menu li {
  display: flex;
  align-items: center;
}

.br-side-tabs__menu li.ant-menu-item span.anticon {
  // margin-right: 18px;
  color: @text-color-3;
  font-size: 20px;
}

.br-side-tabs__menu li.ant-menu-item span.anticon .anticon {
  margin-right: 0;
}

.br-side-tabs__menu .br-side-tabs__menu__new-feature.ant-menu-item {
  padding-left: 0 !important;
}

.circle {
  border-radius: 50%;
}

.br-side-tabs__menu__new-feature__circle {
  margin: 0 7px;
}

.br-side-tabs__menu__new-feature__circle__outer {
  width: 16px;
  height: 16px;
  background: #ff00001a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.br-side-tabs__menu__new-feature__circle__inner {
  width: 8px;
  height: 8px;
  background: @text-color-27;
}

@media only screen and (max-width: 992px) {
  .br-side-tabs__menu li.ant-menu-item span.anticon {
    margin-top: 0px;
    font-size: 23px;
  }
}

@media only screen and (max-width: 600px) {
  .br-side-tabs {
    overflow: auto;
  }

  .br-side-tabs__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
