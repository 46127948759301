@import 'styles/variables';

.price-breakdown-container {
  display: flex;
  flex-direction: column;
}

.price-breakdown-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  & span {
    color: @gray-400;
  }
}

.price-breakdown-total-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  border-top: 1px solid @gray-500;
}
