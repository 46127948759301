@import 'styles/variables';

.br-pickups__pickup-settings__form .ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 0;
}

.br-pickups__pickup-settings__form
  .ant-form-item
  .br-pickups__pickup-settings__form__selector,
.br-pickups__pickup-settings__form__selector .ant-picke {
  width: 552px;
  height: 32px;
  border-radius: 4px;
}

.br-pickups__pickup-settings__form__contacts {
  box-sizing: border-box;
  height: 100%;
  border: 1px solid @border-color-20;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
}

.br-pickups__pickup-settings__form__form-input .ant-col.ant-form-item-control,
.br-pickups__pickup-settings__form__form-input label {
  width: 100%;
  display: flex;
  padding: 10px;
  padding-top: 0;
}

.br-pickups__pickup-settings__form
  .br-pickups__pickup-settings__form__notes-field.ant-input {
  // height: 168px;
  width: 803px;
  border-radius: 4px;
}

.br-pickups__pickup-settings__form__add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 136px;
  height: 21px;
  color: @text-color-29;
  font-family: @poppins-font-600;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
}

.br-pickups__pickup-settings__form__contacts-sub-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.br-pickups__pickup-settings__form__contacts-header,
.br-pickups__pickup-settings__form__header {
  padding-bottom: 18px;
}

.br-pickups__pickup-settings
  .br-pickups__pickup-settings__form__header__title
  .ant-typography,
.br-pickups__pickup-settings__form__contacts-header__title .ant-typography {
  font-size: 17px;
  font-weight: 600;
  font-family: @poppins-font-600;
  margin-bottom: 0;
  color: @text-color;
}

.br-pickups__pickup-settings__form__repeat-recurring-title .ant-typography {
  font-size: 15px;
  font-weight: 500;
  font-family: @lato-font-400;
  color: @text-color;
}

.br-pickups__pickup-settings__form__notes-header.ant-row.ant-form-item {
  height: auto;
}

.br-pickups__pickup-settings__form__notes-header.ant-row.ant-form-item,
.br-pickups__pickup-settings__form__repeat-recurring-title {
  padding-top: 30px;
  padding-bottom: 30px;
}

.br-pickups__pickup-settings__form__pickup_date {
  padding-top: 30px;
}

.br-pickups__pickup-settings {
  padding: 25px 0;
}

.br-pickups__pickup-settings__form__contacts-header__sub-title,
.br-pickups__pickup-settings__form__header__sub-title {
  color: @text-color;
  font-size: 13px;
  line-height: 20px;
}

.br-pickups__pickup-settings__form__bulky-header__sub-title {
  color: @text-color;
  font-size: 15px;
  line-height: 20px;
}

.br-pickups__pickup-settings__form__bulky-header
  .br-pickups__bulky-option
  .ant-checkbox-wrapper {
  margin-top: 12px;
}

.ant-select-single.ant-select-open .ant-select-selection-item,
.br-pickups__pickup-settings__form__selector.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}

.br-pickups__pickup-settings__form__selector.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px;
}

.br-pickups__pickup-settings__form__repeat-recurring-edit-content div {
  margin-right: 22px;
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
}

.br-pickups__pickup-settings__form__repeat-recurring-edit-content button {
  margin-right: 22px;
  font-family: @lato-font-400;
}

.br-pickups__pickup-settings__form__repeat-recurring-edit-content {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.br-pickups__pickup-settings__form__repeat-recurring-edit-content .anticon {
  font-size: 21px;
  margin-right: 12px;
}

.br-pickups__pickup-settings__form__contacts-header .ant-btn[disabled] {
  background: none;
}

.br-pickups__pickup-settings__form__submit-button.ant-btn {
  height: 34px;
  width: 131px;
}

.br-pickups__pickup-settings__form__submit-button.ant-btn span {
  font-family: @lato-font-400;
}

.br-pickups__pickup-settings__form__selector {
  .ant-select-item-option-content {
    padding: 0 12px;
  }
}