@import 'styles/variables';

.br-missing-multi-packages__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  & > div {
    width: 100%;
  }
}

.br-missing-multi-packages__actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.br-missing-multi-packages__content {
  padding: 16px;
  background-color: @yellow-50;
  border-radius: 8px;
}

.br-missing-multi-packages__title {
  text-align: center;
  margin-top: 20px;

  > span {
    color: @yellow-600;
  }
}

.br-missing-multi-packages__icon__container {
  display: flex;
  justify-content: center;
}

.br-missing-multi-packages__icon.anticon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @yellow-100;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 8px solid @yellow-50;

  svg path {
    fill: @yellow-600;
  }
}

.br-missing-multi-packages-content__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.header > span {
    color: @text-gray;
    text-transform: uppercase;
    .font({.subheading()});
  }
}

.br-missing-multi-packages__content {
  max-height: 300px;
  overflow: auto;

  .ant-divider-horizontal {
    margin: 16px 0;
  }
}

.br-missing-multi-packages__content-items {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .br-multi-package__container > span {
    .font({.body-medium()});
  }
}
