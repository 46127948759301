@import 'styles/variables';

.br-hubs-receive-from-business {
  > .br-pickups-tab-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    background-color: @white;
    box-shadow: @border-all-sides;
    border-radius: 16px;
    padding: @spacing-xl;

    > .br-pickups-tab-actions__scannig-type-title {
      margin-block: 0;
      margin-inline-end: @spacing-lg;
    }

    > .br-pickups-tab-actions {
      margin-bottom: 0;

      > .ant-radio-group {
        gap: @spacing-sm;
        > .ant-radio-button-wrapper {
          border-radius: 8px;

          &.ant-radio-button-wrapper-checked {
            background-color: @teal-500;
            color: @white;
          }
        }
      }
    }

    > .br-pickups-tab__scanning-inputs {
      flex-basis: 100%;
      max-width: unset;
      .ant-input-group {
        max-width: 400px;
      }
    }
  }
}