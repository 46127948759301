@import 'styles/variables.less';

.br-receive-seals {
  font-weight: 600;

  .br-swapping-tables__container {
    margin-top: 16px;
  }
}

.br-receive-seals__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  border: 1px solid @gray-100;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: @shadow-sm;
  margin-bottom: 16px;
}

.br-receive-seals__header__title {
  margin-bottom: 20px;

  span {
    color: @deep-teal;
  }
}

.br-receive-seals__header__counts {
  display: flex;
  gap: 12px;

  .br-receive-seals__header__counts__count {
    padding: 6px 20px;
  }
}

.br-receive-seals__tag {
  border-radius: 100px;
  background: @teal-50;

  span {
    color: @deep-teal;
  }
}

.br-receive-seals__content {
  .ant-tag {
    width: fit-content;
    border-radius: 10px;
  }

  .br-table-component .br-table__quick-filter-tabs {
    margin: 0;
    padding: 18px 0;
  }

  .br-table-pagination-prev-icon,
  .br-table-pagination-next-icon {
    padding: 5px;
  }
  & > .br-table-component {
    box-shadow: @shadow-sm;
  }
}
