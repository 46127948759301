@import 'styles/variables';

.br-Previous-date-card__container {
  height: 400px;
  border: 1px solid;
  width: 100%;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid @border-color-23;
}

.br-previous-date-card__export-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.br-previous-date-card__header {
  display: flex;
  align-items: flex-start;
}

.br-previous-date-card__export-button-container {
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.br-Previous-date-card__container h4 {
  font-family: @poppins-font-500;
}

.br-previous-date-card__export-button-container p {
  color: @gray-500;
}

.br-previous-date-card__export-button {
  width: 60%;
}
