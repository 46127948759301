@import 'styles/variables.less';

.br-barcodes-to-awb__container {
  margin-top: 12px;
}

.br-barcodes-to-awb-cards__container {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}

@media only screen and (max-width: @sm) {
  .br-barcodes-to-awb-cards__container {
    flex-wrap: wrap;
    .br-barcodes-to-awb__card {
      width: 100%;
    }
  }
}
