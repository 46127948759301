@import 'styles/variables';

.br-geofences {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.br-geofences__hub-selectors {
  width: 550px;
}

.br-geofences__map-container {
  margin-top: 36px;
  > div > div {
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .gm-style-iw.gm-style-iw-c button:focus {
    outline: none;
  }
}

.br-geofences-info-box__container {
  background-color: @white;
  width: fit-content;
  padding: 6px;
}

.br-geofences__actions {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-btn {
    min-width: 200px;
  }
}

.br-geofences__simplify-action {
  display: flex;
  gap: 8px;
  margin-top: 8px;

  .ant-row.ant-form-item {
    min-height: auto;
  }

  .ant-btn {
    margin-top: 28px;
  }
}
