@import 'styles/variables.less';

.br-inventory-request-details {
  background-color: @bg-gray-light;
  .ant-spin-nested-loading {
    width: fit-content;
  }
}

.br-inventory-request-details__header__actions,
.br-inventory-request-details,
.br-inventory-request-details__quantity-column {
  display: flex;
}

.br-inventory-request-details__header,
.br-inventory-request-details {
  width: 100%;
  justify-content: center;
}

.br-inventory-request-details__header__actions .ant-btn:first-child {
  margin-right: 8px;
}

  .br-inventory-request-details__header-close-icon {
    margin-right: 12px;
    border: 1px solid @gray-200;
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;
  }

.br-inventory-request-details__content {
  display: flex;
  width: 100%;
  height: fit-content;
  max-width: 996px;
  padding: 24px 0;
  > div {
    box-shadow: @shadow-sm;
    border-radius: 8px;
  }
  .br-inventory-request-details__content__left-section {
    flex: 2.1;
  }
  .br-inventory-request-details__content__right-section {
    flex: 0.9;
  }
}

.br-inventory-request-details__product-image {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 7px;
  border: 1px solid @gray-200;
  border-radius: 4px;
  margin-right: 12px;
}

.br-inventory-request-details__product-name {
  color: @text-teal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.br-inventory-request-details__product-sku {
  color: @text-gray;
}

.br-inventory-request-details__content__right-section,
.br-inventory-request-details__content__left-section {
  .heading {
    display: block;
  }
  height: fit-content;
  background-color: @white;
}

.br-inventory-request-details__content__left-section {
  margin-right: 24px;
  .ant-table,
  .br-table-component.br-table-component__no-selection {
    border-radius: 0 0 8px 8px;
  }
  .br-table.ant-table-wrapper {
    border-radius: 8px;
    box-shadow: none;
  }
}

.br-inventory-request-details__content__right-section,
.br-inventory-request-details__content__left-section > .heading {
  padding: 24px;
}

.br-inventory-request-details__content__right-section {
  .heading {
    margin-bottom: 20px;
  }
  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
}

.br-inventory-request-details__content__left-section {
  .ant-input-group-addon {
    padding: 0;
    width: 24px;
    border-radius: 0px 4px 4px 0px;
    border-color: @gray-200;
  }
  .ant-input-wrapper,
  .ant-input {
    height: 36px;
  }
}

.br-inventory-request-details__quantity-column {
  flex-direction: column;
  .anticon {
    background: @gray-50;
  }
  .anticon:first-child {
    transform: rotate(180deg);
    border-top: 1px solid @gray-200;
    border-bottom-left-radius: 4px;
  }
  .anticon:last-child {
    border-bottom-right-radius: 4px;
  }
}

.br-inventory-request-details__quantity-column__disabled {
  .anticon {
    background: @bg-gray-light;
    svg path {
      fill: @icon-disabled;
    }
  }
}

.br-inventory-request-details__differnet-quantity {
  color: @yellow-600;
}

.br-inventory-request-details__select-icon {
  margin-right: 8px;
}

@media only screen and (max-width: @md) {
  .br-inventory-request-details {
    display: block;
  }

  .br-inventory-request-details__content {
    flex-direction: column-reverse;
  }

  .br-inventory-request-details .ant-spin-nested-loading,
  .br-inventory-request-details__content__left-section,
  .br-inventory-request-details__content__right-section {
    width: 100%;
  }

  .br-inventory-request-details__content__left-section {
    margin-top: 24px;
  }
}
