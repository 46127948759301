@import 'styles/variables';

.br-international-pickups__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
}

.br-international-pickup-assign__modal {
  .br-dropdown-modal_subtitle {
    margin: 0;
    .font({.body-medium()});
  }
}

.br-international-shipping-search__container {
  border: 1px solid @gray-300;
  border-radius: 8px;
  box-shadow: @shadow-sm;

  .ant-picker {
    height: 36px;
  }
}

.br-international-shipping-search__title {
  padding: 16px;
  border-bottom: 1px solid @gray-300;
}

.br-international-shipping-search__content {
  padding: 16px;
}

.br-international-shipping-search__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .ant-row.ant-form-item {
    max-width: 50%;
  }
}

.br-international-shipping-search__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.br-international-pickup__unassign-action {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: @icon-default;
    }
  }
}

.br-international-pickups__puid {
  color: @text-teal;
  cursor: pointer;
}
