@import 'styles/variables.less';

.br-settings-system__toggle-container {
  border: 1px solid @border-color-23;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.br-settings-system__toggle-container > div {
  padding: 24px;
}

.br-settings-system__toggle-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  & span {
    font-family: @lato-font-700;
    margin-bottom: 0;
    font-size: 14px;
  }
}

.br-settings-system__subtitle {
  font-family: @lato-font-400;
  font-size: 12px;
  max-width: 60%;
  line-height: 20px;
}

.br-settings-system__cod__title {
  font-family: @poppins-font-600;
  font-size: 13px;
  color: #374151;
}

.br-settings-system__default-toggle.ant-btn span {
  font-size: 14px;
  font-family: @lato-font-400;
  font-weight: 600;
}

.br-settings-system__active-toggle.ant-btn,
.br-settings-system__active-toggle.ant-btn:hover,
.br-settings-system__active-toggle.ant-btn:focus {
  color: @text-color-27;
  border-color: @border-color-15;
}

.br-settings-system__toggle-container .br-settings-system__activated-cod,
.br-settings-system__toggle-container .br-settings-system__time-slots {
  border-top: 1px solid @border-color-23;
  padding-top: 16px;
  padding-bottom: 16px;
  & img {
    margin-right: 10px;
  }
  & .br-settings-system__fawry-hint {
    font-family: @lato-font-400;
    font-size: 12px;
  }
}

.br-settings-system__default-toggle span {
  margin-left: 10px;
}

.br-settings-system__default-toggle.ant-btn:hover svg path {
  fill: @text-color-29;
}

.br-settings-system__time-slots-table .ant-descriptions-item-container {
  flex-direction: column;
}

.br-settings-system__time-slots-table .ant-descriptions-row {
  display: flex;
  justify-content: space-between;
}

.br-settings-system__time-slots-table .ant-descriptions-item-label {
  font-family: @lato-font-700;
  color: #9ca3af;
  font-size: 12px;
}

.br-settings-system__time-slots-table .ant-descriptions-item-content {
  font-family: @lato-font-700;
  color: @text-color-49;
  font-size: 14px;
}

.br-settings-system__time-slots-divider.ant-divider {
  margin: 8px 0;
}

.br-settings-system__toggle-container .br-settings-system__time-slots {
  padding: 24px 0;
}

.br-settings-system__time-slots-table {
  padding: 0 24px;
}

.br-settings-system__pickups {
  & .ant-input-number-handler-wrap {
    opacity: 1;
  }
}

.br-settings-system__pickups-container {
  & .ant-btn:not(.ant-btn-circle) {
    justify-content: flex-end;
  }
}

.br-settings-system__pickups-save {
  margin-top: 24px;
}
