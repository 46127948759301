@import 'styles/variables.less';

.br-customer-details_create-pickup {
  display: flex;
  justify-content: space-between;
}

.br-customer-details_create-pickup__button {
  justify-content: flex-end;
}

.br-request-pickup__skip-note.ant-tag {
  color: @text-color-31;
  font-family: @lato-font-400;
  font-size: 13px;
  font-weight: normal;
  background: @tag-light-yellow-background-color;
  border-radius: 4px;
  border: 1px solid @tag-light-yellow-text-color;
  padding: 3px 10px;
}

.br-new-order-card__content .br-request-pickup__skip-note {
  margin-bottom: 10px;
  white-space: normal;
}

.br-customer-details_create-pickup .ant-checkbox-wrapper > span {
  font-size: 13px;
}

.br-pickups__pickup-date {
  color: @gray-500;
  margin: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
