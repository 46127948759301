@import 'styles/variables';

.br-inventory-container {
  padding: 41px 24px;
}

.br-inventory__business-selection-container {
  margin-bottom: 20px;
  .br-inventory__business-header {
    color: @gray-800;
    font-family: @poppins-font-600;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .br-inventory__business-selector {
    width: 300px;
  }
  .ant-select-clear {
    display: flex;
    align-items: center;
  }
}

// -------- TABLE --------
.br-inventory-container table tbody > tr,
.br-inventory-container table thead > tr {
  td {
    border: 0px;
    border-bottom: 1px solid @border-color-17;
  }
  cursor: auto;
  .ant-table-selection-column {
    padding: 16px;
  }
  // Select the last 4 children, f(1) = -1 + 4 = 3, ..., f(4) = -4 + 4 = 0
  > td:nth-last-child(-n + 4),
  th:nth-last-child(-n + 4) {
    text-align: center;
  }
  > td:nth-child(2),
  td:nth-child(3) {
    color: @teal-500;
    cursor: pointer;
  }
  > td .br-inventory-table__action {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button,
    span {
      padding: 0px;
      width: 100%;
    }
    svg {
      margin: 0px;
      width: 60%;
      height: fit-content;
    }
  }
}

.br-inventory-container table thead.ant-table-thead tr th.ant-table-cell {
  border-radius: 0px;
  background-color: @gray-25;
  border-bottom: 1px solid @gray-300;
}

.br-inventory-table__negative-quantity {
  color: @red-600;
}

.br-inventory-container table .br-inventory-table__category-cell {
  padding: 1px 10px 2px;
  width: 60px;
  height: 19px;
  font-size: 12px;
  border-radius: 10px;
  background-color: @gray-200;
  font-family: @poppins-font-600;
}

.br-inventory-table__quantity-tooltip {
  padding: 0px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  > svg {
    margin-left: 2px;
    path {
      fill: @gray-400;
    }
  }
}

.br-inventory-container .br-inventory-table__quantity {
  display: flex;
  justify-content: center;
  div.ant-tooltip-inner {
    font-size: 12px;
  }
}

.br-inventory-container table .ant-dropdown {
  width: 200px;
  .ant-dropdown-menu-title-content {
    white-space: nowrap;
  }
}
