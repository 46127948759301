@import 'styles/variables.less';

.br-balance-receipt .ant-modal .ant-modal-content {
  width: 800px;
  height: 740px;
}

.br-balance-receipt .ant-modal .ant-modal-body {
  height: 100%;
}

.br-balance-receipt__close-icon {
  font-size: 17px;
}

.br-balance-receipt__header-divider.ant-divider-horizontal {
  margin-top: 12px;
}

.br-balance-receipt__row > *:nth-child(1) {
  flex: 0.65;
}

.br-balance-receipt__row > *:nth-child(2) {
  flex: 0.35;
}

.br-balance-receipt__row {
  align-items: flex-start;
  margin-bottom: 25px;
}

.br-balance-receipt__label,
.br-balance-receipt__title {
  font-family: @poppins-font-500;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.br-balance-receipt__title {
  font-size: 24px;
}

.br-balance-receipt__value {
  font-family: @lato-font-400;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.br-balance-receipt__amount {
  font-family: @poppins-font-500;
  font-size: 24px;
  line-height: 29px;
  color: @text-color-29;
}

.br-balance-receipt__balance {
  margin-bottom: 24px;
}

.br-balance-receipt__balance-box {
  height: 64px;
  width: 160px;
  padding: 12px 18px;
  border-radius: 8px;
  background: @background-color-18;
}

.br-balance-receipt__balance-box:first-child {
  margin-right: 12px;
}

.br-balance-receipt__box {
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid @border-color-18;
}

.br-balance-receipt__box:not(:last-child) {
  margin-bottom: 12px;
}

.br-balance-receipt .ant-divider-horizontal.ant-divider-with-text {
  margin: 0;
}

.br-balance-receipt .ant-divider-horizontal.ant-divider-with-text::after {
  display: none;
}

.br-balance-receipt .ant-divider-inner-text {
  padding-right: 0;
}

.br-balance-receipt__negative {
  color: @text-color-60;
}

.br-balance-receipt__top-up-text {
  font-family: @lato-font-400;
  font-size: 16px;
  line-height: 26px;
}

.br-balance-receipt__view-breakdown-button {
  text-decoration: underline;
}

.br-balance-receipt__box-pickup {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.br-wallet__receipt-card-details {
  p:last-of-type {
    margin-top: 8px;
  }
}
