@import 'styles/variables';

.br-business-info-content-container {
  border-left: 1px solid @gray-200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 344px;

  .br-compensation__business-info-rows {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 12px 16px 0 16px;
  }
}

.br-business-info-container {
  display: flex;
  flex-direction: column;
}

.br-business-info-content {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.br-business-info-content-text {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  & span {
    margin-bottom: 8px;
  }
  & .br-business-info-email-text {
    margin-bottom: 0;
  }
}

.br-business-info-content-value {
  align-items: flex-start;
  & span {
    font-size: 14px;
    color: @gray-500;
  }
}

.br-business-info-content-text-label {
  width: 108px;
  & span {
    font-size: 14px;
    color: @gray-900;
  }
}

.br-business-info__header {
  font-size: 12px;
  color: @gray-400;
  letter-spacing: 0.04em;
}

.br-business-info__amount-container {
  display: flex;
  flex-direction: column;
  background-color: @teal-100;

  padding: 24px 16px;

  & .br-amount-text {
    font-size: 20px;
    color: @gray-900;
    font-family: @poppins-font-600;
  }
}
