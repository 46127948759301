@import 'styles/variables';

.br-is-settings__select-label {
  font-family: @lato-font-700;
  color: @text-color-49;
  padding-right: 16px;
  line-height: 17px;
}

.ant-select.br-is-settings__select.ant-select-single.ant-select-show-arrow {
  width: 110px;
  height: 40px;
  .ant-select-selector {
    min-height: 100%;
    align-items: center;
    font-size: 13px;
    padding: 0 17px 0 12px;
  }
  .ant-select-arrow {
    font-size: 8px;
  }
}

.br-is-settings__card {
  border-radius: 8px;
  padding: 12px 27px;
  border: 1px solid @border-color-23;
  box-shadow: 0px 2px 4px @box-shadow;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    font-family: @poppins-font-600;
  }
  .ant-btn:not(.ant-btn-circle) {
    border-radius: 8px;
    width: 126px;
    height: 40px;
  }
}

.ant-collapse.br-is-settings__collapse {
  border-radius: 8px;
  padding: 0;
  border: 1px solid @border-color-23;
  box-shadow: 0px 2px 4px @box-shadow;
  margin: 16px 0;
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      cursor: default;
      padding: 12px 27px;
      align-items: center;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          font-family: @poppins-font-600;
        }
        .ant-btn:not(.ant-btn-circle) {
          border-radius: 8px;
          width: 58px;
          height: 40px;
        }
      }
      .ant-collapse-arrow {
        display: none;
      }
      .ant-collapse-extra {
        float: none;
      }
    }
    .ant-collapse-content.ant-collapse-content-active {
      border: none;
      background-color: transparent;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.br-is-settings__table {
  margin: auto;
  width: calc(100% - 32px);
  margin-bottom: 8px;
  .br-searchable-component__header {
    display: none;
  }
  .ant-table-content {
    border: none;
  }
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
  }
  .ant-row.ant-form-item {
    min-height: auto;
    .ant-input-number {
      width: 120px;
    }
  }
}

.ant-btn.br-is-settings__table__save-button,
.ant-btn.br-is-settings__table__save-button:hover,
.ant-btn.br-is-settings__table__save-button:focus {
  background-color: transparent;
  border: none;
  color: @text-color-52;
}

.ant-btn.br-is-settings__table__delete-button,
.ant-btn.br-is-settings__table__delete-button:active {
  background-color: transparent;
  border: none;
  color: @text-color-58;
}

.ant-btn.br-is-settings__table__edit-button {
  background-color: transparent;
  border: none;
}
