.br-date-range-modal__button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  & button {
    margin-right: 8px;
  }
}

.br-date-range-modal__error-text {
  color: red;
  margin-top: 3px;
  font-size: 13px;
}

.br-date-range-modal__title {
  font-size: 16px;
}
