@import 'styles/variables';

.br-cashier__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-cashier__header__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & .br-cashier__header-title {
    font-size: 18px;
    font-family: @poppins-font-600;
  }
  & .br-cashier__header-subtitle {
    font-size: 16px;
    color: @gray-700;
  }
}

.br-cashier__filter-summary-container {
  margin-top: 16px;
  margin-bottom: 20px;
  padding: 24px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.br-cashier__filter-content {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  border-bottom: 1px solid @gray-200;
  & button.ant-btn {
    height: 44px;
    padding: 10px 18px;
  }
  & .ant-btn.ant-btn-primary {
    margin-left: 16px;
    padding: 10px 37px;
    background-color: @teal-500;
  }
}

.br-cashier__summary-header {
  display: flex;
  border-right: 1px solid @gray-200;
  margin-top: 28px;
  align-items: center;
  width: fit-content;
  &.br-cashier__summary-header-finance-view{
    margin-top: 0;
  }
}

.br-cashier__summary-icon {
  display: flex;
  align-items: center;
  background-color: @gray-100;
  padding: 14px;
  border-radius: 8px;
  margin-right: 9px;
}

.br-cashier__summary-icon.br-cashier-icon-yellow {
  background-color: @yellow-50;
}

.br-cashier__summary-icon.br-cashier-icon-green {
  background-color: @green-50;
}

.br-cashier__summary-icon.br-cashier-icon-purple {
  background-color: #f4f3ff;
}

.br-cashier__summary-icon.br-cashier-icon-blue {
  background-color: @blue-50;
}

.br-cashier__summary-icon.br-cashier-icon-red {
  background-color: @red-50;
}

.br-cashier__summary-icon.br-cashier-icon-teal {
  background-color: @teal-50;
}

.br-cashier__summary-card-values {
  & .br-cashier__summary-card-values__title {
    color: @gray-500;
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: 12px;
  }
}

.br-cashier__summary-card-values__value-content {
  display: flex;
  flex-direction: row;
  & .br-cashier__summary-card-values__value-content__text {
    font-size: 12px;
    color: @gray-500;
    margin-right: 18px;
    display: flex;
    align-items: center;
    & .br-cashier__card-value {
      margin-left: 4px;
      display: flex;
      align-items: center;
    }
  }
}

.br-cashier__card-value {
  color: @gray-900;
  font-size: 18px;
  font-family: @lato-font-700;
  display: flex;
  align-items: center;
  gap: 8px;
  & .br-cashier-summary-cards-tag.green {
    background-color: @green-50;
    color: @green-700;
  }
    & .br-cashier-summary-cards-tag.blue {
      background-color: @blue-50;
      color: @blue-700;
    }
}

.br-cashier__summary-content {
  display: flex;
}

.br-cashuer__summary-status-container {
  margin-left: 8px;
  display: flex;
  & .br-cashier__summary-header {
    border: 0;
    padding-right: 30px;
  }
}

.br-cashier__star-column-container {
  display: flex;
  flex-direction: column;
  & .br-cashier__star-column-name {
    color: @teal-500;
    cursor: pointer;
    &.finance{
      cursor:auto;
    }
  }
  & .br-cashier__star-column-phone {
    color: @gray-500;
    font-size: 13px;
  }
}

.br-cashier-container .br-table .ant-table .ant-table-body .ant-table-cell {
  color: @text-color-47;
}

.br-cashier-container .br-table__header .ant-btn.ant-btn-primary {
  background-color: @teal-500;
}

.br-cashier-container .br-table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @teal-500;
}

.br-cashier__state-column-container {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 16px;
  width: fit-content;
  &.state-upper-case{
    & > span{
      text-transform: uppercase;
    }
  }
  
}

.br-cashier__state-column-container.red {
  background-color: @red-100;
  color: @red-900;
  & svg {
    margin-left: 4px;
    cursor: pointer;
    & path {
      fill: @red-700;
    }
  }
}

.br-cashier__state-column-container.yellow {
  background-color: @yellow-100;
  color: @yellow-900;
}

.br-cashier__state-column-container.blue {
  background-color: @blue-100;
  color: @blue-900;
}

.br-cashier__state-column-container.green {
  background-color: @green-100;
  color: @green-900;
  & svg {
    margin-left: 4px;
    & path {
      fill: @green-500;
    }
  }
}

.br-cashier__actions-container {
  display: flex;
  justify-content: flex-end;
  & .br-cashier__reject-button{
    border-color: @red-300;
    & svg path{
      fill: @red-700;
    }
  }

  & .ant-btn.primary{
    margin: 0 8px;
  }

  & .ant-btn.ant-btn-primary {
    background-color: @teal-500;
  }
}

///////////////////

.br-analytics-filter {
  display: flex;
  justify-content: flex-end;
  position: relative;

  & > .ant-btn {
    padding: 6px;
    margin-left: 8px;
  }

  & > button.ant-btn:last-of-type {
    margin: 0;
  }

  & .ant-btn:not(:last-child) {
    margin-right: 8px;
    margin-left: 8px;
  }

  & .ant-btn:not(.ant-btn-circle) {
    height: 36px;
  }
}

.br-analytics-filter__types-dropdown,
.br-analytics-filter__cities-dropdown {
  width: 75%;
}

.br-analytics-filter__cities-dropdown.ant-dropdown {
  border-radius: 2px;
  max-width: 239px;
}

.br-analytics-filter__cities-header,
.br-analytics-filter__type-header {
  background: @background-color-5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid @border-color-20;

  & .ant-btn {
    width: 52px;
  }

  & > span {
    color: @text-color-31;
    font-size: 14px;
  }
}

.br-analytics-filter__cities-dropdown .ant-menu-vertical {
  & .ant-menu-item.ant-menu-item-only-child {
    margin: 0;
    padding: 0 12px;
    height: 100%;
    padding-bottom: 8px;
  }

  & .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active,
  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    color: @text-color;
    background-color: white;
  }
}

.br-analytics-filter__cities-dropdown-title {
  margin-left: 7px;
}

.ant-btn.ant-dropdown-trigger.br-analytics-filter__cities-button {
  & .anticon.anticon-down {
    display: none;
  }
}

.br-star-logs__filter-menu-content {
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  & .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    & label:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.ant-btn.ant-dropdown-trigger.br-analytics-filter__cities-button {
  margin-left: 8px;
  margin-right: 0;
}

.br-star-logs__filter-component-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .br-analytics-filter__cities-button {
    margin-right: 8px;
  }
}

.br-cashier-table__receipt-no-text{
  color: @teal-500;
  cursor: pointer;
}

.br-orders-table__print-selected-rows-container {
  display: flex;
  align-items: center;
  margin-right: 66px;

  .ant-btn {
    border-left: unset;
    border-radius: unset;
  }

  .ant-btn.br-orders-table__title__delete-btn.br-orders-table__title__delete-btn__with-printing-options {
    border-radius: unset;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background-color: unset;
    color: unset;
    border-color: @gray-300;
  }

  & .ant-btn.ant-dropdown-trigger.br-orders-table__printing-options {
    max-width: 36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    &>svg {
      margin: 0;
    }
  }

  & .ant-btn.ant-dropdown-trigger.br-orders-table__printing-options:is(:hover, :focus) {
    border-color: @gray-300;
  }
  & .ant-btn.br-orders-table__title__delete-btn-two {
    border: 1px solid @gray-300;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  & .ant-btn.br-orders-table__title__delete-btn {
    border: 1px solid @gray-300;
    border-radius: 0;
  }
}

.br-orders-table__title-count {
  display: flex;
  align-items: center;
  max-height: 34px;

  .ant-checkbox {
    margin-right: 14px;
  }

  padding: 10px;
border: 1px solid @gray-300;
border-radius: 4px 0px 0px 4px;
border-right: 0;
}



.br-cashier__liability-amount{
  color: @red-600;
}

.br-cashier__collected-amount{
  color: @green-600;
}

.br-fincnce-remaining-amount-green{
  color: @green-700;
}

.br-fincnce-remaining-amount-red {
  color: @red-700;
}

.br-cashier__cashier-date-action-container{
  display: flex;
  align-items: center;
  gap: 16px;
  & button.ant-btn{
    min-height: 36px;
  }
  & .br-signature__container{
    display: flex;
    gap: 8px;
  }
}