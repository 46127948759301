@import 'styles/variables';

.br-packages-debrief__rto-tab__view-details {
    .ant-drawer-content-wrapper {
      width: 820px !important;
      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }
  }
  
  .br-packages-debrief__rto-tab__view-details__title {
    font-size: 24px;
    font-family: @lato-font-700;
    margin-top: 10px;
  }
  
  .br-packages-debrief__rto-tab__view-details__cards {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    .ant-card.ant-card-bordered {
      flex: 1;
      height: 216px;
      border-radius: 10px;
      & , .ant-card-head {
        border-color: @border-color-3;
      }
  
      .ant-card-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 158px;
        .br-image {
          img {
            max-height: 110px;
          }
          svg {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
  
  .br-packages-debrief__rto-tab__view-details__cards__orders-counts {
    &.ant-card.ant-card-bordered {
      .ant-card-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;
        
        & div div:first-child {
          margin-bottom: 5px;
        }

        & span {
          display: block;
          text-align: center;
        }
      }
    }
  }

  .br-packages-debrief__rto-tab__view-details__tabs__extra-content {
      display: flex;
      align-items: center;
      gap: 18px;
  }

  .br-packages-debrief__rto-tab__view-details__tabs {
    .br-image svg {
      width: 30px;
      height: 30px;
    }
  }

  .br-packages-debrief__rto-tab__view-details__tabs__link {
    text-decoration: underline;
  }