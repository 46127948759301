.inputGroup {
  width: 15%;
}

.divInput {
  display: flex;
  justify-content: flex-end;
}

.SearchToggleClosed {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before{
    content: "\25BA";
    margin-right: 0.5rem;
  }
}

.SearchToggleOpen {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before{
    content: "\25BC";
    margin-right: 0.5rem;
  }
}

.pin_icon {
  width: auto;
  height: auto;
  float: left;
  margin-right: 10px;
}

.pin_icon img {
  float: left;
}

.SearchToggleClosed {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before {
    content: '\25BA';
    margin-right: 0.5rem;
  }
}

.SearchToggleOpen {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before {
    content: '\25BC';
    margin-right: 0.5rem;
  }
}

.formWidth {
  margin-bottom: 30px;
}

.formWidth {
  div {
    justify-content: left;
  }
}

.formLabel {
  margin-bottom: 0.5rem;
}

.formRowSearchButton{
  flex-direction: row-reverse;
}

.formRow{
  margin-top: 1rem;
}

.tableRoute{
  margin-top:5rem;
}