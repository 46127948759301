.br-ellipsis-cell {
  display: grid;
  place-items: stretch;
}

.br-ellipsis-cell__container {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
}
