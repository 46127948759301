.divCreate {
  display: flex;
  justify-content: flex-end;
}

.inputGroup {
  width: 30%;
  margin-bottom: 20px;
}

.divHub {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.inputGroupCSV {
  width: 15%;
  margin-bottom: 20px;
  margin-left: 20px;
}

.multiSelect > div {
  border: 1px solid #e4e7ea;
}

.multiSelect > div:hover {
  border: 1px solid #e4e7ea;
}

.multiSelect > div:focus {
  border: 1px solid #8ad4ee;
}

.invalid div:first-child{
  border-color: #f86c6b;
}
.invalid div:first-child:hover {
  border: 1px solid #f86c6b;
}

.formRow {
  justify-content: left !important;
}

.formLabel {
  white-space: nowrap;
  max-width: none;
}

.SearchToggleClosed {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before{
    content: "\25BA";
    margin-right: 0.5rem;
  }
}
.SearchToggleOpen {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before{
    content: "\25BC";
    margin-right: 0.5rem;
  }
}