@import 'styles/variables';

.br-dispatching__cards-container {
  display: flex;
  justify-content: space-between;
}

.br-dispatching__content {
  margin-top: 16px;
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-dispatching__content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.br-dispatching__content-title {
  font-family: @poppins-font-600;
  font-size: 18px;
  color: @gray-700;
}

.br-dispatching-hubs-filter {
  & > .ant-select.ant-select-single:not(.ant-select-customize-input) {
    min-width: 200px;

    & .ant-select-selector {
      border: 1px solid @gray-300;
      box-shadow: @shadow-xs;
      border-radius: 8px;
    }
  }
}

.br-dispatching__search {
  padding: 24px;
  display: flex;
  justify-content: space-between;

  & > .ant-form {
    min-width: 90%;
  }

  & .ant-row.ant-form-item {
    min-height: 100%;

    & .ant-input-affix-wrapper {
      padding: 0 12px;
      border-radius: 4px;
      min-height: 36px;
    }
  }

  & > .ant-btn {
    min-height: 36px;
    border-radius: 4px;

    svg {
      fill: none;
    }
  }
}

.br-dispatching__table-container {
  & .br-table-component {
    border-radius: 0;

    & .br-table__title {
      font-family: @lato-font-700;
    }

    & .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
      background-color: @teal-500;
      padding-top: 2px;
    }

    & .ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-btn {
      color: @gray-500;
    }

    &
      .ant-tabs-top
      > .ant-tabs-nav
      .ant-tabs-tab.ant-tabs-tab-active
      .ant-tabs-tab-btn {
      color: @teal-500;
    }
  }
}

.br-dispatching__container {
  a {
    color: @teal-500;
  }
}

.br-dispatching__table-shelf {
  color: @gray-400;
}

.br-dispatching__issues-tooltip {
  & .ant-tooltip-inner {
    background-color: @gray-800;
  }
}

.br-dispatching__issues-tooltip-text {
  width: fit-content;
  border-bottom: 1px dashed @gray-300;
}

.br-dispatching__issues-tag.ant-tag {
  border-radius: 10px;
  border: 1px solid @gray-100;
  background-color: @gray-100;
}

.br-dispatching__container {
  & .ant-pagination {
    .anticon {
      vertical-align: middle;
    }
    .ant-pagination-prev {
      min-width: 32px;
    }
  }
}

.br-dispatching__table-delayed-row {
  background-color: @red-50;
}

.br-dispatching__container {
  & .br-table__quick-filter-tabs {
    margin-bottom: 0;
  }
  & .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
}

.br-dispatching__table-actions {
  & > .ant-btn {
    margin-right: 10px;
  }
}

.br-dispatching__export-button.ant-btn:not(.ant-btn-circle) {
  border-radius: 8px;
  min-height: 40px;

  & svg {
    margin-right: 6px;
  }
}
