@import 'styles/variables';

.ant-tag.br-tag {
  height: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.ant-tag.br-tag {
  .br-tag-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.ant-tag.br-tag {
  &.br-tag-gray {
    background-color: @gray-100;
    border-color: @gray-100;
    color: @text-gray;

    .br-tag-dot {
      background-color: @text-gray;
    }
  }

  &.br-tag-teal {
    background-color: @teal-100;
    border-color: @teal-100;
    color: @teal-700;

    .br-tag-dot {
      background-color: @teal-700;
    }
  }

  &.br-tag-blue {
    background-color: @blue-100;
    border-color: @blue-100;
    color: @text-blue-dark;

    .br-tag-dot {
      background-color: @text-blue-dark;
    }
  }

  &.br-tag-green {
    background-color: @green-100;
    border-color: @green-100;
    color: @text-green-dark;

    .br-tag-dot {
      background-color: @green-700;
    }
  }

  &.br-tag-red {
    background-color: @red-100;
    border-color: @red-100;
    color: @text-red-dark;

    .br-tag-dot {
      background-color: @red-700;
    }
  }

  &.br-tag-purple {
    background-color: @purple-100;
    border-color: @purple-100;
    color: @purple-900;

    .br-tag-dot {
      background-color: @purple-900;
    }
  }

  &.br-tag-yellow {
    background-color: @yellow-100;
    border-color: @yellow-100;
    color: @yellow-900;
    .br-tag-dot {
      background-color: @icon-yellow-dark;
    }
  }

  &.br-tag-black {
    background-color: @black;
    border-color: @black;
    color: @white;
    .br-tag-dot {
      background-color: @white;
    }
  }
}
