.br-assign-pickup__modal {
  .ant-modal-body {
    padding: 0;
    min-height: 120px;
  }

  .ant-tabs-content-holder {
    padding: 0 24px;

    .ant-select {
      width: 100%;
    }
  }
}
