@import 'styles/variables';

.br-exceptions-tab__swapping-tables .ant-table-container {
  border-radius: 4px;
  border: 1px solid rgb(226, 232, 240);
}

.br-exception-tab .br-swapping-tables {
  & > div {
    &:first-child {
      width: 59%;
    }
    &:last-child {
      width: 41%;
    }
  }

  .ant-table-container p {
    white-space: pre-wrap;
    margin-bottom: 0;
  }
}

.vertically-centered {
  display: flex;
  align-items: center;
}

.br-exception-tab__form {
  display: flex;
  width: calc(50% - 10px);
}

.br-exception-tab__form .ant-select {
  width: 100%;
}

.br-exception-tab__form-label {
  height: 20px;
  font-family: @lato-font-700;
  font-size: 13px;
}

.br-exceptions-tab__swapping-tables {
  display: flex;
  width: 100%;
}

.br-exceptions-tab__swapping-tables span {
  flex: 1;
}

.br-exceptions-tab__swapping-tables .ant-table-row {
  vertical-align: baseline;
}

.br-exceptions-tab__swapping-tables .ant-table .ant-table-cell {
  font-size: 13px;
}

.br-exceptions-tab__reason {
  width: 197px;
}

.br-exceptions-tab__date {
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  padding: 2px 10px;
  color: @white;
  width: max-content;
}

.br-exceptions-tab__exceptions-table {
  margin-right: 20px;
}

.br-exception-tab__tracking-number-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}

.justify-content-space-between {
  justify-content: space-between;
}

.br-exceptions-tab__swapping-tables__right-side {
  display: flex;
  flex-direction: column;
}

.br-exceptions-tab__action {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.br-exceptions-tab__reschedule {
  color: @text-color-54;
  background-color: @background-color-61;
}

.br-exceptions-tab__reschedule-tomorrow {
  color: @text-color-53;
  background-color: @background-color-60;
}

@media only screen and (max-width: 992px) {
  .br-exceptions-tab__swapping-tables .br-searchable-table,
  .br-exception-tab__form {
    width: 100%;
  }

  .br-exceptions-tab__swapping-tables {
    flex-direction: column;
  }
}
