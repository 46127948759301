@import 'styles/variables';

.br-international-orders__container {
  padding: 0;

  .br-table__quick-filter-tabs {
    margin-bottom: 16px;
  }
}

.br-international-delivery__column-subtext {
  color: @text-gray;
}

.br-international-order__tracking-number {
  color: @text-teal;
  cursor: pointer;
}

.br-international-order__destination-tracking-number {
  text-decoration: underline;
  svg {
    transform: rotate(270deg);
    path {
      stroke: @text-teal;
    }
  }
  .br-international-order__tracking-number;
}

.br-international-table-selected-rows-container {
  display: flex;
  align-items: center;
  margin-right: 66px;

  .ant-btn {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-btn:not(.ant-btn-circle) {
    border-left: unset;
    border-radius: unset;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background-color: unset;
    color: unset;
    border-color: @gray-300;
  }
}

.br-international-table__title-count {
  display: flex;
  align-items: center;
  max-height: 34px;
  padding: 10px;
  border: 1px solid @gray-300;
  border-radius: 4px 0px 0px 4px;

  .ant-checkbox {
    margin-right: 14px;
  }

  .font({.button()});
}
