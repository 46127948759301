@import 'styles/variables';

.br-dispatching-overview__title {
  font-family: @poppins-font-600;
  font-size: 18px;
  color: @gray-700;
}

.br-dispatching-overview__hub-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 22px;
  gap: 17px;
}
