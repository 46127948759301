@import 'styles/variables';

.br-orders-mass-upload .ant-alert-message {
  font-family: @poppins-font-500;
  font-size: 16px;
}

.br-mass__upload__error-file {
  display: flex;
  align-items: center;
  background-color: @background-color-5;
  padding: 20px 42px;
  margin-top: 20px;
}

.br-mass__upload__error-file img {
  height: 47px;
}

.br-mass__upload__error-file__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.br-mass__upload__error-file__info p {
  margin-bottom: 0;
  font-size: 14px;
  color: @text-color-31;
}

.br-mass__upload__error-file__info
  .br-mass__upload__error-file__info__file-name {
  font-size: 18px;
  color: unset;
}

.br-mass-upload__error-file__options {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-bottom: 16px;
  & button {
    margin-right: 8px;
    padding: 10px;
  }
}

.br-mass-upload__error-file__issues-found-text {
  margin-bottom: 20px;
  color: @text-color-38;
  font-size: 16px;
  font-family: @poppins-font-600;
  font-weight: 600;
}

.br-mass-upload__error-file__table-title {
  padding: 10px 15px;
  background-color: @background-color-19;
  color: @text-color;
  font-family: @poppins-font-500;
  font-size: 16px;
}

.br-mass-upload__errors-table {
  padding-bottom: 20px;
}

.br-mass-upload__errors-table .ant-table {
  width: 100%;
}

.br-mass-upload__errors-table .ant-table-row {
  cursor: auto;
}

.br-mass-upload__errors-table .ant-table-container .ant-table-thead > tr > th {
  border-bottom: 3px solid #e2e8f0;
  font-size: 15px;
  font-family: @poppins-font-600;
}

.br-orders-mass-upload .ant-popover-inner-content {
  max-height: 152px;
  max-width: 345px;
  overflow: auto;
  overflow-x: hidden;
}

.br-orders-mass-upload .ant-popover-inner {
  padding: 5px;
  border-radius: 8px;
}

.br-orders-mass-upload .ant-popover-inner .ant-popover-inner-content {
  padding: 7px 11px;
}

.br-orders-mass-upload .ant-popover-inner ::-webkit-scrollbar {
  width: 4px;
}

.br-orders-mass-upload .ant-popover-inner ::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: @scrollbar-thumb-background-color;
}

.br-orders-mass-upload .ant-popover-inner ::-webkit-scrollbar-track {
  background-color: @scrollbar-track-background-color;
}

@media only screen and (max-width: 600px) {
  .br-mass__upload__error-file {
    padding: 10px 5px 10px 10px;
    margin-top: 0;
  }

  .br-mass__upload__error-file img {
    height: 30px;
  }

  .br-mass__upload__error-file__info {
    margin-left: 10px;
  }

  .br-mass__upload__error-file__info p {
    font-size: 10px;
  }

  .br-mass__upload__error-file__info
    .br-mass__upload__error-file__info__file-name {
    font-size: 10px;
  }

  .br-content-header-with-sub-title.br-mass-upload__title {
    margin-bottom: 10px;
  }

  .br-content-header-with-sub-title.br-mass-upload__title
    .br-content-header__title {
    font-size: 16px;
  }
  .br-content-header-with-sub-title.br-mass-upload__title
    .br-content-header__sub-title {
    font-size: 12px;
  }

  .br-mass-upload__error-file__options {
    margin: 5px 0 10px 0;
    & button {
      margin-right: 8px;
      padding: 4px;
      height: 10px;
      & span {
        font-size: 10px;
      }
    }
  }
  .br-orders-mass-upload .ant-alert-message {
    font-size: 12px;
  }
  .br-mass-upload__error-file__issues-found-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .br-mass-upload__errors-table
    .ant-table-container
    .ant-table-thead
    > tr
    > th {
    border-bottom: 3px solid #e2e8f0;
    font-size: 12px;
    padding: 5px;
  }

  .br-mass-upload__errors-table
    .ant-table-container
    .ant-table-tbody
    > tr
    > td {
    font-size: 12px;
    padding: 5px;
  }
}
