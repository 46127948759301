@import 'styles/variables.less';
.br-form-count__label-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 6px;
  align-items: center;
}

.br-form-count__optional-label {
  color: @text-color-32;
  font-size: 10px;
  font-family: @lato-font-400;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  margin-left: 3px;
  background: @border-color-18;
  border-radius: 2px;
  padding: 2px 3px;
}

.br-form-count__optional-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.br-form-count__label {
  color: @text-color;
  font-size: 14px;
  font-family: @lato-font-700;
  display: flex;
  align-items: center;
}

.br-form-count__count-label {
  color: @text-color;
  font-size: 10px;
  font-weight: normal;
}

@media only screen and (max-width: 425px) {
  .br-form-count__label-container {
    height: unset;
  }
}
