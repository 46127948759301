.br-routes-search__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ant-btn {
    min-width: 160px;
  }
}
