@import 'styles/variables';

.carousel-container {
  position: relative;
  width: 100%;
  height: 450px;
}

.br-images-carousel__footer-wrapper {
  width: 100%;
  height: 68px;
  background: #00000080;
  position: relative;
  bottom: 15%;
}

.carousel-wrapper {
  top: 90%;
  position: relative;
  margin: 0 auto;
  width: 65%;
}

.custom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: @white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
}

.carousel-container .br-images-carousel__single-image {
  width: 100%;
  height: 450px;
}

.custom-arrow-prev {
  left: 10px;
}

.custom-arrow-next {
  right: 10px;
}

.slide-info-container {
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: @white;
  padding: 5px 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
