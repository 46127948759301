.br-signin__verify-user {
  display: flex;
  align-items: center;
  padding: 100px 35px
}

.br-signin__verify-user > img {
  width: 50%;
  margin-inline-end: 80px;
}

.br-signin__verify-user__content {
  flex-direction: column;
  align-items: center;
}

.br-signin__pin-code-modal__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.br-signin__pin-code-modal__footer .ant-btn:first-child {
  margin-inline-end: 10px;
}

@media only screen and (max-width: 992px) {
  .br-signin__verify-user {
    flex-direction: column-reverse;
  }
}
