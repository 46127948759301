@import 'styles/variables.less';

.br-zoning-filters__container.ant-collapse {
  margin-top: 20px;
}

.br-zoning-filters__group {
  padding-bottom: 12px;
  border-bottom: 1px solid @gray-200;
}

.br-zoning-filters__group-title {
  margin: 20px 0;
}

.br-zoning-filters__group-row {
  display: flex;
  align-items: center;
}

.br-zoning-filters__coverage {
  max-width: 200px;

  .ant-select {
    width: 100%;
  }

  .ant-form-item-control-input-content,
  .ant-row.ant-form-item {
    min-height: auto;
  }

  > .br-zoning-filters__group-row {
    margin-bottom: 10px;
  }
}

.br-zoning-filters__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
