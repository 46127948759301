@import 'styles/variables.less';

.br-banner-content__parent {
  // padding: 20px;
  width: 100%;
}

.br-banner-content.br-banner-warning {
  border: 1px solid @yellow-200;
  background-color: @yellow-50;
  .br-banner-content__text-container > svg path {
    fill: @icon-yellow-dark;
  }
}

.br-banner-content.br-banner-danger {
  border: 1px solid @border-color-15;
  background-color: @tag-red-background-color;
  .br-banner-content__text-container > svg path {
    fill: @icon-red-dark;
  }
}

.br-banner-content.br-banner-success {
  border: 1px solid @border-color-16;
  background-color: @tag-green-background-color;
  .br-banner-content__text-container > svg path {
    fill: @icon-green;
  }
}

.br-banner-content.br-banner-info {
  background-color: @gray-50;
  border: 1px solid @gray-200;
  .br-banner-content__text-container > svg path {
    fill: @icon-default;
  }
}

.br-banner-content__parent .br-banner-content {
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  & .br-banner-content__dismiss-icon {
    position: absolute;
    right: 12px;
    & svg {
      font-size: 14px;
      fill: @text-color-31;
    }
  }
  & .br-banner-content__button {
    display: flex;
    align-items: center;
  }
}

.br-banner-content__parent
  .br-banner-content
  .br-banner-content__button
  button {
  padding: 8px 6px;
  margin-right: 27px;
}

.br-banner-content__parent
  .br-banner-content
  .br-banner-content__button.br-banner-content__button-text
  button {
  padding: 8px 6px;
  color: @tag-light-yellow-text-color;
  background-color: transparent;
  margin-right: 15px;
  border: 0;
}

.br-banner-content__text-container {
  display: flex;
  align-items: flex-start;
  & img,
  svg {
    margin: 0px;
  }
}

.br-banner-content__text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  & p,
  h1 {
    margin: 0;
  }

  & h1 {
    font-size: 16px;
    font-family: @poppins-font-600;
    line-height: 23px;
  }

  & p {
    margin-top: 5px;
  }
}

.br-banner-content__text__dismiss-icon-responsive {
  display: none;
}

.br-banner-content.br-banner-fixed.br-banner-info {
  box-shadow: inset 0px -3px 0px @gray-200;
}
.br-banner-content.br-banner-fixed.br-banner-danger {
  box-shadow: inset 0px -3px 0px @red-200;
}
.br-banner-content.br-banner-fixed.br-banner-success {
  box-shadow: inset 0px -3px 0px @green-200;
}
.br-banner-content.br-banner-fixed.br-banner-warning {
  box-shadow: inset 0px -3px 0px @yellow-200;
}

.br-banner-content.br-banner-fixed {
  .br-banner-content__buttons-container {
    margin: 0px 16px 0px 0px;
  }
  border: 0px;
  border-radius: 0px;
}

@media only screen and (max-width: 768px) {
  .br-banner-content__text__dismiss-icon-responsive {
    color: @tag-light-yellow-text-color;
    margin-left: auto;
    display: flex;
  }

  span.br-banner-content__dismiss-icon {
    display: none;
  }

  .br-banner-content__parent .br-banner-content {
    display: flex;
    flex-direction: column;
  }

  .br-banner-content__button {
    width: 100%;
  }

  .br-banner-content__parent .br-banner-content button {
    margin-top: 10px;
    width: 100%;
  }
}
