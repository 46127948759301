@import 'styles/variables.less';

.br-location-contact-form .ant-form-item-label > label {
  margin-bottom: 0;
}

.br-location-contact-modal.ant-modal {
  width: 620px;
  border-radius: 8px;
}

.br-location-contact-form .ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  margin-bottom: 0;
}

.br-location-contact-form .ant-form-item-label {
  text-align: start;
  margin-bottom: 0;
}

.br-location-contact-form__header {
  display: flex;
  flex-direction: column;
}

.br-location-contact-modal .br-banner-content__parent {
  padding-top: 19px;
  margin-bottom: 15px;
}

.br-location-contact-form .ant-row.ant-form-item:not(:last-child) {
  margin-right: 6px;
}

.br-location-contact-form .ant-input::placeholder,
.br-location-contact-form .ant-select-selection-placeholder {
  font-size: 13px;
}

.br-location-contact-form .ant-form-item-label {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 13px;
  font-weight: 500;
}

.br-location-contact-form .ant-input {
  border-radius: 4px;
}

.br-location-contact-form__header {
  margin-bottom: 22px;
}

.br-location-contact-form__header__title {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 17px;
  font-weight: 600;
}

.br-location-contact-form__header__sub-title {
  color: @text-color;
  font-size: 13px;
}

.br-location-contact-form__footer__with-back {
  margin-top: 28px;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.br-location-contact-form__footer {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.br-location-contact-form__footer__back-button.ant-btn,
.br-location-contact-form__footer__cancel-button.ant-btn {
  height: 34px;
  border: 1px solid @border-color-17;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 @box-shadow;
}

.br-location-contact-form__footer__next-button.ant-btn {
  height: 34px;

  border: 1px solid @border-color-16;
  border-radius: 4px;
  background-color: @background-color-16;
  box-shadow: 0 2px 2px 0 @box-shadow;
}

.br-location-contact-form__footer__right-side-buttons {
  display: flex;
  flex-direction: row;
}

.br-location-contact-form__footer__back-button.ant-btn span,
.br-location-contact-form__footer__cancel-button.ant-btn span,
.br-location-contact-form__footer__next-button.ant-btn span {
  font-family: @lato-font-400;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}

.br-location-contact-form__footer__cancel-button.ant-btn {
  margin-right: 10px;
}

.br-location-contact-form__footer__checkbox.ant-row.ant-form-item {
  height: auto;
  min-height: 0;
}

.br-location-contact-form__footer__checkbox .ant-checkbox + span {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 13px;
}
