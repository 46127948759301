@import 'styles/variables';

.br-order-actions__container {
  border: 1px solid @border-color-23;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;
  min-width: 70%;
}

.br-order-actions__tabs .ant-tabs-nav-wrap {
  padding: 0px 20px;

  & .ant-tabs-tab-btn {
    font-size: 14px;
  }
}

.br-order-actions__container {
  & .br-searchable-component__header {
    padding: 0;
    min-height: 40px;

    & > div {
      width: 100%;
      height: 100%;
    }

    & .br-searchable-component__header__search-input.ant-input-search {
      width: 100%;
      padding: 0 16px;
      border: unset;

      & .ant-input-affix-wrapper {
        border-radius: 8px;
        min-height: 40px;
      }
    }
  }
}

.br-order-actions__container {
  & .selected-row {
    background-color: @teal-100;
  }

  & .ant-table-wrapper.br-searchable-table__listing {
    margin-top: 11px;

    & .ant-table-content {
      border-radius: 0;
    }

    & .ant-table-container table > thead > tr:first-child th {
      background-color: @background-color-61;
      border-radius: 0;
    }

    & .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 0;
    }
  }
}

.br-order-actions__container {
  & .br-searchable-table__listing .ant-tag {
    border-radius: 10px;
    width: auto;
  }
}

.br-order-actions__container {
  & .br-action-center-cta-button .ant-btn:not(.ant-btn-circle) {
    border-radius: 8px;
    min-height: 28px;
    max-height: 100%;
    width: 130px;
    height: auto;
    white-space: normal;
  }
}

.br-order-actions__container {
  & .ant-table-wrapper.br-searchable-table__listing {
    & .ant-table-tbody .ant-table-row {
      cursor: pointer;
    }
  }
}

.br-order-actions-delivery-link {
  text-decoration: underline;
}

.br-order-actions-table-time {
  display: block;
  color: @gray-400;
  font-size: 12px;
}

.br-order-actions__container {
  & .br-searchable-table__listing .ant-table .ant-table-cell {
    padding: 0;
  }

  & .ant-table-column-sorters {
    padding: 0;
    justify-content: flex-start;

    & .ant-table-column-title {
      width: auto;
    }
  }

  & td.ant-table-column-sort {
    background: transparent;
  }
}

.br-order-actions__container {
  & .br-searchable-component__header__export.ant-btn {
    margin-right: 10px;
    min-height: 40px;
    border-radius: 8px;
  }
}

.br-action-center__star-info-popover {
  max-width: 320px;
  & .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-inner {
    border-radius: 8px;
  }
}

.br-action-center__request-actions-container {
  display: flex;
  align-items: center;
  gap: 8px;

  & .ant-btn {
    max-height: 28px;
  }
}
