.br-order-action-modal__title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

.br-order-action-modal__dropdown {
  width: 100%;
}

.br-order-action-modal__footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 14px;
}
