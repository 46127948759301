@import 'styles/variables';

.br-cashier-reject-modal-title-container {
  display: flex;
  flex-direction: column;
}

.br-cashier-reject-modal__title {
  font-size: 18px;
  font-family: @lato-font-700;
}

.br-cashier-reject-modal__subtitle {
  font-size: 13px;
  color: @gray-500;
}

.br-cashier-reject-modal__amount-form-item.ant-row.ant-form-item {
  margin-top: 14px;
}

.br-cashier-rejection-modal-container.ant-modal {
  & .ant-modal-header {
    border-radius: 8px;
  }
  & .ant-modal-footer {
    justify-content: flex-end;
    & button.ant-btn-primary {
      background-color: @teal-500;
    }
  }
}
.br-cashier-rejection-modal-upload-title {
  color: @gray-900;
}

.br-cashier-rejection-modal__click-to-upload-button {
  color: @teal-500;
  cursor: pointer;
}

.br-cashier-rejection-modal__upload-empty-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  & button.ant-btn-primary {
    margin-top: 12px;
    background-color: @teal-500;
  }
}
.br-cashier-rejcet-modal__approve-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & span {
    font-size: 24px;
    font-family: @poppins-font-600;
    color: @teal-500;
    & svg {
      margin-right: 12px;
    }
  }
}
.br-cashier-rejcet-modal__approve-amount-container {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  & span {
    justify-content: space-between;
    font-family: @lato-font-400;
    font-size: 13px;
    color: @gray-500;
  }
}

.br-cashier-rejcet-modal__approve-cod-amount-container {
  display: flex;
  padding: 4px 9px;
  background: @teal-100;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  max-width: 205px;
}
.br-cashier-rejcet-modal__approve-ccod-amount-container {
  display: flex;
  padding: 4px 9px;
  justify-content: space-between;
  background: @gray-100;
  border-radius: 8px;
  width: 100%;
  max-width: 205px;
}

.br-cashier-approve__upload-image-container {
  margin-top: 40px;
}
