@import 'styles/variables.less';

.ant-modal-confirm-btns {
  display: flex;
}

.br-dispatch-rto {
  height: 75vh;
  display: flex;
  column-gap: 24px;

  .br-dispatch-rto_question-circle-icon {
    font-size: 12px;
    margin-left: 5px;
    color: @text-color-54;
  }

  .br-dispatch-rto_side-container {
    border-radius: 8px;
    border: 1px solid @border-color-23;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px @border-color-14;
  }

  .br-dispatch-rto_side-container_left {
    flex: 68%;
  }

  .br-dispatch-rto_side-container_right {
    padding: 20px 25px;
    flex: 32%;
  }

  .br-dispatch-rto_side-container_left_header {
    padding: 20px 30px 0;
  }

  .br-dispatch-rto_side-container_left_header_title {
    margin: 0;
  }

  .br-dispatch-rto_side-container_left_hub-dropdown {
    width: 200px;
    float: right;
  }

  .br-dispatch-rto_side-container_left_header_actions-area {
    margin-top: 10px;
    display: flex;
    gap: 9px;
  }

  .br-dispatch-rto_side-container_left_header_actions-area_input {
    flex: 56%;
  }

  .br-dispatch-rto_side-container_left_header_actions-area_btns {
    flex: 44%;

    .br-dispatch-rto_side-container_left_header_actions-area_btns_export-btn {
      height: 32px;
    }
  }

  .br-dispatch-rto_side-container_left_header_actions-area_btns_export-btn_icon {
    font-size: 18px;
    color: @text-color-12;
  }

  .br-dispatch-rto_side-container_left_header_actions-area_btns_dropdown_label {
    display: inline-block;
    width: 40%;
  }

  .br-dispatch-rto_side-container_left_header_actions-area_btns_dropdown {
    width: 60%;
  }

  .br-dispatch-rto_side-container_left_table {
    margin-top: 20px;

    .ant-table-title {
      font-family: @lato-font-700;
      padding: 0;
      margin-left: 48px;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .ant-table-container {
      border-top: 1px solid @border-color-23;
    }
    .ant-table-thead > tr > th {
      color: unset;
      font-family: @lato-font-700;
      font-size: 16px;
    }
  }

  .br-dispatch-rto_side-container_left_table_age-column-header {
    display: inline-flex;
    align-items: center;
    width: max-content;
    margin: auto;
  }

  .br-dispatch-rto_side-container_left_table_age-column-header_day {
    margin-left: 4px;
    color: @text-color-12;
    font-family: @lato-font-400;
  }

  tr.br-dispatch-rto_side-container_left_table_row-header {
    background: @background-color-63;
    font-family: @lato-font-700;
    color: @text-color-47;
    font-size: 16px;

    &:not(.ant-table-row-selected):hover > td {
      background: unset;
    }
  }

  .br-dispatch-rto_side-container_left_table_unknown-word {
    display: inline-flex;
    align-items: center;
  }

  .br-dispatch-rto_side-container_left_table_unknown-word-colored {
    color: @background-color-48;
  }

  .br-dispatch-rto_side-container_left_table_tag {
    background: @tag-red-background-color;
    color: @text-color-65;
    border-radius: 10px;
    border: unset;
    margin-left: 10px;
    font-size: 12px;
  }

  .br-dispatch-rto_side-container_left_table_empty-img {
    margin: 25px 0 21px 0;
    svg {
      width: 289px;
      height: 121px;
    }
  }

  .br-dispatch-rto_side-container_left_table_empty-text {
    color: @text-color-12;
  }

  .br-dispatch-rto_side-container_right_select-assignee-dropdown,
  .br-dispatch-rto_side-container_right_shelf-route-dropdown {
    width: 100%;
    margin: 5px 0;
  }

  .br-dispatch-rto_side-container_right_shelf-route-text {
    color: @text-color-12;
    line-height: 16.8px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .br-dispatch-rto_side-container_right_tracking-numbers_input {
    margin: 5px 0 10px 0;
  }

  .br-dispatch-rto_side-container_right_tracking-numbers_list.ant-table-wrapper {
    margin-top: 10px;

    .ant-table-title {
      font-family: @lato-font-700;
      padding-top: 0;
    }

    .ant-table-thead th {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .br-dispatch-rto_side-container_right_tracking-numbers_list_item-btn {
    color: transparent;
    margin: auto;

    :hover,
    :active,
    :focus {
      color: transparent;
    }
  }

  .br-dispatch-rto_side-container_right_tracking-numbers_buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .ant-btn {
      height: 30px;
    }
  }

  .br-dispatch-rto_side-container_right_create-new-route-btn.ant-btn {
    padding: 0;
    height: auto;
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.br-dispatch-rto_side-container_right_create-new-route {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
