.br-holiday-settings__container {
  .ant-row.ant-form-item {
    min-height: auto;
    margin-bottom: 8px;
  }

  .ant-picker {
    height: 36px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 20px;
  }
}

.br-holiday-settings__form-row {
  display: flex;
  align-items: center;

  .ant-row.ant-form-item {
    flex: 1;
  }
}

.br-holiday-settings__form-container {
  margin-bottom: 15px;
}

.options_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.red {
  background-color: #ff4d4f;
}
.green {
  background-color: #45a714;
}

.tooltip-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
