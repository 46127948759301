@import 'styles/variables.less';

.br-call-modal .ant-modal {
  width: 455px;
}
.br-call-modal .ant-modal-close {
  display: flex;
}

.br-call-modal .ant-modal-close svg {
  width: 19px;
  height: 16px;
}

.br-call-modal__heading {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.br-call-modal__form__footer {
  display: flex;
  justify-content: flex-end;
}

.br-call-modal__form__footer .ant-btn-primary {
  margin-left: 10px;
}

.br-call-modal__form__footer {
  border-top: unset;
  display: flex;
  justify-content: flex-end;
}

.br-call-modal__form__footer button:first-child {
  border: unset;
}

.br-call-modal__form__footer .ant-btn:not(.ant-btn-circle) {
  padding: 16px 12px;
  height: 38px;
}

.br-call-modal__form {
  margin-top: 32px;
}

.br-call-modal__form__checkbox {
  font-size: 14px;
  font-family: @lato-font-400;
  font-weight: 500;
}

.br-call-modal__form div.br-call-modal__form__textarea {
  margin-bottom: 39px;
}

.br-call-modal__form .ant-form-item:first-child {
  margin-top: 32px;
}
