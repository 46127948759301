@import 'styles/variables';

.br-hubs-performance-align-left {
  justify-content: flex-end;
}

.br-hubs-performance__donut-chart-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  & .br-hubs-performance__donut-chart-inner__value {
    font-family: @poppins-font-600;
    font-size: 40px;
  }
  & .br-hubs-performance__donut-chart-inner__text {
    color: @gray-500;
    font-size: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.br-hubs-performance_donut-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 338px;
  padding: 16px 25px;
}

.br-hubs-performance__donut-chart-legend {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  width: 100%;
}

.br-hubs-performance-donut-chart-legend-content {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.br-hubs-performance__donut-chart-legend-indicator {
  width: 8px;
  height: 4px;
  border-radius: 40px;
  margin-right: 8px;
}

.br-hubs-performance__donut-chart-legend-indicator.top {
  background-color: @green-600;
}

.br-hubs-performance__donut-chart-legend-indicator.good {
  background-color: @yellow-400;
}

.br-hubs-performance__donut-chart-legend-indicator.low {
  background-color: @red-500;
}

.br-hub-performance__higlight-card-container.br-hubs-performance__rating-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-bottom: 0;
  & h1 {
    font-size: 14px;
    color: @gray-600;
    font-weight: bold;
  }

  & .br-hub-performance__higlight__highlight-content {
    display: flex;
    flex-direction: column;
    & .br-hub-performance__highlight-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
    }
    & .br-hub-performance__highlight-content-info {
      background: @gray-50;
      padding: 16px;
      box-shadow:@shadow-sm;
      border-radius: 4px;
      width: 50%;
      margin-right: 16px;
      & h2 {
        font-size: 20px;
        font-weight: 600;
        color: @gray-900;
      }
      & h4 {
        font-size: 12px;

        color: @gray-500;
      }
    }
  }
}

.br-hubs-performance__rating-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 16px;
  width: 100%;
  max-width: 195px;
  margin-right: 24px;
  cursor: pointer;
}

.br-hubs-performance__rating-card-container.top {
  border-top: 3px solid @green-600;
}

.br-hubs-performance__rating-card-container.good {
  border-top: 3px solid @yellow-400;
}

.br-hubs-performance__rating-card-container.low {
  border-top: 3px solid @red-500;
  margin-right: 0;
}

.br-hubs-performance__rating-card-container__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.br-hubs-performance__rating-card__rate-text {
  font-family: @lato-font-700;
  font-size: 16px;
}

.br-hubs-performance__rating-card__rate-value {
  font-family: @poppins-font-600;
  font-size: 24px;
}

.br-hubs-performance__rating-card__filter-text {
  font-size: 12px;
  cursor: pointer;
  color: @teal-500;
  text-decoration: underline;
}

.br-hubs-performance__pre-header-container {
  background-color: @teal-100;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span:first-child {
    color: @gray-500;
    font-family: @lato-font-900;
  }
}

.br-hubs-performance__pre-header__score-card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & span:first-child {
    font-size: 13px;
    color: @gray-500;
    font-family: @lato-font-700;
  }
  & span:last-child {
    font-size: 11px;
    color: @gray-400;
    font-family: @lato-font-700;
  }
}

.br-hubs-performance__expand-container {
  padding: 17px 28px;
  background-color: @teal-100;
  display: flex;
  justify-content: space-between;
}

.br-hub-performance__table-container
  .br-table
  .ant-table-tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1 {
  & .ant-table-cell {
    padding: 0;
  }
  & .br-hubs-performance__expand-content {
    padding: 8px 12px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & span:first-child {
      font-family: @lato-font-700;
      color: @gray-400;
      margin-bottom: 6px;
    }
    & span:last-child {
      font-family: @lato-font-900;
      color: @gray-500;
    }
  }
}

.br-hub-performance__table-container
  .br-star-logs__filter-menu-content
  .ant-radio-group.ant-radio-group-outline {
  display: flex;
  flex-direction: column;
  & label {
    margin-bottom: 8px;
  }
}

.br-hub-performance__table-container
  .br-analytics-filter__cities-header
  > span {
  font-size: 11px;
}

.br-hubs-performance__filter-tag-remove {
  margin-left: 8px;
  cursor: pointer;
}

.br-hubs-performance__filter-tag-content {
  padding: 3px 6px;
  border-radius: 10px;
  background-color: @gray-200;
  margin-left: 8px;
  font-family: @poppins-font-600;
  font-size: 12px;
}

.br-hubs-performance__filter_tag_container {
  margin-top: 32px;
  & span:first-child {
    font-family: @lato-font-700;
    font-size: 16px;
  }
}

.br-hubs-performance__hub-name-text {
  cursor: pointer;
  color: @teal-500;
  text-decoration: underline;
}

.br-hub-performance__table-container .br-table__quick-filter-tabs {
  padding-top: 16px;
}

.br-hubs-performance-container .ant-spin-container {
  &
    > .br-hub-performance__hub-date-filter-container.br-hubs-performance-align-left,
  & > .br-hub-performance__card-container,
  & > .br-hubs-performance__filter_tag_container {
    flex-direction: row;
  }
}
