@import 'styles/variables';

.br-fulfillment-location-form.ant-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-row.ant-form-item {
    min-height: unset;

    &:not(:last-child) {
      margin: 0;
    }
  }
}

.br-fulfillment-location-form__levels-dropdowns {
  display: flex;
  gap: 12px;
}

.br-fulfillment-location-form__location-name__disabled {
  padding: 8px 12px;
  background: @bg-gray-light;
  border: 1px solid @gray-200;
  color: @text-gray-dark;
  border-radius: 4px;
}

.br-fulfillment-location-form__location-name__disabled__zone {
  color: @text-gray;
  margin-right: 5px;
}
