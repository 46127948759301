@import 'styles/variables';

.br-swapping-container {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.br-swapping-container__content {
  border: 1px solid @gray-200;
  box-shadow: @shadow-md;
  border-radius: 8px;
  width: 100%;
  // max-width: 553px;
  height: 352px;
  padding-top: 16px;
  width: 50%;
  & .br-swapping-container__title {
    margin: 16px;
    display: flex;
    margin-top: 0;
    justify-content: space-between;
    & span {
      color: @red-500;
    }
  }
}

.br-swapping-container__collapse-container.ant-collapse {
  margin-top: 10px;
  // height: 100%;
  max-height: 352px;
  overflow: auto;
  & .ant-collapse-item .ant-collapse-header {
    padding: 10px;
  }
  & .ant-collapse-item.site-collapse-custom-panel {
    background: @gray-50;
    border-bottom: 1px solid @gray-200;
    & .br-new-swapping-container__panel_title {
      color: @gray-500;
      display: flex;
      justify-content: space-between;
      & span {
        margin-right: 20px;
        color: @red-500;
      }
    }
  }
  & .ant-collapse-content.ant-collapse-content-active {
    background-color: white;
    & .ant-collapse-content-box {
      padding: 0;
      overflow: auto;
      height: 100%;
      max-height: 200px;
    }
  }
}

.br-swapping-container__collapse-container.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 9px;
  top: 10px;
  right: 16px;
  color: @gray-400;
  left: unset;
}

.br-new-swapping-container__panel-content {
  display: flex;
  justify-content: space-between;
  padding: 16px;

  & span {
    color: @gray-700;

    &:last-child {
      display: flex;
      justify-content: flex-end;
      width: fit-content;
    }
  }

  & svg {
    cursor: pointer;
  }
}

.br-new-swapping-container__panel-content.br-new-swapping-container__panel-content-missing {
  & span:first-child {
    color: @red-500;
  }
}

.br-new-swapping-container__panel-content.br-new-swapping-ungroup {
  padding: 16px;
  background: @gray-50;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 0;
}

.br-new-swapping-container-editable-text {
  display: flex;
}

.br-swapping-containr-panel__missing-scans {
  background-color: @yellow-50;
}
