@import 'styles/variables';

.br-delivery-logs__container {
  margin-top: 24px;
}

.br-delivery-logs__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  & span {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 16px;
    font-family: @poppins-font-600;
  }
}

.br-delivery-logs__container .ant-divider.ant-divider-horizontal {
  border-color: @border-color-24;
  margin-top: 16px;
  margin-bottom: 40px;
}

.br-delivery-logs__container .ant-timeline {
  & .ant-timeline-item {
    padding-bottom: 16px;
    & .ant-timeline-item-tail {
      height: calc(100% + 16px);
      top: 0px;
      left: 2px;
      border-left: 1px solid @border-color-24;
    }
    & div.ant-timeline-item-head {
      background-color: @background-color-55;
      border-color: @border-color-25;
      width: 5px;
      height: 5px;
    }
  }
}

.br-delivery-logs__log_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.br-delivery-logs__log-content {
  display: flex;
  flex-direction: column;
  & span {
    color: @text-color-31;
    font-size: 12px;
    font-family: @lato-font-400;
    line-height: 24px;
  }
  & .br-delivery-logs__log_description {
    font-family: @lato-font-700;
    font-size: 13px;
    color: @text-color;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  & .br-delivery-logs__taken_by {
    font-family: @lato-font-700;
    margin-right: 12px;
  }
  & .br-delivery-logs__hub {
    font-family: @lato-font-700;
  }
  .br-delivery-logs__por {
    margin-left: 10px;
    display: inline;

    .br-delivery-logs__por-button {
      display: inline;
      padding: 0;

      span {
        color: @text-color-22;
      }
    }

    .br-delivery-logs__por__not-available {
      color: @text-color-27;
    }
  }
}

.br-delivery-logs__log-date {
  color: @text-color-31;
  font-family: @lato-font-700;
  font-size: 13px;
  line-height: 24px;
}

.br-delivery-logs__extra-info {
  margin-top: 8px;
  border: 1px solid @border-color-23;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  color: @text-color-31;
  font-family: @lato-font-400;
}

.br-delivery-logs__extra-info.warning {
  margin-top: 8px;
  border: 1px solid @border-color-26;
  background-color: @background-color-56;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  color: @text-color-31;
}

.br-delivery-logs__extra-info.danger {
  margin-top: 8px;
  border: 1px solid @border-color-15;
  background-color: @background-color-57;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  color: @text-color-31;
}

.br-delivery-logs__extra-info div svg {
  color: transparent;
  margin-left: auto;
  width: 20px;
  height: 20px;
}

.br-delivery-logs__extra-info div svg:hover {
  cursor: pointer;
}

.br-delivery-logs__extra-info .br-delivery-logs__extra-info__down-icon {
  margin-left: auto;
}

.br-delivery-logs__extra-info {
  display: flex;
}

.br-delivery-logs__whatsapp-log {
  display: flex;
  background-color: @background-color;
  border: 1px solid @border-color-23;
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  align-items: center;
}

.br-delivery-logs__whatsapp-log svg {
  min-width: 33px;
  min-height: 33px;
}

.br-delivery-logs__whatsapp-update {
  padding-left: 10px;
  font-family: @lato-font-700;
}

.br-delivery-logs__exception-image {
  display: inline;
  margin-left: 5px;

  .ant-btn.ant-btn-text {
    display: inline;
    padding: 0;

    span {
      color: @text-color-22;
    }
  }

  .br-delivery-logs__exception-image__no-image {
    color: @text-color-27;
  }
}

.br-delivery-logs__extra-info__return-manifest {
  &.ant-btn:not(.ant-btn-circle) {
    height: auto;
    padding: 0 10px;
    display: inline;
    margin-left: 10px;

    & > span {
      color: @blue-500;
    }
  }
}
