@import 'styles/variables';

.pending-transit__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pending-transit__title {
  font-family: @poppins-font-500;
}

.pending-transit__cards-container {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.pending-transit__cards-container > * {
  width: 24%;
}

.pending-transit__hubs-table-title {
  display: flex;
  width: 140px;
  justify-content: space-between;
}

.hub-deliveries__hubname-column {
  text-decoration: underline;
  color: @teal-500;
}

.hub-deliveries__hubname-column:hover {
  cursor: pointer;
}
