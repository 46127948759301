@import 'styles/variables';

.br-fulfillment-outbound-list.br-container-content {
  background: @bg-gray-light;

  .br-table__quick-filter-tabs {
    background: @white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}
