
@import 'styles/variables.less';

.br-confirmation-tool-tip__title{
    padding: 12px;
    box-shadow: inset 0px -1px 0px @gray-700;
    margin-bottom: 12px;
    font-family: @lato-font-700;
}

.br-confirmation-tool-tip__rejection-reason{
    padding: 12px;
    padding-top: 0;
    margin-bottom: 0;
    color: @gray-300;
}