@import 'styles/variables.less';

.br-edit-weight-modal .ant-modal-content {
  width: 612px;
}

.br-edit-weight-modal .ant-modal-close {
  display: flex;
}

.br-edit-weight-modal .ant-modal-close svg {
  width: 19px;
  height: 16px;
}

.br-edit-weight-modal__heading {
  color: @text-color;
  font-family: @poppins-font-600;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
}

.br-edit-weight-modal .ant-input-number {
  width: 163px;
}

.br-edit-weight-modal .select-weight {
  margin-bottom: 10px;
}

.br-edit-weight-modal .ant-modal-footer {
  justify-content: flex-end;
}

// .br-edit-weight-modal__form .ant-row.ant-form-item {
//   min-height: fit-content;
// }
