@import 'styles/variables.less';

.br-iitem-details__header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 45px;
}

.br-completed-order-items__container {
  margin-bottom: 16px;
  & span {
    font-size: 18px;
  }
  & .br-information-table {
    margin-top: 16px;
  }
}

.br-completed-order-items__container .br-item-details__tag-value {
  font-size: 14px;
}

.br-information-table__listing__field-value .br-item-details__tag {
  font-size: 14px;
  border-radius: 8px;
  padding: 3px 8px;
  margin-left: 4px;
}

.br-item-details__fees-tag {
  color: #1dbdcd;
  background-color: #f3fafb;
}

.br-item-details__discount-tag {
  color: #e30613;
  background-color: #fef3f2;
}
