@import 'styles/variables';

.br-password-input__validation,
.br-password-input__last-validation {
  color: @text-gray;
}

.br-password-input__validation,
.br-password-input__last-validation {
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: @icon-disabled;
    }
  }
}

.br-password-input__validation--is-valid {
  color: @text-gray-dark;
  svg path {
    fill: @green-600;
  }
}

.br-password-input__validation--is-not-valid {
  color: @red-500;
  svg path {
    fill: @red-400;
  }
}

.br-registration-form-card .br-password-input.ant-row.ant-form-item {
  .ant-form-item-explain {
    margin-top: 8px;
  }
}

.br-password-input__last-validation {
  margin-top: 4px;
}

.br-password-input__validation-success {
  display: flex;
  align-items: center;
  color: @text-gray-dark;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: @green-600;
    }
  }
}
