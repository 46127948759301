@import 'styles/variables';

.br-international-deliveries-search__container {
  border-radius: 8px;
  background-color: @white;
  box-shadow: @shadow-sm;
  margin-bottom: 32px;
}

.br-international-deliveries-search__header {
  padding: 16px;
  border-bottom: 1px solid @gray-300;
}

.br-international-deliveries-search__filters {
  padding: 16px;

  .ant-picker {
    height: 36px;
  }

  .br-filters-search__row {
    margin-bottom: 16px;
  }
}

.br-international-deliveries-search__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
