@font-face {
  font-family: 'Lato/SemiBold';
  src: url(../assets/fonts/English/Lato-Semibold.ttf);
}

@font-face {
  font-family: 'Lato/Regular';
  src: url(../assets/fonts/English/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Lato/Bold';
  src: url(../assets/fonts/English/Lato-Bold.ttf);
}

@font-face {
  font-family: 'Poppins/Regular';
  src: url(../assets/fonts/English/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins/SemiBold';
  src: url(../assets/fonts/English/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins/Medium';
  src: url(../assets/fonts/English/Poppins-Medium.ttf);
}
