.inputGroup {
  width: 45%;
}

.inputSearch {
  width: 15%;
}

.divInput {
  display: flex;
  flex-direction: column;
}

h2 {
  margin-top: 20px;
}

.hubButtons {
  display: flex;
  justify-content: flex-end;
}

.hubInputGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hubInput {
  width: 21%;
}

.moneyDebrief {
  width: 75%;
}

.buttonDebrief {
  display: flex;
  justify-content: flex-end;
}

.secondPart {
  margin-top: 20px;
}
.formRow {
  justify-content: left !important;
}
.formLabel {
  white-space: nowrap;
  max-width: none;
}

.debriefTabs {
  width: unset;
  height: unset;
  display: flex;
  flex-direction: row;
}

.debriefTabs a {
  color: #1f2937;
}

.actionsMenu {
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
}

.actionsMenuPackage {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

.actionsMenuPackage h4 {
  display: flex;
  flex: 1;
}

.scanned {
  display: flex;
  width: 100%;
}

.selectWitdh {
  width: 50%;
}

.selectWitdhPickup {
  width: 100%;
}

.scanned h4 {
  width: 100%;
  margin-left: 30px;
}

.display_flex {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
}

.searchContainer {
  display: flex;
  width: 50%;
  gap: 10px;
}

.searchInput {
  flex: 50%;
  input {
    height: 34px;
  }
}
.shelvesScanBtns {
  gap: 9px;
}
