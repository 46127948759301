@import 'styles/variables.less';

.br-receive-from-bin__counter {
  padding: 24px;
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > span {
    color: @teal-500;
  }
}

.br-receive-from-bin__scanning {
  margin-top: 16px;
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-receive-from-bin-scanning__type {
  padding: 8px 24px;
  box-shadow: @border-bottom;
  .ant-row.ant-form-item {
    flex-direction: row;
    align-items: center;
    & > .ant-col.ant-form-item-control {
      width: auto;
      margin-left: 8px;
    }
  }

  .ant-radio-group > label.ant-radio-button-wrapper {
    margin-left: 8px;
    border: 1px solid @gray-100;
    border-radius: 4px;

    &::before {
      display: none;
    }
  }

  .ant-radio-group
    > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid @teal-500;
    color: @teal-500;

    &:first-child {
      border-right-color: @teal-500;
    }
  }
}

.br-hub-receive-from-bin__scanning {
  padding: 16px 24px;

  & > .ant-form {
    display: flex;
    align-items: flex-end;
    & > span {
      color: @gray-400;
      margin-right: 13px;
      margin-bottom: 4px;
    }

    & > .ant-row.ant-form-item:not(:last-child) {
      margin-right: 13px;
    }

    & > .ant-row.ant-form-item {
      min-height: 100%;
      min-width: 260px;
    }
  }
}

.br-hub-receive-from-bin__actions {
  align-items: flex-end;
  margin-left: 10px;
}

.br-hub-recceive-from-bin-table__container {
  margin-top: 20px;
}
