@import 'styles/variables';

.br-receive-new-pickups__table {
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid @gray-300;
    border-radius: 2px;
  }

  .ant-row.ant-form-item {
    min-height: 100%;
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }
}

.br-soho-tag.ant-tag {
  font-family: @lato-font-700;
  border-radius: 10px;
  border-color: @gray-100;
  font-size: 12px;
  margin-right: 0;
  margin-left: 10px;
  color: @gray-800;
  background: @gray-100;
}

.br-receive-new-pickups__container {
  margin-top: 24px;
}

.br-receive-new-pickups__star-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & .br-receive-new-pickups__star-select{
    display: flex;
    flex-direction: column;
    width: 260px;
    margin-right: 64px;
    & .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
        margin-top: 8px;
        width: 100%;
        max-width: 260px;
      }
  }
  
}

.br-receive-new-pickups__star-pickups-count-container {
  margin: 24px 0;
  background-color: @yellow-50;
  padding: 12px 24px;
  border-left: 2px solid @yellow-600;
  border-radius: 8px;
  & .br-receive-new-hubs__first-mile-count-info {
    color: @yellow-600;
  }
}
