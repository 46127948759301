@import 'styles/variables';

.br-delivery-logs__call-logs-container {
  margin: 30px 0;
  background-color: @white;
  border: 1px solid @gray-300;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px @box-shadow;
  font-size: 16px;
  font-family: @poppins-font-600;

  & .ant-tooltip-content {
    min-width: 200px;
  }

  & .ant-tooltip-inner {
    background: @gray-800;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
  }
}

.br-delivery-logs-title {
  margin-bottom: 12px;
}

.br-delivery-logs__call-logs {
  display: flex;
  gap: 16px;
}

.br-delivery-logs__call-log-box {
  min-width: 120px;
  background-color: @gray-100;
  color: @gray-600;
  padding: 8px 12px;
  font-size: 12px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.br-delivery-logs-log-count {
  display: inline-block;
  width: 20px;
  background-color: @gray-600;
  border-radius: 50%;
  margin-left: 4px;
  color: @white;
}

.br-delivery-call-log {
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
}

.br-delivery-call-log-tooltip {
  display: flex;
  padding: 12px 0;
  flex-direction: column;
  gap: 12px;
}

.br-delivery-logs__call-logs-container {
  & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }
}
