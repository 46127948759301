@import 'styles/variables.less';

.br-hub-transfer-scanning__container {
  flex: 70%;
  border: 1px solid @gray-100;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-hub-transfer-scanning__type {
  padding: 8px 24px;
  box-shadow: @border-bottom;
  .ant-row.ant-form-item {
    flex-direction: row;
    align-items: center;
    & > .ant-col.ant-form-item-control {
      width: auto;
      margin-left: 8px;
    }
  }

  .ant-radio-group > label.ant-radio-button-wrapper {
    margin-left: 8px;
    border: 1px solid @gray-100;
    border-radius: 4px;

    &::before {
      display: none;
    }
  }

  .ant-radio-group
    > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid @teal-500;
    color: @teal-500;

    &:first-child {
      border-right-color: @teal-500;
    }
  }
}

.br-hub-transfer__scanning {
  padding: 16px 24px;

  & > .ant-form {
    display: flex;
    align-items: flex-end;
    & > span {
      color: @gray-400;
      margin-right: 13px;
      margin-bottom: 4px;
    }

    & > .ant-row.ant-form-item:not(:last-child) {
      margin-right: 13px;
    }

    & > .ant-row.ant-form-item {
      min-height: 100%;
      min-width: 230px;
    }
  }
}

.br-hub-transfer-scanning-seal__tooltip-icon {
  margin-left: 6px;
}

.br-hub-transfer-table__container {
  margin-top: 16px;

  & > .br-table-component {
    box-shadow: @shadow-sm;
  }
}

.br-hub-transfer-table__title {
  padding: 24px;
  min-height: 68px;
  width: 100%;
}

.br-hub-transfer-scanning__actions {
  align-items: flex-end;
  margin-left: 10px;
}

.br-hub-transfer-scanning__count {
  padding: 0 8px;
  margin-bottom: 4px;
  color: @gray-500;

  & > span {
    color: @teal-500;
  }
}

.br-hub-transfer-delete__action {
  cursor: pointer;

  & > * {
    fill: @red-600;
  }
}

.br-hub-transfer-error-message__cta {
  text-decoration: underline;
  cursor: pointer;
}

.br-hub-transfer__container {
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }
  div.br-hub-transfer-scanning {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 16px;
  }
}

.br-hub-transfer-scanning__wrong-scanned-table {
  flex: 30%;
  border-radius: 8px;
  box-shadow: @shadow-sm;

  &,.ant-table-container {
    border-top: 1px solid @gray-100;
  }

  .br-hub-transfer-scanning__wrong-scanned-table__header {
    padding: 3px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .br-close-icon {
      cursor: pointer;
    }
    
    .body-medium;
  }
}
