@import 'styles/variables.less';

.br-pricing-table-mobile__content {
  width: 100%;
  max-width: 640px;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
  padding: 15px;
}

.br-pricing-table-mobile__list {
  background-color: white;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 14px 20px;
}

.br-pricing-table-mobile__sector-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
  & span:first-child {
    font-size: 15px;
    font-family: @lato-font-700;
  }

  & span {
    font-size: 12px;
  }
}

.br-pricing-table-mobile__list-package-size {
  font-size: 14px;
  margin-bottom: 11px;
  font-family: @lato-font-700;
  display: block;
}

.br-pricing-mobile__type-prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  & svg {
    width: 9px;
    margin-left: 3px;
    & path {
      fill: @side-bar-icon-color;
    }
  }
}
