@import 'styles/variables';

.br-dropdown-modal .ant-modal-content {
  border-radius: 12px;
}

.br-dropdown-modal_title {
  font-family: @lato-font-700;
  font-size: 18px;
  margin-bottom: 8px;
}

.br-dropdown-modal_subtitle {
  margin: 12px 0;
}

.br-dropdown-modal_footer {
  display: flex;
  justify-content: flex-end;

  .br-dropdown-modal_footer_btn {
    height: 44px;
    border-radius: 8px;
  }
}

.br-dropdown-modal_dropdown {
  width: 100%;
  border-radius: 8px;
}
