@import 'styles/variables';

.br-ofd-search-table__container {
  margin-top: 12px;
}

.br-ofd-search__attempt-date-time {
  display: block;
  color: @text-gray;
}

.br-ofd-search__star {
  display: block;
  color: @text-gray;
}

.br-ofd-search-state__tag.ant-tag {
  border-radius: 16px;
  max-width: fit-content;
}

.br-ofd-search-total-card__container,
.br-ofd-search-asr-card__container {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  margin: 20px 0;
  padding: 24px;
  max-width: 250px;
}

.br-ofd-search-asr-card__container {
  border-left: 4px solid @green-500;
}

.br-ofd-search-total-card__container {
  border-left: 4px solid @blue-500;
}

.br-ofd-search-asr-card__value {
  color: @green-500;
}

.br-ofd-search-total-card__value {
  color: @blue-500;
}

.br-ofd-search-asr-card__title,
.br-ofd-search-total-card__title {
  width: fit-content;
  border-bottom: 1px dashed @gray-300;
}

.br-ofd-search__cards-container {
  display: flex;
  align-items: center;
  gap: 12px;

  & > * {
    flex: 1;
  }
}

.br-ofd-search__check {
  > svg > * {
    fill: @red-500;
  }

  &.br-ofd-search__check__valid {
    > svg > * {
      fill: @green-600;
    }
  }
}
