.br-bulk-edit-modal__item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  .br-bulk-edit-modal__switch {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 5px;

    > div {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }
}

.ant-modal.br-material-bulk-edit-modal {
  & .ant-modal-body {
    max-height: 500px;
    overflow: auto;
  }
}
