@import 'styles/variables.less';

.br-star-details__star-name,
.br-star-details__star-wallet {
  display: flex;
  flex-direction: column;
}

.br-star-details__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 41px;
}

.br-star-details__label {
  font-family: @lato-font-400;
  font-size: 16px;
  color: @text-color-57;
}

.br-star-details__value,
.br-star-details__wallet_value {
  margin-top: 2px;
  font-family: @poppins-font-600;
  font-size: 24px;
}

.br-star-details__divider.ant-divider {
  height: auto;
  margin: 0 29px;
}

.br-star-details__tables__divider.ant-divider {
  height: auto;
  margin: 0;
}

.br-star-details__tables__headers {
  margin-bottom: 16px;
}

.br-star-details__tables__headers .ant-btn:not(:last-child) {
  margin-right: 26px;
}

.br-star-details__actions > .ant-dropdown-menu-item {
  display: flex;
  align-items: center;

  & svg {
    fill: none;
  }
}

.br-star-details__actions__status {
  margin-right: 8px;
}

.br-star-details__tables {
  margin-top: 30px;
}

.br-star-details__tables__headers {
  display: flex;
}

.br-star-details__default-btn-tab.ant-btn {
  border: none;
}

.ant-btn.br-star-details__default-btn-tab:hover,
.ant-btn.br-star-details__default-btn-tab:active {
  background: none;
  color: @text-color-57;
  border: 0 none;
}

.br-request-pickup-modal__title {
  margin-bottom: 24px;
  font-family: @lato-font-700;
}

.br-request-pickup-modal__footer {
  padding-top: 24px;
}

.br-star-details__header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-star-details .br-create-edit-star__salary-section {
  margin: 16px 0;
}
