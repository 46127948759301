@import 'styles/variables';

.br-hubs-receive-from-business__confirmation-modal {
  .ant-modal-content {
    > .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: @spacing-xl;

      padding: @spacing-xl @spacing-xl 0;

      .confirmation-modal__header {
        text-align: center;

        &__icon {
          position: relative;

          display: flex;
          justify-content: center;
          align-items: center;

          margin-bottom: calc(@spacing-md + 24px);

          &::before, &::after {
            content: '';

            position: absolute;

            width: 48px;
            height: 48px;

            border-radius: 9999px;
            background-color: @yellow-50;
          }

          &::after {
            width: 32px;
            height: 32px;
            background-color: @yellow-100;
          }

          > svg {
            position: relative;
            z-index: 1;
            width: 20px;
            height: 20px;

            > * {
              stroke: @yellow-600;
            }
          }
        }

        h4.ant-typography {
          .display-sm();
          color: @gray-900;
          margin-bottom: @spacing-md;
        }

        h5.ant-typography {
          .display-sm();
          color: @teal-500;
          margin-top: 0;
        }
      }

      > div.ant-descriptions {
        border-radius: 12px;
        background-color: @gray-100;

        padding-block: @spacing-md;

        .ant-descriptions-item-label {
          .body();
          &::after {
            content: unset;
          }
        }

        .ant-descriptions-item-content {
          .body-medium();
        }
        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
          background: none;
          border: none;

          color: @gray-900;
          padding-block: 5px;
        }
      }


      > div.ant-typography {
        .heading();
        text-align: center;
        color: @gray-500;

        margin-bottom: 0;
      }
    }

    > .ant-modal-footer {
      border: none;
      background-color: @white;
      padding: @spacing-xl;
    }
  }
}