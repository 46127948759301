@import 'styles/variables.less';

.br-settlement-modal__footer {
  display: flex;
  justify-content: flex-end;
}

.br-settlement-modal__footer .ant-btn:not(:last-child) {
  margin-right: 8px;
}

.br-settlement__modal .ant-modal-title {
  font-size: 20px;
  font-family: @lato-font-700;
}
