.br-support-tickets__preview-image {
  width: 100%;
  max-height: 800px;
}

.br-preview-image-modal {
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      cursor: pointer;
    }
  }
}
