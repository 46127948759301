@import 'styles/variables';

.br-add-product-modal {
  .ant-modal-body {
    padding: 0;
    max-height: 388px;
    overflow: auto;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-wrapper {
      display: flex;
      margin: 0;
      padding: 21px 16px;
      box-shadow: @border-bottom;

      &.ant-radio-wrapper-checked {
        background: @bg-selected;
      }

      span.ant-radio + * {
        padding-left: 16px;
      }
    }
  }

  .br-empty-state {
    margin-bottom: 16px;
  }
}

.br-add-product-modal__search-container {
  box-shadow: @border-bottom;
  padding: 16px;

  .ant-input-affix-wrapper {
    border-radius: 4px;
  }
}
