@import 'styles/variables';

.br-exceptions-tab__delete-modal .ant-modal-footer,
.br-exceptions-tab__ticket-modal .ant-modal-footer {
  justify-content: flex-end;
}

.br-exceptions-tab__ticket-modal__title,
.br-receive-pickup-confirmation-modal p {
  font-size: 17px;
  font-family: @lato-font-700;
}

.br-exceptions-tab__ticket-modal__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-exceptions-tab__ticket-modal__actions .ant-btn {
  width: 200px;
  margin-bottom: 15px;
}

.br-receive-pickup-confirmation-modal p:not(:first-child) {
  font-size: 15px;
}

.br-receive-pickup-confirmation-modal__footer {
  display: flex;
  justify-content: flex-end;
}

.br-receive-pickup-confirmation-modal__footer .ant-btn:first-child {
  margin-right: 8px;
}

.br-create-new-security-seal-modal__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & .ant-btn:first-child {
    margin-right: 8px;
  }
}
