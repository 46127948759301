@import 'styles/variables';

.ant-modal.br-security-modal {
  width: 600px;
}

.br-security-pin-modal__footer {
  display: flex;
  justify-content: flex-end;
}

.br-security-pin-modal__footer .ant-btn:first-child {
  margin-right: 10px;
}

.br-security__qr-code,
.br-security__qr-code__secret-token {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.br-security__qr-code__secret-token {
  margin-bottom: 10px;
}

.br-security__qr-code-modal {
  margin-bottom: 0;
}

.br-security__qr-code-modal__title {
  font-size: 16px;
  margin-bottom: 15px;
}

.br-security__qr-code__secret-token span:first-child {
  font-family: @lato-font-700;
  text-align: center;
}

.br-security__qr-code__secret-token span:nth-child(2) {
  font-size: 13px;
  text-align: center;
}
