@import 'styles/variables';

.br-bonus-and-deduction__filter__actions {
  display: flex;
  margin-top: 10px;
  & .ant-btn:last-child {
    background-color: @background-color-18;
    border-color: @background-color-18;
    margin-left: 12px;
  }
}
