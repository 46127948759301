@import 'styles/variables';

.br-dispatching-hub-summary-card__container {
  box-shadow: @shadow-sm;
  border-radius: 4px;
  min-width: 220px;
  cursor: pointer;
}

.br-dispatching-hub-summary-card__hub-name {
  padding: 16px;
  border-bottom: 1px solid @gray-200;
  & > svg {
    margin-right: 12px;
  }
}

.br-dispatching-hub-summary__card-title {
  color: @gray-400;
}

.br-dispatching-hub-summary-card__info {
  padding: 24px 16px;
}

.br-dispatching-hub-summary__to-be-dispatched-title {
  text-transform: uppercase;
  color: @gray-600;
}

.br-dispatching-hub-summary__cards {
  flex-wrap: wrap;
  & > div {
    width: 50%;
    margin-top: 16px;
    padding-left: 8px;
  }
}

.br-dispatching-hub-summary__card {
  &.border-yellow {
    border-left: 1px solid @yellow-400;
  }
  &.border-blue {
    border-left: 1px solid @blue-400;
  }
  &.border-dark-yellow {
    border-left: 1px solid @yellow-600;
  }
  &.border-red {
    border-left: 1px solid @red-700;
  }
}
