@import 'styles/variables';

.br-location-empty-state {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.br-location-empty-state__title {
  display: block;
  margin-top: 44px;
  .font({.display-md()});
}
