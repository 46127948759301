.br-fulfillment-table {
  .br-sales-orders-table__header-btns {
    display: flex;

    .br-button-component:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      gap: 12px;

      svg {
        border-radius: 0;
      }
    }
    .br-button-component:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    &.ant-table-cell.ant-table-selection-column {
      padding: 16px 12px;
    }

    &:nth-child(2) {
      padding-left: 12px;
    }
  }
}
