@import 'styles/variables';

.br-serials-same-type {
  padding: 20px 0;
  box-shadow: @border-top;

  .br-serial-number-btn.ant-btn {
    height: 24px;
    padding: 2px 8px;
    border-radius: 12px;
    background: @bg-gray-light;
    border-width: 0;

    &,
    &:hover,
    &:focus,
    &:active {
      color: @text-gray-dark;
    }
    &:focus {
      background: @bg-gray;
    }
  }
}

.br-serials-same-type__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.br-serials-same-type__serials {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.br-serial-number__popover {
  .ant-popover-inner {
    border-radius: 8px;

    .ant-popover-inner-content {
      display: flex;
      gap: 8px;

      .br-button-component {
        max-height: 28px;
        padding: 2px 4px;

        .copy-icon {
          width: 24px;
        }
      }
    }
  }
}

.br-serials-same-type__view-all-btn {
  margin: 24px auto 0;
}
