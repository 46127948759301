@import 'styles/variables';

.br-hubs-receive-from-business__pickup-scanning__business-info.ant-card {
  > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    > .business-info__header-icon {
      width: 48px;
      height: 48px;
      margin-inline: auto;
      margin-bottom: @spacing-md;

      background-color: @white;
      border-radius: 9999px;
    }
  }
}