.br-fxf-filters {
  display: flex;
  margin: 32px 0;
  align-items: flex-end;
  justify-content: space-between;

  .ant-row.ant-form-item {
    min-height: unset;
    max-width: 328px;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

.br-fxf-filters__content {
  display: flex;
  flex: 1;
  gap: 24px;
  align-items: flex-end;
}
