@import 'styles/variables.less';

.br-barcode-scanning-header__container {
  display: flex;
  align-items: center;
  gap: 12px;

  .ant-btn {
    max-width: 28px;
  }
}

.br-barcode-scanning__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.br-barcode-scanning__input-container {
  padding: 24px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  min-width: 684px;
}

.br-barcode-scanning__list-container {
  min-width: 684px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-barcode-scanning__list-header {
  padding: 16px 24px;
  box-shadow: @border-bottom;
  background-color: @gray-50;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.br-barcode-scanning__empty-state {
  padding: 24px;
}

.br-barcode-scanning__list-body {
  max-height: 544px;
  overflow-y: auto;
}

.br-barcode-scanning__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
}

.br-barcode-scanning__list-item:not(:last-child) {
  box-shadow: @border-bottom;
}

.br-container.br-barcode-scanning__awb-printing {
  box-shadow: unset;

  > .br-container-content {
    padding: 10px;
    margin: 10px;
  }
}

@media only screen and (max-width: @sm) {
  .br-barcode-scanning__input-container,
  .br-barcode-scanning__list-container {
    min-width: 100%;
  }
}
