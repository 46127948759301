@import 'styles/variables';

.br-insurance-plan__selector {
  width: 100%;
  flex: 1;

  &.ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid @teal-500;
  }

  .ant-select-selection-item {
    color: @teal-500;
    .font({.button()});
  }

  &.ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
    background: transparent url('../../../assets/bosta-icons/Chevron-down.svg')
      no-repeat 50% 50% !important;

    > svg > * {
      stroke: @icon-teal;
    }
  }
}
