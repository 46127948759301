@import 'styles/variables';

.br-edit-business__section__title {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.br-edit-business-container {
  margin-bottom: 20px;
}

.br-edit-business__wallet-payment-section
  .ant-form-item
  .anticon.anticon-info-circle {
  color: @icon-subdued;
}

.br-edit-business-container__promotion-actions {
  display: flex;
  align-items: center;
  .anticon {
    margin-right: 8px;
    path {
      fill: none;
    }
  }
}

.br-edit-business__api-key-section .br-edit-business__section__title {
  .br-edit-business__section__subtitle {
    margin-top: 8px;
  }
  span {
    display: flex;
    align-items: center;

    .ant-btn {
      margin-left: 12px;
    }
  }
}

.br-edit-business_cities__option .ant-select-item-option-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.br-edit-business__pricing-info-section {
  .br-edit-business__section__title-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .ant-checkbox-wrapper {
    min-width: 30%;
    .font({.body-medium()});
  }

  > .display-flex {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.br-edit-business__allow-refund {
  width: 40%;
  border-left: 1px solid @gray-300;
  padding-left: 22px;
  display: flex;
  align-items: center;
  gap: 25px;

  > span {
    display: flex;
    align-items: center;
    gap: 12px;
    .font(.body-medium());

    &:has(.ant-switch-disabled) {
      color: @text-gray;
      cursor: not-allowed;
    }
  }

  .ant-row.ant-form-item,
  .ant-form-item-control-input-content {
    min-height: auto;
  }
}

.br-edit-business__allow-refund {
  .ant-tooltip-content {
    min-width: 330px;
  }
}

.br-edit-business__tooltip.ant-tooltip {
  max-width: unset;
}
