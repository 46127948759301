@import 'styles/variables.less';

.br-table-component.br-bosta-capital-table.br-table-component__no-selection {
  & .br-table__quick-filter-tabs {
    padding-top: 16px;
  }
  & .br-capital__white-space-column {
    white-space: nowrap;
  }
}

.br-bosta-capital__container {
  & .br-tabs .br-tabs__tab {
    height: auto;

    & .ant-tabs-nav {
      margin-bottom: 10px;
    }

    & .ant-tabs-nav-wrap {
      margin: 0;
    }
  }

  & .ant-spin-container {
    & > span.heading {
      margin-bottom: 16px;
    }
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    background-color: @teal-100;

    & div {
      padding: 4px;
    }
  }
}
