@import 'styles/variables.less';

.br-packaging-orders__shipping-orders-container {
  padding: 24px;
  background-color: white;
  & .br-table__header__input-search {
    display: none;
  }
}

.br-table-component.br-shipping-orders__table {
  & .br-packagin-shipping-orders__status-column {
    width: fit-content;
  }
  & .br-packaging-shipping-orders__tracking-number-text {
    color: @teal-500;
    cursor: pointer;
  }
}

.br-shipping-orders__table {
  .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
    display: none;
  }
}

.br-packaging-orders__count {
  display: flex;
  align-items: center;
  gap: 4px;
}
