.br-amzaon-tips__errors-table.ant-modal {
  max-width: 700px;

  .ant-modal-body {
    max-height: 500px;
    overflow-y: scroll;
  }

  .ant-btn-default {
    display: none;
  }
}
