@import "styles/variables";

.br-pickup-details > .br-pickup-details-content > .br-table-component {
  padding: 0;
  border-radius: 0;

  .ant-table-container {
    box-shadow: @border-all-sides;

    &::after, &::before {
      box-shadow: none;
    }

    .ant-table-content {
      border-radius: 0;

      table {
        max-width: 100%;
        border-radius: 0;

        tr > th, tr > td {
          border-radius: 0;
        }

        tr > th {
          &:first-of-type {
            box-shadow: @border-left, @border-bottom;

            [dir="rtl"] & {
              box-shadow: @border-right, @border-bottom;
            }
          }

          &:last-of-type {
            box-shadow: @border-right, @border-bottom;

            [dir="rtl"] & {
              box-shadow: @border-left, @border-bottom;
            }
          }
        }
      }
    }
  }
}

.br-pickup-details__missed-delivery-tag {
  padding: 4px 10px;
  border-radius: 10px;
  background-color: @red-100;
  margin-left: 8px;
  color: @red-500;
}