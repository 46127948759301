@import 'styles/variables.less';

.br-header-card-content {
  background-color: @background-color-5;
  border-radius: 8px;
  padding: 15px 22px 11px 14px;
}

.br-header-card-content__row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  gap: 6px;
  > span:first-child {
    display: flex;
    align-items: flex-start;
  }
}

.br-header-card-content__row:not(:first-child) {
  margin-top: 12px;
}

.br-header-card-content__row__label {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 14px;
}

.br-header-card-content__row__value {
  color: @text-color;
  font-family: @lato-font-400;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.br-header-card-content__sub-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-bottom: 0;
  color: @text-color-32;
  font-family: @lato-font-400;
  font-size: 13px;
}
