@import 'styles/variables';

.br-score-card__hubs-section-container {
  border: 1px solid #e5e7eb;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 27px;
}

.br-score-car__hubs-section-title {
  font-size: 14px;
  margin-bottom: 16px;
  font-family: @lato-font-700;
}

.br-score-card__hubs-section__select-hub-container {
  display: flex;
  margin-bottom: 16px;
  & button {
    margin-left: 12px;
    padding: 18px 16px;
  }
}

.br-score-card__input-container {
  width: 266px;
  max-width: 266px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  border-color: #a7acb1;
  border-radius: 4px;
  padding: 8px 17px 8px 12px;
  & span {
    font-size: 13px;
    color: #aaafb4;
  }
}

.br-score-card__menu-container.ant-dropdown-menu {
  border: 1px solid #ced0da;
  border-radius: 4px;
  padding-top: 12px;
}

.br-score-card__menu-container.ant-dropdown-menu
  .ant-dropdown-menu-item
  .ant-checkbox-group.br-score-card__checkbox-group-container {
  display: flex;
  flex-direction: column;

  & .ant-checkbox-group-item {
    margin-bottom: 12px;

    .ant-checkbox + span {
      color: #6c738a;
      font-size: 13px;
      font-family: @lato-font-700;
    }
  }
}

.br-score-card__select-hub-tooltip {
  font-size: 12px;
  color: #374151;
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
  }
}

.br-score-card__divider {
  margin: 0 12px;
  color: #e5e7eb;
}

.br-score-card__assigned-hub {
  // background-color: black;
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.br-score-card__assigned-hub-template-no {
  background-color: #fee2e2;
  border-radius: 10px;
  padding: 1px 10px 2px;
  font-size: 10px;
  color: #991b1b;
}

.br-scorecard__checkbox-list-container {
  height: 100%;
  max-height: 250px;
  overflow: auto;
}

.br-score-card__tempalate-section-hub-filter-conatiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &
    .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    min-width: 150px;
  }
}

.ant-select-dropdown.zeez.ant-select-dropdown-placement-bottomLeft {
  background-color: red;
  width: 250px;
  // left: 230px !important;
  left: 400px !important;
}
