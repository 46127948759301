// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/all.min.css';
// Import Flag Icons Set
// Import Font Awesome Icons Set
// Import Simple Line Icons Set
// @import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #afafaf;
  border-radius: 2px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d83f2d;
  background-color: #d83f2d;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(216, 63, 45, 0.22);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #ffc9c3;
  border-color: #ffc9c3;
}

.custom-control-label {
  color: #979797;
  font-size: 1em;
  font-family: 'Lato/Bold';
}

.custom-control-label::before {
  border: #000 solid 2px;
}

.primaryButton {
  border-radius: 10px;
  color: #fff !important;
  margin-top: 12px;
  background-color: #d7262c !important;
  font-size: 16px;
  font-family: 'Lato/Bold';
  border-color: transparent !important;
  min-width: 160px;
  padding: 0.375rem 0.75rem;
  margin-left: 12px;

  &_success {
    @extend .primaryButton;
    background-color: #0a8e00 !important;
  }

  &_warning {
    @extend .primaryButton;
    background-color: #ff7011 !important;
  }
}

.secondaryButton {
  border-radius: 10px;
  color: #030303 !important;
  margin-top: 12px;
  background-color: #d4d4d46e !important;
  font-size: 16px;
  font-family: 'Lato/Bold';
  border-color: transparent !important;
  min-width: 160px;
  padding: 0.375rem 0.75rem;
  margin-left: 12px;
}

.formContainer {
  margin-bottom: 24px;
}

.input-group-text {
  font-size: 15px;
}

.css-1wa3eu0-placeholder {
  color: #5c6873 !important;
  font-weight: 400;
  padding: 0.37rem 0.5rem !important;
  //font-size: 15px;
}

.btn-primary {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-primary,
.btn-primary:hover {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none !important;
}

.form-row {
  justify-content: center;
  align-items: baseline;
  & label {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
  }
}

.form-row.br-align-left {
  justify-content: flex-start;
}

.dimmed {
  opacity: 0.5 !important;
  pointer-events: none;
}

div[class$='-menu'] {
  z-index: 100000;
}

.debriefTabs {
  & + .tab-content {
    border: 0;
  }

  & .nav-link {
    padding: 1rem 3rem;

    &.active {
      border-bottom-color: #c8ced3;
      border-radius: 0.25rem;
      background-color: #f0f3f5;
      color: #1f2937;

      &:focus {
        border-bottom-color: #c8ced3;
      }
    }
  }
}

.lightOpacity {
  opacity: 0.3 !important;
}

.css-1wa3eu0-placeholder {
  opacity: 0.3;
}

input[type='text'],
input[type='password'],
input[type='number'],
.no-min-width {
  min-width: unset;
}

.no-color * {
  color: black !important;
  font-weight: bold;
  font-size: medium;
}

// .ant-modal-wrap, .ant-modal-mask {
//   z-index: 10000 !important;
// }

.ant-picker-dropdown,
.ant-picker-panel-container,
.ant-calendar-picker-container {
  z-index: 10050 !important;
}

.fullWidth {
  width: 100%;
}

.float-right {
  float: right;
}

.return-to-shipper {
  .ant-modal-content {
    background-color: #f7ed8e;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.redispatch-tomorrow {
  .ant-modal-content {
    background-color: #8ef7aa;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.change-phone-number {
  .ant-modal-content {
    background-color: #8edff7;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.schedule-delivery {
  .ant-modal-content {
    background-color: #f7db8e;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.change-address {
  .ant-modal-content {
    background-color: #df8ef7;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.change-address-reschedule {
  .ant-modal-content {
    background-color: #f78ecd;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
    .primaryButton {
      display: none;
    }
  }
}

.default-action {
  .ant-modal-content {
    background-color: #fc7878;

    .ant-modal-confirm-content {
      @extend .float-right;
    }
  }
}

.app-loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
