@import 'styles/variables.less';

.br-stars-search__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .ant-btn {
    width: 160px;
  }

  & > div {
    display: flex;
    gap: 4px;
  }
}
