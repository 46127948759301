@import 'styles/variables';

.br-shipment-details__timeline {
  width: 100%;
  max-width: 996px;
  padding-top: 24px;
}

.br-shipment-details__timeline__container {
  position: relative;
  border: 1px solid @gray-200;
  border-radius: 8px;
  > button.br-shipment-details__expand-collapse {
    position: absolute;
    left: calc(50% - 64px);
    bottom: 16px;
    gap: 4px;
  }
}

.br-shipment-details__steps {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px 8px 0 0;

  //steps stepper
  .br-shipment-details__steps-stepper {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 16px;
    margin-bottom: 8px;

    > div.br-shipment-details__steps-dots {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 4px;
      span.br-shipment-details__steps-dot {
        width: 8px;
        height: 8px;
        display: flex;
        border-radius: 100px;
        background-color: @white;
      }
    }

    > div.br-shipment-details__steps-track {
      position: absolute;
      display: flex;
      align-items: center;
      height: 16px;
      width: 100%;
      border-radius: 12px;
      padding: 0 2px;
      .br-shipment-details__steps-progress {
        border-radius: 12px;
        height: 12px;
      }
    }
  }

  //steps description (state & date)
  .br-shipment-details__steps-description {
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 142px;
      &.br-shipment-details__steps-description-1 {
        text-align: center;
      }
      &.br-shipment-details__steps-description-2 {
        text-align: end;
      }

      .br-shipment-details__steps-date {
        color: @text-gray;
      }
    }
  }

  //steps color groups
  &-br-state-blue-group,
  &-br-state-mint-blue-group {
    background-color: @blue-50;
    .br-shipment-details__steps-track {
      background-color: @blue-100;
      .br-shipment-details__steps-progress {
        background: linear-gradient(270deg, @blue-500 0%, @blue-400 100%);
      }
    }
  }

  &-br-state-yellow-group {
    background-color: @yellow-50;
    .br-shipment-details__steps-track {
      background-color: @yellow-100;
      .br-shipment-details__steps-progress {
        background: linear-gradient(270deg, @yellow-500 0%, @yellow-400 100%);
      }
    }
  }

  &-br-state-teal-group {
    background-color: @teal-100;
    .br-shipment-details__steps-track {
      background-color: @teal-200;
    }
    .br-shipment-details__steps-dot:first-child {
      border: 2px solid @teal-500;
    }
  }

  &-br-state-gray-group {
    background-color: @gray-100;
    .br-shipment-details__steps-track {
      background-color: @gray-200;
      .br-shipment-details__steps-progress {
        background: linear-gradient(270deg, @gray-500 0%, @gray-400 100%);
      }
    }
  }

  &-br-state-red-group {
    background-color: @red-50;
    .br-shipment-details__steps-track {
      background-color: @red-100;
      .br-shipment-details__steps-progress {
        background: linear-gradient(270deg, @red-500 0%, @red-400 100%);
      }
    }
  }

  &-br-state-green-group {
    background-color: @green-50;
    .br-shipment-details__steps-track {
      background-color: @green-100;
      .br-shipment-details__steps-progress {
        background: linear-gradient(270deg, @green-500 0%, @green-400 100%);
      }
    }
  }

  //steps current step
  &-0 {
    .br-shipment-details__steps-stepper
      > div.br-shipment-details__steps-dots
      span.br-shipment-details__steps-dot:first-child {
      width: 12px;
      height: 12px;
    }

    .br-shipment-details__steps-state-name-0 {
      .font({.body-medium()});
    }
    .br-shipment-details__steps-state-name-1,
    .br-shipment-details__steps-state-name-2 {
      color: @text-gray;
    }
  }

  &-1 {
    .br-shipment-details__steps-progress {
      width: calc(50% + 6px);
    }
    .br-shipment-details__steps-dot:first-child {
      opacity: 0.4;
    }
    .br-shipment-details__steps-state-name-1 {
      .font({.body-medium()});
    }
    .br-shipment-details__steps-state-name-2 {
      color: @text-gray;
    }
  }

  &-2 {
    .br-shipment-details__steps-progress {
      width: calc(100% + 6px);
    }
    .br-shipment-details__steps-dot:not(:last-child) {
      opacity: 0.4;
    }
    .br-shipment-details__steps-state-name-2 {
      .font({.body-medium()});
    }
  }
}

.br-shipment-details__description {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  box-shadow: @border-top-bottom;
  .br-shipment-details__description-text {
    display: flex;
    flex-direction: column;
    .br-shipment-details__timeline__footer__next_action_label {
      white-space: break-spaces;
      & .br-order-description__follow-up-button-title {
        color: @text-color;
        font-size: 14px;
      }
    }
  }

  &.br-state-mint-blue-group,
  &.br-state-blue-group {
    background-color: @blue-50;
  }

  &.br-state-teal-group {
    background-color: @teal-100;
  }

  &.br-state-gray-group {
    background-color: @gray-100;
  }

  &.br-state-green-group {
    background-color: @green-50;
  }

  &.br-state-red-group {
    background-color: @red-50;
  }

  &.br-state-yellow-group {
    background-color: @yellow-50;
  }
}

@media only screen and (max-width: @xs) {
  .br-shipment-details__timeline {
    padding-top: 0;
  }
}
