@import 'styles/variables';

.br-hubs-receive-from-business__pickup-scanning__scanned-packages.ant-card {
  > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    .display-xs();
    display: flex;
    gap: @spacing-sm;
  }


  > .ant-card-body {
    padding: 0;
    height: calc(460px - 64px);
    overflow-y: auto;

    .ant-list .ant-list-items > .ant-list-item {
      .body();
      color: @gray-500;
      padding-inline: @spacing-xl;

      display: flex;
      justify-content: space-between;
      align-items: center;

      > button.ant-btn.br-button-component {
        background: none;
        border: none;
      }
    }

    > .ant-row.ant-form-item:has(.ant-list-empty-text) {
      height: 100%;

      > .ant-col.ant-form-item-control > .ant-form-item-control-input {
        height: 100%;
      }

      .br-hubs-receive-from-business__pickup-scanning__scanned-packages__empty-data-label {
        .heading();
        color: @gray-500;
        height: 100%;

        > svg {
          margin-bottom: @spacing-xl;
        }
      }
    }
  }
}