@import 'styles/variables';

.br-international-transfer-table__conatiner {
  .br-table-component__empty-state {
    height: 100%;

    .br-empty-state {
      padding: 24px;
      box-shadow: @border-top;
    }
  }
}

.br-international-transfer-table__actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  .ant-btn svg path {
    fill: transparent;
  }
}
