@import 'styles/variables.less';

.br-add-time-slot-modal__title {
  margin-bottom: 36px;
  font-family: @poppins-font-600;
  color: @text-color-50;
  font-size: 18px;
}

.br-add-time-slot-modal__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 52px;
}

.br-add-time-slot-modal__footer .ant-btn:not(:last-child) {
  margin-right: 8px;
}
