@import 'styles/variables.less';

.br-hub-transfer__tabs {
  margin-top: 24px;

  .ant-tabs-top.br-tabs__tab {
    border: 1px solid @gray-100;
    border-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: @shadow-sm;
  }
}

.br-hub-operation__content > .br-tabs > :not(.ant-tabs) {
  &.br-hub-transfer-error__banner {
    padding: 0;
  }
}

.br-hub-transfer__header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
