@import 'styles/variables';

.br-container-content {
  background: @bg-gray-light;
}

.br-status-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  
  .ant-tag {
    border-radius: 16px;
    padding: 2px 8px;
    height: 20px;
    border: none;
    display: flex;
    align-items: center;
    width: max-content;
    margin-right: 0;

    svg {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }

    &.in_stock {
      background: @teal-100;
      color: @teal-700;
    }
    
    &.created,
    &.on_hold,
    &.rtv {
      background: @yellow-100;
      color: @yellow-900;
    }
  
    &.confirmed,
    &.picked {
      background: @purple-100;
      color: @purple-900;
    }
  
    &.putaway,
    &.packed,
    &.ready,
    &.processing {
      background: @blue-100;
      color: @text-blue-dark;
    }
  
    &.completed,
    &.transferred {
      background: @green-100;
      color: @text-green-dark;
  
      svg path {
        fill: @green-500;
      }
    }
  
    &.canceled,
    &.problematic {
      background: @gray-200;
      color: @text-gray;
    }

    &.damaged,
    &.lost {
      background: @red-100;
      color: @text-red-dark;
    }
  }
}

.br-po__actual-quantity__header {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: @icon-subdued;
    }
  }
}
