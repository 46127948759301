@import 'styles/variables';

.br-international-delivery-logs__timeline {
  .ant-timeline-item-head {
    width: 14px;
    height: 14px;
    background-color: @bg-gray;
    border: none;
  }

  .ant-timeline-item-tail {
    left: 6px;
  }
}

.br-international-delivery__event-extra-data {
  padding: 8px;
  border: 1px solid @gray-200;
  border-radius: 4px;
  margin-top: 8px;
}
