.buttonsDiv {
  display: flex;
  justify-content: flex-end;
}

.filtersSection {
  margin: 35px;

  & .filterBar {
    margin: 15px 0;
  }
}

.formWidth {
  padding: 15px;
}

.formRow {
  justify-content: left !important;
  align-items: center !important;
}
.error {
  color: red;
}
.modifiedState {
  border-radius: 13px;
  font-size: calc(14px + (16 - 14) * ((100vw - 80vw) / (1920 - 960)));
  font-family: 'Lato/Bold';
  text-align: center;
  text-transform: capitalize;
  width: 160px;
  padding: 6px;
  color: '#D7262C';
  background-color: 'rgba(215, 38, 44, 16%)';
}

.formLabel {
  white-space: nowrap;
  max-width: none;
}

.inlineComponent {
  display: inline;
}

.actionsMenu {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

.dimmed {
  opacity: 0.6;
  pointer-events: none;
}

.SearchToggleClosed {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before {
    content: '\25BA';
    margin-right: 0.5rem;
  }
}
.SearchToggleOpen {
  text-decoration: underline;
  font-size: 20px;
  font-family: 'Lato/Bold';
  &::before {
    content: '\25BC';
    margin-right: 0.5rem;
  }
}
button.outboundGreen {
  background-color: #1ac63d !important;
}

.exceedSLA {
  color: rgb(255, 255, 255);
  background-color: rgb(255, 51, 51);
}
.nearToSLA {
  color: rgb(255, 255, 255);
  background-color: #ff7f1a;
}

.details_item {
  &__text {
    display: inline;
    word-break: break-word;
  }
}

.full_width {
  width: 100%;
}

.info_bordered_box {
  border: 4px solid black;
}

.br_delivery__crp_image {
  width: 50%;
  max-height: 100%;
  object-fit: cover;
  max-width: 100px;
  cursor: pointer;
}
