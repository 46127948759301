@import 'styles/variables';

.br-shipment-details {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.br-shipment-details__container {
  > div:first-child {
    display: flex;
    justify-content: center;
    box-shadow: @border-bottom;
    margin-right: 16px;
  }
  .br-shipment-details__header {
    padding: 0;
    width: 100%;
    max-width: 996px;
    box-shadow: @border-bottom;
  }
}

.br-shipment-details__tooltip-icon-danger.anticon {
  color: @tooltip-icon-background-color-2;
  font-size: 12px;
}

.br-shipment-details__tooltip-icon.anticon {
  color: @tooltip-icon-background-color;
  font-size: 12px;
}

.br-shipment-details .br-side-tabs__content {
  padding: 0;
}

.ant-tabs.br-shipment-details__tabs {
  height: fit-content;
  & .ant-tabs-nav {
    margin-bottom: 0;
  }

  & .ant-tabs-nav-list,
  .ant-tabs-content-holder {
    padding-left: 45px;
  }
  & .ant-tabs-tab,
  .ant-tabs-tab.ant-tabs-tab-active {
    margin: 0px;
    padding: 0;
    padding-bottom: 14px;
  }
  & .ant-tabs-tab .ant-tabs-tab-btn {
    color: @text-color-32;
    text-align: center;
    width: 129px;
    max-width: 129px;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @text-color;
    text-align: center;
    width: 129px;
    max-width: 129px;
  }

  & .ant-tabs-nav-list .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: @background-color-16;
    width: 100% !important;
    max-width: 129px !important;
  }
}

.br-shipment-details__header-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-tag {
    width: fit-content;
    color: white;
    border: none;
    border-radius: 16px;
    margin: 0;
    padding: 2px 8px;
    svg {
      width: 12px;
      height: 12px;
      margin-left: 5px;
    }
  }

  & .br-shipment-details__header-tag-action {
    display: flex;
    & button {
      margin-right: 5px;
      padding: 6px;
      height: fit-content;
      & span {
        font-size: 13px;
      }
    }
  }
}

.br-shipment-details__header-tag.br-state-mint-blue-group,
.br-shipment-details__header-tag.br-state-blue-group {
  & .ant-tag {
    background-color: @blue-100;
    color: @text-blue-dark;
  }
}

.br-shipment-details__header-tag.br-state-teal-group {
  & .ant-tag {
    background-color: @teal-100;
    color: @teal-700;
  }
}

.br-shipment-details__header-tag.br-state-green-group {
  & .ant-tag {
    background-color: @green-100;
    color: @text-green-dark;
  }
}

.br-shipment-details__header-tag.br-state-gray-group {
  & .ant-tag {
    background-color: @gray-100;
    color: @text-gray-dark;
  }
}

.br-shipment-details__header-tag.br-state-red-group {
  & .ant-tag {
    background-color: @red-100;
    color: @text-red-dark;
  }
}

.br-shipment-details__header-tag.br-state-yellow-group {
  & .ant-tag {
    background-color: @yellow-100;
    color: @yellow-900;
  }
}

.br-shipment-details__actions-small {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  .br-shipment-details__actions-small__details {
    display: flex;
    gap: 12px;
    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 36px;
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .ant-tag {
        display: none;
      }
      .br-shipment-details__actions-small__date {
        color: @text-gray;
      }
    }
  }
}

@media only screen and (max-width: @lg) {
  .br-shipment-details {
    padding: 0 24px;
  }
  .br-shipment-details__container {
    > div:first-child {
      padding: 0 24px;
    }
  }
}

@media only screen and (max-width: @md) {
  .br-shipment-details {
    padding: 0 16px;
  }
  .br-shipment-details__container {
    > div:first-child {
      padding: 0 16px;
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-shipment-details {
    padding: 0 8px;
  }
  .br-shipment-details__container {
    > div:first-child {
      padding: 0 8px;
    }
  }

  .br-shipment-details__steps,
  .br-shipment-details__description,
  .br-shipment-details__tracking,
  .br-shipment-card__header,
  .br-shipment-details__customer,
  .br-shipment-card .br-information-table,
  .br-shipment-details__summary__wallet-footer,
  .br-order-details__fulfillment-details-container {
    padding: 16px;
  }

  .br-order-details__fulfillment-details-items {
    padding: 8px 16px;
  }

  .br-shipment-details__summary__wallet-footer {
    margin: 16px;
  }

  .br-shipment-details__performance {
    padding: 20px 16px 0 16px;
  }

  .br-shipment-card__footer,
  .br-shipment-details__order-type-opening {
    padding: 20px 16px;
  }

  .br-shipment-details__tracking .br-shipment-details__tracking-timeline {
    padding-left: 2px;
  }
}

@media only screen and (max-width: 375px) {
  .br-shipment-details__actions-small {
    padding: 0 8px;
  }
}
