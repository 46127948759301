.br-swapping-tables__container {
  .br-table-component__empty-state {
    height: 35vh;
  }

  & > div {
    max-width: 50%;
  }
}

.br-swapping-tables-left-table__container {
  margin-right: 16px;
}
