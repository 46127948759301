@import "styles/variables";

article.br-business-details .br-business-details__team-members-table {
  .br-table-component .ant-table-content table tbody.ant-table-tbody {
    > tr.ant-table-row > td.ant-table-cell {
      div.br-business-details__team-member-name {
        div:has(span) span {
          .body();
          margin-inline-end: @spacing-xs;
        }

        div.br-business-details__team-member-job {
          .caption-lg();
          color: @gray-500;
        }
      }

      &:has(button.ant-btn.br-button-component) {
        div {
          display: flex;
          align-items: center;

          button.ant-btn.br-button-component {
            margin-inline-start: @spacing-sm;

            svg > path {
              fill: @teal-500;
            }
          }
        }
      }
    }
  }
}