.transactionContent{
  height: 650px;
}
.uploadTransactions {
  height: 100%;
}

.missing{
  background-color: red;
  padding: 2px;
}

.footer{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}