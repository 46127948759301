@import 'styles/variables.less';

.br-failure-modal {
  .ant-modal-body {
    text-align: center;
  }
}

.br-failure-modal__title {
  margin-top: 16px;
  .font({.display-md()});
}

.br-failure-modal__message {
  margin: 16px auto 32px;
  .font({.title-sm()});
}

.br-failure-modal__actions {
  .br-button-component {
    width: 100%;
  }
}

.br-failure-modal__timer {
  color: @text-gray;
  margin-left: 3px;
}
