@import 'styles/variables';

.br-order-actions-filter__trigger.ant-btn {
  margin-right: 8px;
  border-radius: 8px;
  min-height: 40px;
}

.br-order-actions-filter__trigger-icon {
  margin-right: 8px;
}

.br-star-map-filter__date-menu.ant-dropdown-menu {
  padding: 8px;
}

.br-hub-performance__this-month-date-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: @teal-500;
}
