@import 'styles/variables';

.br-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  height: 60px;
  border-bottom: 1px solid @border-color-14;
  background-color: white;
  position: fixed;
  width: calc(100% - 252px);
  z-index: 10;
}

.br-header__title {
  display: flex;
  align-items: center;
}

.br-header__title h2 {
  font-size: 18px;
  font-family: @poppins-font-600;
  color: @text-color;
  margin: 0;
}
.br-header__countries-menu.ant-select {
  display: flex;
  align-items: center;
}

.br-header__countries-menu img,
.br-header__countries-dropdown .ant-select-item-option-content img {
  margin-right: 4px;
  width: 20px;
  height: 20px;
}

.br-header__countries-menu .anticon {
  font-size: 5px;
}

.br-header__countries-menu.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0.5px solid #a0aec0;
  box-shadow: 0px 2px 2px @box-shadow-4;
}

.br-header__countries-menu .ant-select-arrow {
  display: flex;
  align-items: center;
}

.br-header__countries-dropdown .ant-select-item-option-content,
.br-header__countries-menu span {
  font-size: 13px;
  font-family: @lato-font-400;
  font-weight: 500;
}

.br-header__user-info {
  display: flex;
  flex-direction: column;
}

.br-header__user-info__name {
  color: @text-color;
}

.br-header__user-info__email {
  color: @gray-500;
  margin-top: 3px;
}

.br-header__back-button {
  height: 24px;
  margin-right: 16px;
}

.br-header__right-side {
  display: flex;
  align-items: center;
  gap: 16px;
}

.br-header__remote-access-btn {
  &.ant-btn:not(.ant-btn-circle) {
    border-radius: 100px;

    svg {
      height: 16px;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-header {
    position: relative;
    padding: 16px 6px;
    padding-left: 50px;
    width: 100%;

    & h2 {
      font-size: 16px;
    }
  }

  .br-header__countries-menu {
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-right: 6px;
    }
  }

  .br-header__countries-dropdown-label {
    display: none;
  }
}
