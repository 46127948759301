@import 'styles/variables.less';

.ant-modal.br-import-multiple-products-modal {
  .br-upload-dialog__empty-view__title {
    .font({.body()});
  }

  .ant-modal-header {
    height: auto;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 8px;
    padding: 56px 24px;
    border-color: @gray-200;

    .br-upload-dialog__empty-view__image {
      margin-bottom: 12px;

      img {
        width: 58px;
        height: 72px;
      }
    }
  }

  .ant-modal-footer {
    display: block;
  }

  .mirror {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .br-sample-template {
    text-decoration: underline;
    color: @text-teal;
    &.error {
      color: inherit;
    }
  }
}

.br-import-multiple-products-modal__header {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  svg {
    width: 20px;
    height: 25px;
  }
}

.br-import-multiple-products-modal__subtitle {
  color: @text-gray;
  .font({.body()});
}

.br-import-multiple-products-modal__info {
  display: flex;
  gap: 12px;
  background-color: @teal-100;
  padding: 15px;
  border: 1px solid @teal-300;
  border-radius: 4px;
  margin-bottom: 10px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: @icon-teal;
    }
  }
}

.br-uploaded-file {
  .ant-table {
    margin-top: 16px;
    box-shadow: @shadow-sm;
    td.ant-table-cell {
      cursor: auto;
      padding: 8px 12px;
    }
    .ant-table-thead {
      .ant-table-cell {
        height: auto;
        padding: 12px;
        text-transform: uppercase;
        color: @text-gray;
        background: @gray-50;
      }
      &
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
    }
  }

  .br-in-rows-column__tooltip {
    word-break: break-word;
    width: max-content;
    max-width: 300px;
    left: 400px;

    .ant-tooltip-inner {
      text-align: center;
    }
  }

  .br-in-rows-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .br-in-rows-column__tag {
      background: @gray-100;
      border-radius: 16px;
      padding: 0px 6px;
      color: @text-gray;
    }
  }
}

.br-preview-uploaded-file {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.br-preview-uploaded-file__error {
  display: flex;
  align-items: center;
}

.br-preview-uploaded-file__content {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  div:first-child {
    gap: 20px;
    display: flex;
    align-items: center;
  }
  svg {
    width: 32px;
    height: 28px;
  }
  .br-preview-uploaded-file__content__data {
    div:first-child {
      flex: 1;

      .br-preview-uploaded-file__icon {
        max-width: max-content;
      }
    }
  }
  .br-preview-uploaded-file__title-subtitle {
    .br-preview-uploaded-file__data {
      font-weight: 600;
      vertical-align: middle;
    }
  }

  .br-preview-uploaded-file__title {
    display: block !important;
    max-width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .br-preview-uploaded-file__subtitle {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    color: @text-gray;

    .error {
      color: @red-500;
    }
  }
}

.br-preview-uploaded-file__error {
  border: 1px solid @red-200;
  background-color: @red-50;
  gap: 14px;
  border-radius: 4px;
  padding: 16px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: @icon-red-dark;
    }
  }
}

.br-import-multiple-products-modal__footer {
  justify-content: flex-end;

  &,
  .br-import-multiple-products-modal__help-link,
  .br-import-multiple-products-modal__footer__actions {
    display: flex;
    align-items: center;
  }

  .br-import-multiple-products-modal__help-link {
    gap: 5px;
    color: @text-gray;
    svg {
      path {
        stroke: @icon-default;
      }
    }
  }
}

.br-uploading-file {
  border: 1px dashed @gray-200;
  border-radius: 8px;
  text-align: center;
  padding: 73px 88px;

  img {
    width: 58px;
    height: 72px;
  }
  .br-uploading-file__progress {
    margin: 40px 0 12px 0;

    .br-uploading-file__progress-text {
      float: left;
    }
    .ant-progress-bg {
      background-color: @teal-500;
    }
  }
  .br-uploading-file__title {
    color: @text-gray-light;
  }

  .br-uploading-file__file-name {
    color: @text-teal;
  }

  .br-uploading-file__subtitle {
    color: @text-gray;
  }
}

.br-upload-file {
  .br-upload-file__spin-container {
    display: flex;
    align-items: center;
    height: 255px;
    border: 1px dashed @gray-200;
    border-radius: 8px;
  }
}

.ant-modal.br-import-multiple-products-modal {
  .br-preview-uploaded-file__content__replace-btn {
    .ant-upload-list {
      display: block;
    }
    
    .ant-upload-list-picture-card-container {
      display: none;
    }

    .br-upload-dialog__upload {
      overflow-y: unset;
    }

    .br-upload-dialog__upload:not(.br-upload-dialog__upload-empty) {
      padding: 0;
      border: none;
    }

    .ant-upload.ant-upload-select-picture-card {
      height: auto;
      border: none;
      padding: 0;
      margin: 0;
      background-color: unset;
    }
  }
}

@media only screen and (max-width: @xs) {
  .ant-modal.br-import-multiple-products-modal {
    margin: 0 auto;

    .ant-upload.ant-upload-select-picture-card {
      padding: 46px 34px;
    }

    .br-preview-uploaded-file__content__data {
      flex-direction: column;

      .br-preview-uploaded-file__content__replace-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .br-preview-uploaded-file__content {
      div:first-child {
        max-width: 100%;
      }
    }

    .br-preview-uploaded-file__title-subtitle {
      max-width: 85%;
    }

    .br-uploading-file {
      padding: 30px 24px;
    }
  }
}

@media only screen and (max-width: @2xs) {
  .ant-modal.br-import-multiple-products-modal {
    .br-preview-uploaded-file__title-subtitle {
      max-width: 80%;
    }
  }
}

@media only screen and (max-width: 460px) {
  .br-uploaded-file {
    .ant-table {
      .ant-table-body {
        max-height: 100px !important;
      }
    }
  }
}
