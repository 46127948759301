@import 'styles/variables';

.br-serial-numbers-tooltip.ant-tooltip {
  .ant-tooltip-inner {
    padding: 12px;
    border-radius: 8px;

    .ant-spin-container {
      padding: 0;
      margin-top: 8px;
    }
  }

  .br-confirmed-po__tooltip__serial-number {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;

    &.checked {
      color: @text-green-dark;
    }
  }
}

.br-serial-numbers-tooltip__count {
  color: @text-gray;
  .font({.caption()});
}

.checked {
  color: @text-green-dark;
}
