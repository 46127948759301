@import 'styles/variables';

.br-fulfillment-location-address-card {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.br-fulfillment-location-address-card__header {
  padding: 4px;
  background: @bg-teal-light;
  color: @text-teal;
  border-radius: 8px 8px 0 0;
  .font({.body-medium()});
}

.br-fulfillment-location-address-card__content {
  padding: 24px;
}

.br-fulfillment-location-address-card__text {
  .font({.display-md()});
}

.br-fulfillment-location-address-card__empty-text {
  color: @text-gray;
  .font({.display-md()});
}

.br-fulfillment-location-address-card__colored-text {
  color: @text-teal;
}

.br-fulfillment-location-address-card__label {
  margin-top: 10px;
  text-transform: uppercase;
  color: @text-gray;
  .font({.subheading()});
}
