@import 'styles/variables';

.ant-modal.br-add-compensation-form-modal__container {
  width: 100%;
  max-width: 920px;
  padding-bottom: 10px;
  height: 100%;

  & .ant-modal-content {
    height: 100%;
  }

  & .ant-modal-header {
    border-radius: 8px;
  }

  & .ant-modal-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    max-height: 82%;
  }

  & .ant-modal-footer {
    justify-content: flex-end;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    > button {
      width: 82px;
    }
  }
}

.Toastify__toast-body
  .br-compensation-form-modal__success-message
  .br-compensation__track-request-message {
  font-family: @lato-font-700;
  margin-left: 24px;
  text-decoration: underline;
}
