@import 'styles/variables';

.br-personal-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.br-peronsal-info__form__edit-label {
  color: @text-color-29;
  font-family: @lato-font-400;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.br-personal-info__otp-screen {
  border: 1px solid @border-color-18;
  border-radius: 4px;
  padding: 10px 14px;
  margin-top: 8px;
}

.br-personal-profile__otp-verfication-footer {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  & button.ant-btn {
    box-shadow: 0px 2px 2px 0px @box-shadow;
  }

  & button:last-child {
    margin: 0 8px;
  }
}

.br-personal-profile__phone-section,
.br-personal-profile__email-section {
  display: flex;
  flex-direction: column;
}

.br-personal-profile__email-section {
  margin-right: 0;
}

.br-personal-info .anticon {
  border-radius: 4px;
  font-size: 11px;
  color: @text-color-15;
  padding: 4px;
}

.br-personal-info .anticon-close {
  background: @text-color-27;
  margin: 0 5px;
}

.br-personal-info .anticon-check {
  background: @text-color-29;
}

.ant-form.ant-form-horizontal.br-otp-modal__otp-code
  .br-otp-modal__form-items
  .ant-row.ant-form-item {
  margin-right: 0;
}
