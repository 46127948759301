.br-hub-operation__content {
  padding: 0;
}

.br-hub-operation__content > .br-tabs {
  & > :not(.ant-tabs) {
    padding: 20px;
  }
}

.br-hub-receive-from-sorting__container,
.br-receive-from-hub__container {
  margin-top: 24px;
}
