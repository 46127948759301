@import 'styles/variables';

.br-lost-orders__header-wrapper {
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.br-lost-orders__header-wrapper .br-pending-transit-filter__container {
  justify-content: flex-end;
}

.br-lost-orders__container .br-table__header {
  padding-top: 0px;
  height: 20px;
}

.br-lost-orders__container
  .br-table__header__actions
  .br-table__header__input-search {
  width: 315px;
}

.br-lost-orders__action-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

.br-lost-orders__action-buttons-wrapper .br-lost-orders__found-it {
  border-radius: 8px;
}

.br-lost-orders__container .br-table {
  .ant-table-row.ant-table-row-level-0:hover button.ant-btn {
    height: 34px;
  }
  .ant-table-row.ant-table-row-level-0 button.ant-btn.link-color {
    border: none;
    box-shadow: none;
  }
}
.ant-table-cell
  .br-lost-orders__action-buttons-wrapper
  .br-lost-orders__star-liable {
  border-radius: 8px;
}

.br-lost-orders__table {
  .ant-pagination > li:not(.ant-pagination-prev, .ant-pagination-next) {
    display: none;
  }
}
