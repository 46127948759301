.br-add-to-district__modal {
  .br-add-to-district__modal-form-row {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .ant-row.ant-form-item:not(:last-child) {
    margin-right: 0;
  }
}
