@import 'styles/variables';

.br-money-report__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-money-debrief-report__show-all-tn-text {
  color: @teal-500;
  cursor: pointer;
  text-decoration: underline;
}

.br-money-debrief__tracking-numbers-modal__tn-content {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  & p {
    margin-bottom: 0;
  }
  & svg {
    display: none;
    & path {
      fill: @teal-500;
    }
  }
  &:hover {
    & svg {
      display: block;
      cursor: pointer;
    }
  }
}
