@import 'styles/variables';

.br-fulfillment-return-list__date-filter-section {
  display: flex;
  justify-content: flex-end;
}

.br-status-tag__return-type.ant-tag {
  margin-left: 8px;
  color: @text-gray;
  background: @gray-200;
}

.text-gray {
  color: @text-gray;
}


.br-fulfillment-returns-table__tracking-number-col {
  display: flex;
  align-items: center;
  gap: 4px;
}

.circled-icon {
  padding: 4px;
  border-radius: 100px;
}

.br-lost-icon {
  background: @red-50;
  svg path {
    fill: @icon-red-dark;
  }
}

.br-damaged-icon {
  background: @yellow-50;
  svg path {
    fill: @icon-red-dark;
  }
}

.br-serial-number {
  padding: 2px 8px;
  border-radius: 12px;
  background: @bg-gray-light;
}
