@import 'styles/variables';

.br-multi-package__container {
  display: flex;
  justify-content: space-between;
  width: fit-content;

  &:not(.br-multi-packages__scanned) > span {
    padding: 4px 8px;
    height: fit-content;

    &:first-child {
      border-radius: 8px;
      color: @white;
      background-color: @yellow-600;
    }
  }
}

.br-multi-package__container.br-multi-packages__scanned {
  background-color: @gray-100;
  height: fit-content;
  padding: 4px 12px;
  border-radius: 8px;
  gap: 2px;

  & > span {
    color: @text-gray;
  }
}
