.br-toggle-button__br-toggle-button-container {
  display: flex;
}

.br-toggle-button__br-singlebutton {
  border-radius: 0 !important;
  width: 80px;
}

.br-toggle-button__br-rounded-end-button {
  border-radius: 0 5px 5px 0 !important;
}

.br-toggle-button__br-rounded-start-button {
  border-radius: 5px 0 0 5px !important;
}
