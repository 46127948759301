.br-finance-settle-modal-container.ant-modal{
    width: 100%;
    max-width: 672px;
}

.br-finance-settle-modal__content{
    display: flex;
    flex-direction: column;
    gap: 12px;
    & .br-finance-settle-modal__hub-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px solid #E4E7EC;
        padding: 16px 20px;
        & > div{
            display: flex;
            flex-direction: column;
            gap: 16px;
            & > div{
                display: flex;
                flex-direction: column;
                gap: 12px;
                & > span{
                    display: flex;
                    gap: 8px;
                        align-items: flex-start;
                    & > div{
                        display: flex;
                        flex-direction: column;
                    
                        & > span:last-child{
                            color: #667085;
                        }
                    }
                }
            }
        }
    }
    & .br-finance-settle-modal__settle-amount-info{
        display: flex;
        width: 100%;
        align-items: flex-end;
        background: #F9FAFB;
            border-radius: 8px;
            padding: 20px;
        & svg{
            margin-right: 24px;
        }
        & > div{
            display: flex;
            flex-direction: column;
            gap:4px;
            width:45%;
            & .ant-input-number-affix-wrapper {
                width:100%
            }
        }
        & > div:first-of-type{
            margin-right: 16px;
        }
    }
}