@import 'styles/variables.less';

.br-star-salary__fm-config-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;

  > div {
    width: calc(50% - 9px);
    min-width: 200px;

    .ant-form-item-control-input-content,
    .ant-input-affix-wrapper {
      border-radius: @spacing-xs;
    }
  }
}
