@import 'styles/variables';

.br-fulfillment-products {
  background: @bg-gray-light;

  .br-select-business {
    max-width: 328px;
  }

  .br-add-product-btn {
    display: flex;
    justify-content: flex-end;
    margin: 32px 0 24px;
  }
}
