@import 'styles/variables';

.br-order-details__notes {
  margin-top: 16px;
  border-radius: 8px;
}

.br-order-details__description {
  min-height: 69px;
  border-radius: 8px 8px 0 0;
  padding: 14px 13px;
}

.br-order-details__action {
  border-radius: 0 0 8px 8px;
  padding: 14px 13px;
}

.br-order-details__description-date {
  display: block;
  color: @tag-yellow-text-color;
  font-family: @lato-font-700;
}

.br-order-details__description-order-details {
  display: block;
  color: @blue-700;
  font-family: @lato-font-700;
}

.br-order-details__cod-amount {
  color: @green-700;
}

.br-order-details {
  &__notes {
    &.br-state-yellow-group {
      border: 1px solid @yellow-100;
    }
    &.br-state-blue-group {
      border: 1px solid @blue-100;
    }
    &.br-state-red-group {
      border: 1px solid @red-100;
    }
    &.br-state-green-group {
      border: 1px solid @green-100;
    }
  }

  &__description {
    &.br-state-yellow-group {
      background-color: @yellow-100;
    }
    &.br-state-blue-group {
      background-color: @blue-100;
    }
    &.br-state-red-group {
      background-color: @red-200;
    }
    &.br-state-green-group {
      background-color: @green-200;
    }
  }
  &__action {
    &.br-state-yellow-group {
      background-color: @yellow-25;
    }
    &.br-state-blue-group {
      background-color: @blue-25;
    }
    &.br-state-red-group {
      background-color: @red-100;
    }
    &.br-state-green-group {
      background-color: @green-25;
    }
  }
}

.br-order-details__customer_notes {
  color: @yellow-500;
}
