.br-pending-transit-filter__container {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.br-pending-transit-filter__container > * {
  width: 48%;
  height: 37px;
}

.br-pending-transit-filter__hidden-hubs-container {
  width: 300px;
}

.br-pending-transit-filter__hidden-hubs-container > * {
  width: 100%;
}
