.br-international-shipping-scan__content {
  padding: 16px 16px 24px 16px;

  .ant-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-row.ant-form-item {
    max-width: 500px;
  }
}

.br-international-transfer-table__conatiner {
  .br-table-component__no-selection
    .br-table
    .ant-table-tbody
    > tr
    > td:first-child {
    padding: 8px 0 9px 16px;
  }
}
