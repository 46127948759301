@import 'styles/variables.less';

.br-success-modal {
  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.br-success-modal__title {
  margin: 20px 0 32px 0;
  text-align: center;
}

.br-success-modal__actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.br-success-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle, @green-100 55%, @green-50 45%);
  border-radius: 50%;
  width: 58px;
  height: 58px;

  svg > * {
    stroke: @green-600;
  }
}
