@import 'styles/variables';

.br-international-deliveries__container {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.br-international-deliveries__actions {
  .ant-dropdown-menu-item {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
