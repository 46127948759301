@import 'styles/variables';

.br-international-shipping__modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .br-banner-content__text {
    flex: 1;
  }
}

.br-international-shipping-modal__title {
  text-align: center;
}

.br-international-shipping-modal__content {
  .br-banner-content__text p {
    margin-top: 25px;
  }

  ul {
    padding-left: 25px;
  }
}

.br-international-shipping-modal__upload-container {
  box-shadow: @shadow-sm;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.br-international-shipping__modal {
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 36px;
    background-color: @bg-gray-light;
  }
}

.br-international-shipping-modal__footer {
  display: flex;
  align-items: center;
  gap: 12px;
}
