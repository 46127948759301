@import 'styles/variables.less';

.br-attempts-count {
  width: 12px;
  height: auto;
  background-color: @background-color;
  border: 1px solid @background-color-5;
  box-shadow: 0px 2px 2px @background-color-38;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.br-attempts-count div {
  height: 4px;
  margin: 1px 0;
  width: 8px;
  border-radius: 2px;
}

.br-attempts-count .green {
  background-color: @green-500;
}

.br-attempts-count .gray {
  background-color: @gray-200;
}

.br-attempts-count .yellow {
  background-color: @yellow-400;
}

.br-attempts-count .red {
  background-color: @red-400;
}

.attempts-count-text {
  color: @text-gray-dark;
}

.attempts-num-text {
  color: @text-gray;
}

.br-attempts-count__text {
  display: flex;
}

.attempts-count-text__with-underline {
  border-bottom: 1px dashed @gray-300;
}
