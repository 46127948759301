@import 'styles/variables.less';

.br-fulfillment-scan-serials-card {
  padding: 24px;
}

.br-failure-modal .br-fulfillment-scan-serials-card__error-serial {
  display: inline-block;
  height: max-content;
  padding: 4px 8px;
  border-radius: 100px;
  box-shadow: @border-all-sides;
}
