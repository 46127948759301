@import "styles/variables";

.br-ops-control-wrong-zone {
  .br-table {
    .ant-table-row.ant-table-row-level-0:hover button.ant-btn {
      height: 34px;
    }
    .ant-table-row.ant-table-row-level-0 button.ant-btn.link-color {
      border: none;
      box-shadow: none;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: @spacing-sm;
    padding: @spacing-xs @spacing-md;
    padding-inline-start: 0;
  }

  &__destination-hub-select {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > span {
      .body-medium();
      color: @gray-900;
    }

    > .ant-select:not(.ant-pagination-options-size-changer) {
      width: 100%;

      > .ant-select-selector {
        border-radius: 8px;

        > .ant-select-selection-item {
          .body();
          color: @gray-900;
        }
      }

      > .ant-select-arrow {
        background: none;
        width: fit-content;
        height: fit-content;

        margin-top: unset;
        inset-inline-end: 0;
        transform: translateX(-50%) translateY(-50%);

        svg {
          visibility: unset;
          width: 20px;
          height: 20px;

          path {
            fill: @gray-500;
          }
        }
      }
    }
  }
}