@import 'styles/variables';

.br-po-products {
  flex: 1;
  position: relative;

  .br-table-component {
    border-radius: unset;
    box-shadow: none;

    table {
      width: 100% !important;
    }

    .br-table__quick-filter-tabs {
      margin: 0;
      padding: 16px 24px;
      background-color: @bg-gray-light;
      border-radius: 8px 8px 0 0;
      box-shadow: @border-bottom;

      .br-table__title {
        margin: 0;
        .font({.display-xs()});
      }
    }

    &.br-table-component__empty-state {
      height: auto;
    }

    .ant-input-number {
      width: 104px;

      .ant-input-number-handler-wrap {
        opacity: 1;
      }
    }

    .close-icon {
      cursor: pointer;
    }

    .ant-table-footer {
      background: @white;
      padding-top: 32px;
      padding-bottom: 32px;

      .br-button-component.ant-btn {
        padding: 4px 8px;
        height: 28px;
      }
    }

    .br-product-name-column {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.br-success-modal__actions {
  .br-button-component.ant-btn {
    height: 36px;
  }
}

.br-po-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: @white;
  z-index: 1000;
  border-radius: 8px;
  opacity: 0.4;
}
