@import 'styles/variables.less';

.br-deliveries-search__checkbox.ant-row.ant-form-item {
  min-height: auto;
  border: 1px solid @gray-200;
  background-color: @white;
  border-radius: 2px;
  margin-top: 8px;

  .ant-form-item-control-input-content {
    padding: 6px;

    .ant-checkbox-wrapper {
      width: 100%;
      .font({.body-medium()});
    }
  }
}

.br-deliveries-search__checkbox {
  .ant-checkbox + span {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
