@import 'styles/variables';

.br-print-serials-modal {
  .scanned {
    color: @text-green-dark;

    .check-icon {
      width: 16px;

      path {
        fill: @green-600;
      }
    }
  }
}

.br-print-serials-modal__collapse {
  .ant-collapse-header {
    .ant-collapse-header-text {
      &,
      .br-collapse__header,
      .br-collapse__header__name,
      .br-collapse__header__scanned-count {
        display: flex;
        align-items: center;
      }

      &,
      .br-collapse__header {
        width: 100%;
      }

      .br-collapse__header {
        justify-content: space-between;
      }

      .br-collapse__header__name {
        gap: 12px;
      }

      .br-collapse__header__scanned-count {
        gap: 8px;
      }
    }
  }

  .br-print-serials-modal__collapse__serial {
    display: flex;
    justify-content: space-between;

    .br-collapse__serial__icon {
      border-radius: 4px;
      border: 1px solid @gray-300;
      padding: 4px 8px;
      width: 36px;
      height: 28px;
      cursor: pointer;
    }

    .scanned {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 2px 8px;
      background: @bg-gray-light;
      border-radius: 12px;
    }
  }
}

.br-print-serials-modal__unconfirmed-serials {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
  margin-bottom: 12px;

  span {
    padding: 4px 8px;
    border-radius: 100px;
    border: 1px solid @gray-200;
    .font(.body-medium());
  }
}
