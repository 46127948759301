@import 'styles/variables.less';

.br-request-pickup-modal .ant-modal {
  width: 522px;
}

.br-request-pickup-modal__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.br-request-pickup-modal__footer button {
  margin-left: 10px;
}

.br-request-pickup-modal__title {
  color: @text-color;
  font-size: 17px;
  font-family: @poppins-font-600;
  font-weight: 600;
  margin-bottom: 20px;
}

.br-request-pickup-modal__create-contact {
  display: flex;
  justify-content: flex-end;
}

.br-request-pickup-modal__body {
  margin-bottom: 20px;
}
