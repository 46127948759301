@import 'styles/variables';

tr.ant-table-row {
  .br-po__actual-quantity__col {
    background: @bg-hovered;
  }
}

.br-confirm-po__confirmation-modal {
  .ant-modal-title {
    .font({.display-xs()});
  }

  .ant-modal-body {
    text-align: center;

    .br-delete-confirmation-modal__message {
      margin-top: 24px;
      padding-bottom: 0;
      .font({.display-sm()});

      .br-confirm-po__confirmation-modal__teal-color {
        color: @text-teal;
      }

      &:has(.br-confirm-po__confirmation-modal__check-po-content) {
        margin-top: 0;
      }

      .br-confirm-po__confirmation-modal__check-po-content {
        text-align: start;
        color: @text-gray-dark;
        margin-bottom: 0;
        .font(.subtitle-md());
      }

      .br-confirm-po__confirmation-modal__po-id {
        color: @text-gray;
        margin: 24px auto 4px;
      }
    }
  }
}

.br-confirmed-po__tooltip.ant-tooltip {
  max-width: none;

  .ant-tooltip-inner {
    .ant-spin-container {
      display: flex;
      flex-direction: row;
      gap: 12px;
      color: @text-gray;
      border-radius: 8px;
      padding: 12px;
      .font({.caption()});
    }

    .br-confirmed-po__tooltip__serial-number {
      background: @bg-gray-light;
      padding: 2px 8px;
      margin-top: 4px;
      border-radius: 12px;
      color: @text-gray-dark;
      .font({.body()});
    }
  }
}

.br-confirmed-po__tooltip__generating-serials {
  display: flex;
  gap: 12px;
  align-items: center;
}

.br-confirmed-po__tooltip__text {
  margin-left: 12px;

  &.has-serials {
    border-bottom: 1px dashed @gray-300;
    cursor: pointer;
  }
}

.br-cancel-po__confirmation-modal {
  .ant-modal-title {
    .font({.display-xs});
  }

  .br-delete-confirmation-modal__message {
    padding: 0;
    .font({.heading()});
  }
}
