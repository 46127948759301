.br-return-manifest__container > * {
  margin-bottom: 24px;
}

.br-return-manifest__container .ant-table-container {
  .ant-table-cell > a {
    margin: 0 8px;
  }
}

.br-return-manifest__container {
  & .ant-pagination {
    .anticon {
      vertical-align: middle;
    }
    .ant-pagination-prev {
      min-width: 32px;
    }
  }
}
