@import 'styles/variables';

.br-delivery-star-notes__contatiner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 180px;
  overflow-y: auto;
}

.br-delivery-notes__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & span {
    margin-top: 12px;
    font-size: 13px;
    color: @text-color-49;
    font-family: @poppins-font-500;
  }

  & .br-delivery-notes__empty-state-subtitle {
    margin-top: 8px;
    color: @text-color-47;
    font-family: @lato-font-400;
  }
}

.br-notes__container {
  margin-bottom: 16px;
  border-bottom: 1px solid @border-color-23;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  & .br-notes__note-title {
    text-align: start;
    unicode-bidi: plaintext;
    margin-bottom: 16px;
    font-family: @lato-font-400;
    font-size: 14px;
    color: @text-color-46;
    word-break: break-all;
    line-height: 21.63px;
  }

  & .br-notes__note-subtitle {
    color: @text-color-45;
    text-align: left;
    & span {
      margin-right: 8px;
      font-family: @lato-font-400;
      font-size: 12px;
      line-height: 14.4px;
    }
  }
}

.br-note__content-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 16px;
}

.br-note__content-container::-webkit-scrollbar {
  width: 2px;
}

.br-note__content-container::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: @background-color-18;
}
