@import 'styles/variables.less';

.br-bulk-promotions__upload-container {
  margin: 24px 0;
  border-radius: 4px;
  height: 100%;
  box-shadow: @border-all-sides;
  .br-upload-dialog__upload {
    display: flex;
    justify-content: center;
    padding: 16px 20px;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
    border: 2px @gray-200 dashed;
  }
}

.br-bulk-promotions__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  & .br-bulk-promotions__content-text {
    color: @gray-900;
    margin: 8px 0 4px;
    & span {
      color: @teal-500;
    }
  }
  & .br-bulk-promotions__content-hint {
    color: @gray-500;
    font-size: 12px;
    margin-bottom: 12px;
  }
  & .ant-btn {
    background-color: @teal-500;
    border: none;
    & svg {
      margin-right: 8px;
    }
  }
  & .ant-btn:hover,
  .ant-btn:focus {
    background-color: @teal-500;
  }
}

.ant-btn-danger.br-bulk-promotions__modal__confirm-btn,
.ant-btn-danger.br-bulk-promotions__modal__confirm-btn:hover {
  background-color: @teal-500;
  border: none;
}

.br-bulk-promotions__errors-box {
  padding: 16px 20px;
  margin-bottom: 32px;
  border-radius: 4px;
  box-shadow: @shadow-sm;
  .ant-btn.ant-btn-primary {
    background-color: @teal-500;
    border: none;
    margin-left: 8px;
  }
}

.br-bulk-promotions__errors-inner-box {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 16px;
  background-color: @red-50;
  border: 1px solid @red-200;
  border-radius: 4px;
  svg {
    path {
      fill: @icon-red-dark;
    }
  }
  span {
    margin-left: 12px;
    font-family: @lato-font-700;
    color: @text-gray-dark;
  }
}

.br-bulk-promotions__errors-box__file-info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  span > span {
    font-family: @lato-font-700;
    color: @text-gray-dark;
  }
}

.br-bulk-promotions__errors-box__file-info__subtitle {
  margin-top: 4px;
  color: @text-gray;
}

.br-bulk-promotions__errors-table.br-searchable-table {
  .br-searchable-component__header {
    display: none;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-family: @lato-font-400;
      font-size: 12px;
      color: @text-gray;
      text-transform: uppercase;
      background-color: @gray-50;
    }
  }
  .ant-table-tbody .ant-table-cell {
    color: @text-gray-dark;
  }
}

.br-bulk-promotions__errors-box__upload {
  .ant-upload.ant-upload-select.ant-upload-select-picture {
    border: none;
    width: 100%;
    height: auto;
  }
}
