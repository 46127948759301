@import 'styles/variables';

.br-fxf-statistics {
  display: flex;
  gap: 24px;
  width: 100%;
}

.br-fxf-statistics__statistic {
  display: flex;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: @white;

  &.full-width {
    width: 100%;
  }

  &.has-icon {
    max-width: 260px;
    flex-grow: 1;
  }

  &.selected {
    border: 1px solid;

    &.total {
      border-color: @teal-400;
    }
    &.pick,
    &.created,
    &.return {
      border-color: @yellow-500;
    }
    &.pack,
    &.confirmed,
    &.qualityCheck {
      border-color: @purple-500;
    }
    &.transfer,
    &.allPutaway,
    &.putaway {
      border-color: @blue-500;
    }
    &.transferred,
    &.completed {
      border-color: @green-500;
    }
    &.canceled {
      border-color: @gray-400;
    }
  }
}

.br-fxf-statistics__statistic__title {
  color: @text-gray;
}

.br-fxf-statistics__statistic__content {
  display: block;
  color: @text-gray-dark;
  margin-top: 4px;
  .font({.display-sm()});
}

@media only screen and (max-width: @lg) {
  .br-po-statistics {
    gap: 12px;
  }
}
