@import 'styles/variables.less';

.ellipses-mixin() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
}

.br-draft-order-page__container {
  padding: 24px;
  background-color: white;
}
.br-draft-orders {
  .br-empty-state__image {
    margin-bottom: 4px;
  }

  .br-empty-state__sub-title {
    text-align: center;
  }

  .br-draft-orders__empty-subtitle-container {
    display: flex;
    flex-direction: column;
  }

  .br-draft-orders__empty-state-extra {
    .br-empty-state__actions {
      margin-top: 16px;
    }
  }
}

.br-draft-orders {
  padding: 24px;
  .ant-tooltip {
    width: max-content;
  }
  .ant-tooltip-inner {
    display: flex;
    flex-direction: column;
    padding: 8px;
    max-width: 166px;
  }
  .ant-tooltip-inner,
  .ant-tooltip-inner > span {
    .ellipses-mixin();
  }
}

.br-draft-orders__order-id-container {
  display: flex;
  align-items: center;
  gap: 4px;
  > span.br-draft-orders__order-id {
    .ellipses-mixin();
  }
}

.br-draft-orders__items {
  > span {
    border-bottom: 1px dashed @gray-300;
  }
}

.br-draft-orders__customer {
  > span:first-child {
    .ellipses-mixin();
  }
}

.br-draft-orders__order-location {
  display: flex;
  flex-direction: column;
  .ellipses-mixin();
  > span:first-child {
    .ellipses-mixin();
  }
  > span:last-child {
    color: @text-gray;
  }
}

.br-draft-orders__order-date {
  display: flex;
  flex-direction: column;
  > span:last-child {
    color: @text-gray;
  }
}

.br-draft-orders__table {
  .ant-table-row.ant-table-row-level-0:hover .ant-btn {
    height: 36px;
  }
  .ant-input-group-wrapper.ant-input-search.br-table__header__input-search {
    max-width: 316px;
  }
  .br-table__quick-filter-tabs {
    margin: 0;
    padding: 16px;
    .ant-tabs.ant-tabs-top {
      display: none;
    }
    .br-table__header div {
      margin: 0;
    }
  }
}

.ant-dropdown-menu-title-content .br-draft-orders__table-actions__delete {
  color: @red-700;
}

.br-draft-orders__table-confirm-btn {
  margin-right: 12px;
  max-height: 29px;
}

.ant-btn:not(.ant-btn-circle).br-draft-orders__table-actions-btn {
  border: 1px solid @teal-500;
  color: @teal-500;
  min-height: 36px;
  min-width: fit-content;
  box-shadow: @shadow-xs;
}

.br-draft-orders__table-orders__view-btn {
  margin-left: 4px;
  color: @text-gray;
}

.br-draft-orders__table-description {
  margin-left: 12px;
  text-transform: capitalize;
  .font({.heading()});
  white-space: nowrap;
}

.br-draft-orders__table-copy {
  color: @text-teal;
}

.br-draft-orders__table-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  & span.br-draft-orders__cancelled-text {
    color: @teal-500;
    text-decoration: underline;
    text-transform: uppercase;
  }
}

.br-draft-orders__mobile-cards__order-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .br-draft-orders__mobile-cards__order-cod {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .br-draft-orders__order-date {
    flex-direction: row;
    gap: 4px;
  }
}

.br-draft-orders__mobile-cards__customer-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    gap: 4px;
  }
  margin-bottom: 16px;
}

.br-draft-orders .br-table .ant-table-row.ant-table-row-level-0 .ant-btn {
  height: 36px;
}

.br-draft-order__gray-element {
  color: @text-gray;
}

.br-draft-orders__table-actions {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.br-table-component:not(.br-table__medium-mobile)
  .br-table-filters
  .ant-radio-group {
  overflow-x: auto;
}

.br-draft-orders__products-popover .ant-popover-content .ant-popover-inner {
  padding: 0 12px;
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      gap: 12px;
      padding: 12px 0;
      &:not(:last-child) {
        box-shadow: @border-bottom;
      }

      .br-draft-orders__products-popover__image {
        position: relative;
        border: 1px solid @gray-200;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        .br-draft-orders__products-popover__image-count {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -8px;
          top: -7px;
          background-color: @white;
          border: 1px solid @gray-200;
          border-radius: 32px;
          z-index: 2;
          width: 20px;
          height: 20px;
          box-shadow: @shadow-xs;
          color: @text-teal;
        }
      }

      .br-draft-orders__products-popover__info {
        display: flex;
        gap: 8px;
        flex: 1;

        .br-draft-orders__products-popover__title {
          display: flex;
          flex-direction: column;
          width: 183px;
        }

        .br-draft-orders__products-popover__price {
          color: @text-gray;
        }

        > span {
          width: 75px;
          text-align: center;
        }
      }
    }
  }
}

html[dir='rtl'] .br-draft-orders .ant-popover {
  left: unset !important;
  .ant-popover-arrow {
    left: 50% !important;
    .ant-popover-arrow-content::before {
      display: none;
    }
  }
}

.br-draft-orders__empty-state .br-empty-state__title {
  text-align: center;
}

.br-draft-orders .br-banner-content__extra-image {
  position: absolute;
  right: 85px;
  height: 156px;
  bottom: 0;
}

.br-draft-orders__empty-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: @bg-pressed;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  width: 317px;
  > img {
    width: 85%;
    min-width: 273px;
  }
}

.br-draft-orders__reverse-arrow {
  rotate: x 180deg;
}

html[dir='rtl'] .br-draft-orders__arrow,
html[dir='rtl'] .br-draft-orders__reverse-arrow {
  scale: -1 1;
}

.br-draft-orders__empty-published {
  .br-table-component__empty-state.br-empty-state {
    box-shadow: none;
    padding: 86px 48px 140px;
  }
}

.ant-tooltip.br-card-payment-info__tooltip {
  & .ant-tooltip-content {
    width: 100%;
    min-width: 160px;
    & .ant-tooltip-inner {
      width: 100%;
      min-width: 160px;
      & .br-card-payment-info__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

html[dir='rtl'] .br-draft-orders-start-selling__tour {
  &.br-tour-placement-bottomRight .br-tour-arrow,
  &.br-tour-placement-bottomLeft .br-tour-arrow {
    left: 23% !important;
    right: unset;
  }
}

.br-amount-column__container {
  display: flex;
  align-items: center;
  // white-space: nowrap;
  gap: 8px;
  & > div {
    display: flex;
    flex-direction: column;
    & > span:last-child {
      color: @gray-500;
      width: 130%;
    }
  }
}

.br-orders__no-cod-label {
  color: @gray-500;
}

.br-orders-table__print-selected-rows-container {
  display: flex;
  align-items: center;
  margin-right: 66px;

  .ant-btn.br-button-component {
    border-left: unset;
    border-radius: unset;
  }

  .ant-btn.br-button-component.br-orders-table__title__delete-btn.br-orders-table__title__delete-btn__with-printing-options {
    border-radius: unset;
  }

  .ant-btn.br-button-component:hover,
  .ant-btn.br-button-component:focus {
    background-color: unset;
    color: unset;
    border-color: @gray-300;
  }

  &
    .ant-btn.br-button-component.ant-dropdown-trigger.br-orders-table__printing-options {
    max-width: 36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    & > svg {
      margin: 0;
    }
  }
  &
    .ant-btn.br-button-component.ant-dropdown-trigger.br-orders-table__printing-options:is(
      :hover,
      :focus
    ) {
    border-color: @gray-300;
  }
}

@media only screen and (max-width: @lg) {
  .br-draft-orders__arrow,
  .br-draft-orders__reverse-arrow {
    rotate: z 90deg;
  }

  html[dir='rtl'] .br-draft-orders__arrow,
  html[dir='rtl'] .br-draft-orders__reverse-arrow {
    rotate: z -90deg;
  }
}

@media only screen and (max-width: @sm) {
  .br-draft-orders {
    padding: 16px 0;
  }
  .br-draft-orders {
    .br-table-mobile__fixed-header {
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  .br-draft-orders__table {
    .ant-input-group-wrapper.ant-input-search.br-table__header__input-search {
      max-width: 100%;
    }
    &.br-table__small-mobile .br-table__header__input-search {
      padding: 0;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-banner-content.br-draft-orders__activate-bosta-banner {
    .br-banner-content__text-container {
      width: 100%;
      max-width: unset;
    }
    .br-products-sotre-info-sllr-sales__bannner-btn {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-draft-orders-start-selling__tour {
    .br-tour-content {
      width: 280px;
    }
  }
}

.br-draft-order__flyers-cancellation-tooltip.ant-tooltip {
  width: 100%;
  max-width: 400px;
  & .ant-tooltip-content {
    width: 100%;
    max-width: 400px;
    // & .ant-tooltip-arrow{
    //   display: none;
    // }
    & .ant-tooltip-inner {
      width: 100%;
      max-width: 400px;
      white-space: break-spaces;
    }
  }
}
