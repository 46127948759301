@import 'styles/variables';

.br-serials-view-header {
  margin-bottom: 20px;

  .br-scan-serials-modal__scan-field.ant-row.ant-form-item {
    padding: 0;
    margin-bottom: 20px;
  }
}

.br-serials-view-header__counts {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 24px;
  padding-top: 20px;
  margin-bottom: 24px;
  box-shadow: @border-top;
}

.br-serials-view-header__item {
  width: 106px;

  &.empty {
    opacity: 0.5;
  }
}

.br-serials-view-header__item__count {
  display: block;
  margin-bottom: 8px;
  .font({.display-lg()});
}

.br-serials-view-header__item__title {
  color: @text-gray;
}

.br-serials-view-header__search-result {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  .copy-icon {
    width: 24px;
  }
}
