@import 'styles/variables';

.br-Escalation-modal__container{
    width:408px !important
}

.br-Escalation-modal__container .ant-modal-footer{
    border-top: none;
    margin: 0 auto;
    justify-content: space-between;
}

.ant-modal-footer button{
    font-family: @lato-font-400;
    width: 48%;
    padding: 8px, 16px, 8px, 16px;
    border-radius: 4px;
}

.br-Escalation-modal__container .br-escalation-modal__primary-btn{
    background-color: @deep-teal;
    color: @white;
}

.br-escalation-modal__header{
    margin: 0 auto;
    text-align: center;
}

.br-escalation-modal__header h3{
    font-size: 18px;
    font-family: @poppins-font-500;
}

.br-escalation-modal_delete-severity{
    margin-top: 2% !important;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    color: @bosta-red;
}