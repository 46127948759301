@import 'styles/variables.less';

.br-customer-details .br-new-order-card__content {
  display: flex;
}

.br-customer-details img {
  margin-left: 5px;
}

.br-customer-details .br-customer-details__customer-address,
.br-customer-details .br-customer-details__customer-info {
  flex: 1;
}

.br-customer-details__footer {
  display: flex;
  flex-direction: row;
}

.br-customer-details__work-address .ant-checkbox-wrapper span {
  font-family: @lato-font-400;
  color: @text-color;
  font-size: 13px;
  font-weight: 500;
}

.br-customer-details__work-address .ant-checkbox-wrapper,
.br-customer-details__work-address .ant-checkbox + span {
  display: flex;
  align-items: center;
}

.br-customer-details__city-dropdown.ant-select-dropdown {
  min-width: 400px;
}

.br-customer-details__area-dropdown.ant-select-dropdown {
  min-width: 550px;
}

.br-new-order
  .br-form-row__building-floor-apartment.br-form-row
  .ant-row.ant-form-item:not(:last-child),
.br-new-order
  .br-form-row__city-area.br-form-row
  .ant-row.ant-form-item:not(:last-child) {
  margin-right: 11px;
}

.br-customer-details_divider {
  height: 'auto';
  margin-right: '22px';
  margin-left: '22px';
  margin-bottom: '31px';
}

@media only screen and (max-width: 992px) {
  .br-customer-details .br-new-order-card__content {
    flex-direction: column;
  }

  .br-customer-details .br-new-order-card {
    width: 100%;
  }

  .br-customer-details__city-dropdown.ant-select-dropdown {
    min-width: 100%;
  }
}
