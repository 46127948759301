@import 'styles/variables';

.br-delete-confirmation-modal__footer {
  display: flex;
  justify-content: flex-end;
}

.br-delete-confirmation-modal__message {
  padding-bottom: 24px;
}

.br-confirmation-modal__without-body .ant-modal-body {
  display: none;
}
