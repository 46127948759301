@import 'styles/variables';

.br-support-tickets__preview-image {
  width: 100%;
}

.br-preview-image-modal .ant-modal {
  .ant-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}

.br-preview-image-modal {
  .ant-modal-body {
    text-align: center;
  }
}

@media only screen and (max-width: @sm) {
  .br-preview-image-modal .ant-modal-body > svg {
    max-width: 100%;
  }
}
