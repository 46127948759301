@import 'styles/variables';

.br-pickup-details-rating {
  display: flex;
  gap: @spacing-xs;

  &__star {
    width: 16px;
    height: 16px;

    &.colored {
      > * {
        fill: @yellow-400;
      }
    }
  }
}