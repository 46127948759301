@import 'styles/variables';

.br-product-details__drawer-container {
  position: absolute;
}

html[dir='rtl']
  .br-product-details__drawer-container
  .ant-drawer-content-wrapper {
  transform: translateX(100%) !important;
}

html[dir='rtl']
  .br-product-details__drawer-container.ant-drawer-open
  .ant-drawer-content-wrapper {
  transform: unset !important;
}

.br-product-details__drawer-container .ant-drawer-content-wrapper {
  font-family: @poppins-font-600;
  min-width: 566px;
}

.br-product-details__drawer-container .ant-drawer-header {
  padding: 20px 32px 0;
  border: none;
}

.br-product-details__drawer-container button.ant-drawer-close:focus {
  outline: none;
}

.br-product-details__drawer-container .ant-drawer-title {
  font-size: 24px;
}

.br-product-details__drawer-container
  .ant-drawer-wrapper-body
  .ant-drawer-body {
  padding: 20px 32px;
}

.br-product-details__drawer-container p {
  font-family: @poppins-font-500;
}

.br-product-details__container .br-product-details__header {
  color: @gray-400;
  font-size: 14px;
  line-height: 21px;
}

.br-product-details__description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .br-product-details__product-name {
    margin-top: 16px;
  }
  .br-product-details__product-description {
    margin-bottom: 0px;
  }
}

.br-product-details__image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .br-product-details__image {
    width: 502px;
    height: 168px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: @gray-100;
    svg {
      width: 170px;
      height: 170px;
      path {
        fill: @gray-200;
      }
    }
  }
  .br-product-details__image svg path:not(:first-child),
  .br-product-details__image svg > g[mask='url(#mask1)'] path {
    display: none;
  }
}

html[dir] .br-product-details__image {
  svg {
    transform: translateX(50px);
  }
}

.br-product-details__additional-content {
  border-bottom: 1px solid @gray-200;
  border-top: 1px solid @gray-200;
  padding-top: 20px;
  p {
    margin-bottom: 20px;
  }
}

.br-product-details__available {
  padding: 16px 0;
  div {
    display: flex;
    .br-product-details__header {
      margin-right: 10px;
    }
  }
}

.br-product-details__container .br-product-details__additional-content {
  display: flex;
  flex-wrap: wrap;
}

.br-product-details__additional-content div {
  flex-grow: 1;
  width: 33%;
}

.br-product-details__additional-content .br-product-details__price {
  color: @green-600;
  font-family: @poppins-font-600;
}

.br-product-details-colors-container {
  display: flex;
  flex-wrap: wrap;
}

.br-product-details__container .br-product-details__button-container {
  background-color: @white;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.br-product-details__container .ant-btn.ant-btn-primary {
  width: 90%;
}

@media only screen and (max-width: 728px) {
  .br-product-details__drawer-container .ant-drawer-content-wrapper {
    min-width: 100%;
  }
  .br-product-details__not-active {
    display: none;
  }
}

@media only screen and (max-width: 512px) {
  .br-product-details__description {
    flex-direction: column-reverse;
    align-items: center;
    .br-product-details__image {
      margin-bottom: 18px;
    }
  }
  .br-product-details__additional-content div {
    width: 50%;
  }
}
