@import 'styles/variables';
.hub-deliveries__header {
  width: 300px;
  display: flex;
  align-items: start;
}

.hub-deliveries__general-cards_container {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.hub-deliveries__table-container .br-tag {
  color: @red-600 !important;
  background-color: @red-100 !important;
}

.hub-deliveries__table-container {
  margin-top: 25px;
}

.hub-deliveries__general-cards_container .single-info__container {
  height: 120px;
}

.hub-deliveries__general-cards_container
  .single-info__container
  .single-info__header {
  height: 50px;
}

.hub-deliveries__general-cards_container .general-info__title {
  min-height: 30px;
  max-height: 40px;
}
